import React, { Component, Fragment } from 'react'
import { Trans } from 'react-i18next'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import Icon from '../SimpleComponents/Icon'
import moment from 'moment'
import LBDateInput from './LBDateInput'
import i18n from '../../../i18n'

export class LBDatePicker extends Component {
  constructor(props) {
    super(props)
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.state = {
      month: this.props.date ? this.props.date : new Date(),
      selectedDate: this.props.date ? this.props.date : new Date()
    }
  }
  handleYearMonthChange(month) {
    this.setState({ month })
  }
  handleDayClick(selectedDate, { disabled }) {
    if (disabled) return
    this.setState({ selectedDate }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.selectedDate)
      }
    })
  }
  render() {
    let disabledRange = {}

    if (this.props.minDate) {
      disabledRange.before = this.props.minDate
    }
    if (this.props.maxDate) {
      disabledRange.after = this.props.maxDate
    }

    return (
      <DayPicker
        id={this.props.id}
        showOutsideDays
        fixedWeeks
        weekdayElement={<Fragment />}
        onDayClick={this.handleDayClick}
        month={this.state.month}
        navbarElement={<Fragment />}
        selectedDays={this.props.selectedDays || this.state.selectedDate}
        disabledDays={
          this.props.disabledDays ? this.props.disabledDays : [disabledRange]
        }
        enableOutsideDaysClick={false}
        captionElement={({ date, localeUtils }) => (
          <YearMonthForm
            id={this.props.id}
            date={date}
            localeUtils={localeUtils}
            onChange={this.handleYearMonthChange}
          />
        )}
      />
    )
  }
}

function YearMonthForm({ id, date, onChange }) {
  const nextMonth = () => {
    onChange(
      new Date(
        (date.getMonth() + 1) % 12 === 0
          ? date.getFullYear() + 1
          : date.getFullYear(),
        (date.getMonth() + 1) % 12
      )
    )
  }

  const prevMonth = () => {
    onChange(
      new Date(
        (date.getMonth() + 11) % 12 === 11
          ? date.getFullYear() - 1
          : date.getFullYear(),
        (date.getMonth() + 11) % 12
      )
    )
  }

  const nextYear = () => {
    onChange(new Date(date.getFullYear() + 1, date.getMonth()))
  }

  const prevYear = () => {
    onChange(new Date(date.getFullYear() - 1, date.getMonth()))
  }

  return (
    <form className="DayPicker-Caption fnt-12">
      <div className="flex-space">
        <div className="flex-aligned">
          <i
            id={`i-icnCalendar-LBDatePicker-${id}-1`}
            className="icn calendar-arrow mr-15"
            onClick={prevMonth}
          >
            <Icon name="icn-arr-left" view="0 0 4.268 7.588" />
          </i>
          <span className="fnt-upper-b month-block" name="month">
            {moment(date).format('MMMM')}
          </span>
          <i
            id={`i-icnCalendar-LBDatePicker-${id}-2`}
            className="icn calendar-arrow ml-15"
            onClick={nextMonth}
          >
            <Icon name="icn-arr-right" view="0 0 8.885 15.795" />
          </i>
        </div>
        <div className="flex-aligned">
          <i
            id={`i-icnCalendar-LBDatePicker-${id}-3`}
            className="icn calendar-arrow mr-15"
            onClick={prevYear}
          >
            <Icon name="icn-arr-left" view="0 0 4.268 7.588" />
          </i>
          <span className="fnt-upper-b year-block" name="year">
            {date.getFullYear()}
          </span>
          <i
            id={`i-icnCalendar-LBDatePicker-${id}-4`}
            className="icn calendar-arrow ml-15"
            onClick={nextYear}
          >
            <Icon name="icn-arr-right" view="0 0 8.885 15.795" />
          </i>
        </div>
      </div>
    </form>
  )
}

export class LBDateRange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate:
        props.start && !props.inError
          ? props.start
          : props.prevMonth
          ? moment().subtract(1, 'M')
          : moment(),
      endDate:
        props.end && !props.inError
          ? props.end
          : props.prevMonth
          ? moment()
          : moment().add(1, 'M'),
      startSelected: false,
      endSelected: false
    }
  }

  onChange() {
    if (
      this.state.startSelected &&
      this.state.endSelected &&
      this.props.onChange
    ) {
      this.props.onChange({
        startDate: this.state.startDate,
        endDate: this.state.endDate
      })
    }
  }

  render() {
    return (
      <Fragment>
        <div className="flex-center">
          <LBDatePicker
            id={`LBDatePicker-${this.props.id}-3`}
            onChange={date => {
              this.setState(
                { startDate: moment(date), startSelected: true },
                this.onChange
              )
            }}
            date={this.state.startDate.toDate()}
            minDate={this.props.minDate}
            maxDate={this.state.endDate.toDate()}
          />
          <LBDatePicker
            id={`LBDatePicker-${this.props.id}-4`}
            onChange={date => {
              this.setState(
                { endDate: moment(date), endSelected: true },
                this.onChange
              )
            }}
            date={this.state.endDate.toDate()}
            minDate={
              this.state.startDate
                ? this.state.startDate.toDate()
                : this.props.minDate
            }
            maxDate={moment().toDate()}
          />
        </div>
        <div className="flex-center mt-10 mb-25">
          <span className="fnt-12 mr-20 fnt-upper">
            <Trans i18nKey="Common.ChosenPeriod" />:
          </span>
          <span className="fnt-14 fnt-upper-b">
            <span className="mr-15">
              {i18n.t('Common.FromTime', {
                fromDate: this.state.startDate.format('DD/MM/YYYY')
              })}
            </span>
            {i18n.t('Common.ToTime', {
              toDate: this.state.endDate.format('DD/MM/YYYY')
            })}
          </span>
        </div>
      </Fragment>
    )
  }
}

export class LBDateInputs extends Component {
  constructor(props) {
    super(props)
    this.state = { minDateError: false }
  }

  hasErrorEnd = () => {
    const { start, end } = this.props
    return (
      moment.isMoment(start) &&
      moment.isMoment(end) &&
      start.startOf('day') > end.startOf('day')
    )
  }

  onStartChange = date => {
    this.props.onChange({ startDate: date, endDate: this.props.end })
  }

  onEndChange = date => {
    this.props.onChange({ startDate: this.props.start, endDate: date })
  }

  render() {
    const startVal = this.props.start
      ? this.props.start.format('DD/MM/YYYY')
      : ''
    const endVal = this.props.end ? this.props.end.format('DD/MM/YYYY') : ''

    const hasErrorEnd = this.hasErrorEnd()

    return (
      <div className="datepicker-out flex-aligned">
        <LBDateInput
          id={`LBDatePicker-${this.props.id}-1`}
          value={startVal}
          onChange={this.onStartChange}
          minDate={this.props.minDate}
          inErrorStateHandler={this.props.inErrorStateHandler}
        />
        <span className="ml-10 mr-10">-</span>
        <LBDateInput
          hasError={hasErrorEnd}
          id={`LBDatePicker-${this.props.id}-2`}
          value={endVal}
          onChange={this.onEndChange}
          inErrorStateHandler={this.props.inErrorStateHandler}
        />
        <i
          id={`i-calenderIcon-LBDatePicker-${this.props.id}`}
          className="icn icn-calendar medium-grey ml-5 pointer"
          onClick={this.props.click}
        >
          <Icon name="icn-calendar" view="0 0 19.274 17.266" />
        </i>
      </div>
    )
  }
}
