/* eslint-disable react/display-name */
import React from 'react'

import I18Global from './I18Global'

const unauthorizedLayout = WrappedComponent => props => {
  return (
    <I18Global>
      {(t, { i18n }) => (
        <div className={`App ${i18n.language}`}>
          <WrappedComponent {...props} />
        </div>
      )}
    </I18Global>
  )
}

export default unauthorizedLayout
