import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from '../../../assets/scss/component/input.scss'

import numberFormat from '../../helpers/numberFormat'
import formatter from '../../helpers/formatter'

let style = classNames.bind(styles)

Number.prototype.countDecimals = function() {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0
  let splt = this.toString().split('.')
  if (splt.length > 1) return splt[1].length || 0
  return 0
}

class DecimalInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: false,
      isFilled: !!this.props.value
    }

    this.onChange = this.onChange.bind(this)
    this.onFocusIn = this.onFocusIn.bind(this)
    this.onFocusOut = this.onFocusOut.bind(this)
  }

  onChange(e) {
    let regMatch = e.target.value
      .replace(',', '.')
      .match(/^(?!00)\d+\.?(\d{1,2})?/)
    let value = regMatch ? regMatch['0'] : ''
    let splt = value.split('.')
    while (!isNaN(value) && parseFloat(value) > 9999999999999) {
      value = value.substring(0, value.length - 1)
    }
    if (
      !isNaN(value) &&
      (!value.includes('.') || splt[1].length <= 2) &&
      (value.length === 0 || parseFloat(value) <= 10000000000000)
    ) {
      if (this.props.onChange) {
        this.props.onChange(value)
      }
    }
  }

  onFocusIn(e) {
    let target = e.target
    let select = function() {
      setTimeout(() => {
        if (this.state.focused) target.select()
      }, 10)
    }
    if (!isNaN(this.props.value) && this.props.onChange) {
      let value = parseFloat(this.props.value)

      this.props.onChange(value) //in case decimal count is
    }
    this.setState({ focused: true }, select)

    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  onFocusOut(e) {
    this.setState({ focused: false, isFilled: !!e.target.value.length })
    if (e.target.value.length === 0 && !this.props.allowNull) {
      this.props.onChange(0)
    }
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  render() {
    let value //mokled amas ro vwerdi mgonia, rom vicodi ras vwerdi, magram exla gartymashi ar var ra miweria da tyuila ar imasmiqnat tvini
    if (!isNaN(this.props.value)) {
      let parsedVal = parseFloat(this.props.value)
      if (parsedVal.countDecimals() > 2) value = parsedVal.toFixed(2)
      //(Math.floor(parsedVal * 100) / 100).toFixed(2);
      else value = this.props.value
    } else {
      if (this.state.focused) value = ''
      else value = 0
    }
    if (!this.state.focused && value !== null && value.toString().length > 0) {
      value = parseFloat(value).toFixed(2)
      value = numberFormat(value, 2, false)
    }
    let className = style({
      filled: this.state.isFilled
    })

    return (
      <Fragment>
        <input
          id={`input-${this.props.id}`}
          key={this.props.key}
          style={this.props.style}
          ref={this.props.setRef || (ref => (this.ref = ref))}
          className={this.props.className + ' ' + (className || '')}
          type="text"
          onChange={this.onChange}
          onBlur={this.onFocusOut}
          onFocus={this.onFocusIn}
          value={value}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          autoComplete="off"
          readOnly={this.props.readOnly}
        />
        <i className="amount-symbol">
          {formatter.getCurrencySign(this.props.symbol)}
        </i>
        {this.props.error ? (
          <div className="lb-input-err">{this.props.errorMsg}</div>
        ) : (
          ''
        )}
      </Fragment>
    )
  }
}

DecimalInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  allowNull: PropTypes.bool,
  symbol: PropTypes.string,
  errorMsg: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default DecimalInput
