import React, { PureComponent } from 'react'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import LbModal from './LbModal'
import SwitchRenderer from '../Layout/SwitchRenderer'
import { globalMessageTypes } from '../../constants/constants'
import i18n from '../../../i18n'

class GlobalMessageDialog extends PureComponent {
  handleOk = () => {
    this.modal.handleCloseModal()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.messageProperties.messageId !==
      this.props.messageProperties.messageId
    ) {
      this.modal.handleOpenModal()
    }
  }

  render() {
    const { messageProperties } = this.props
    return (
      <LbModal
        ref={modal => {
          this.modal = modal
        }}
        header={i18n.t(messageProperties.header)}
        overlayClassName="httperror-modal"
        className="mw-500"
        style={{ overlay: { zIndex: 11 } }}
      >
        <div>
          <SwitchRenderer renderKey={messageProperties.type}>
            <div
              renderValue={globalMessageTypes.MESSAGE}
              className="rows-wrap fnt-14"
            >
              <Trans i18nKey={messageProperties.message} />
            </div>
            <div
              renderValue={globalMessageTypes.MESSAGE_WITHOUT_KEY}
              className="rows-wrap fnt-14"
            >
              {messageProperties.message}
            </div>
            <div
              renderValue={globalMessageTypes.RESET_PASSWORD}
              className="lb-passw-block addnew-password"
            >
              <div className="lb-form-group flex-aligned mt-20">
                <label className="fnt-16 fnt-low-m pr-10">
                  <Trans i18nKey="Administration.AddNewPassword" />:
                </label>
                <input
                  id="input-new-passw"
                  type="text"
                  className="lb-input lb-input-passw"
                  placeholder=""
                  value={messageProperties.password}
                  readOnly
                />
              </div>
            </div>
          </SwitchRenderer>

          <div className="lb-popup-footer">
            <button className="lb-btn btn-red" onClick={this.handleOk}>
              <Trans i18nKey="Common.Yes" />
            </button>
          </div>
        </div>
      </LbModal>
    )
  }
}

export default connect(state => {
  const {
    ui: { globalMessageProperties }
  } = state
  return {
    messageProperties: globalMessageProperties
  }
})(GlobalMessageDialog)
