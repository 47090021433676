import React, { Component } from 'react'
import { getConfigValue } from '../../../constants/config'
import { fetchSpecialRatePendingTasksAjax } from '../../../api/corporate/currentTask.api'
import CurrentTaskDetailsModal from './CurrentTaskDetailsModal'
import { toast } from 'react-toastify'
import { LbToast } from '../../../common/SimpleComponents/LBToasts'
import i18n from '../../../../i18n'
import moment from 'moment'
import { currentTask as currentTaskActions } from '../../../redux/actions/corporate'
import { connect } from 'react-redux'

class SpecRateCurrentTasksGlobal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notifications: [],
      currentTasks: [],
      interval: getConfigValue('current_task_polling_internval'),
      ws: null
    }
    this.handleSpecialRate = this.handleSpecialRate.bind(this)
    this.showNotification = this.showNotification.bind(this)
    this.hideNotification = this.hideNotification.bind(this)
    this.openCurrentTaskModal = this.openCurrentTaskModal.bind(this)
  }
  //timeout = 250 // Initial timeout duration as a class variable
  // connect = () => {
  //   const token = this.props.token
  //   let connection = new HubConnectionBuilder()
  //     .withUrl(
  //       'https://myliberty.02.test.lb.ge/api/currenttask/currenttaskhub',
  //       { accessTokenFactory: () => this.props.token }
  //     )
  //     .build()
  //   connection
  //     .start()
  //     .then(() => console.log('conn started'))
  //     .catch(() => console.log('conn failed'))
  //   connection.on('PaymenUpdated', data => {
  //     console.log('datataaa', data)
  //   })
  // let connectInterval
  //
  // // websocket onopen event listener
  // ws.onopen = () => {
  //   console.log('connected websocket main component')
  //
  //   this.setState({ ws: ws })
  //
  //   that.timeout = 250 // reset timer to 250 on open of websocket connection
  //   clearTimeout(connectInterval) // clear Interval on on open of websocket connection
  // }
  //
  // ws.onclose = e => {
  //   console.log(
  //     `Socket is closed. Reconnect will be attempted in ${Math.min(
  //       10000 / 1000,
  //       (that.timeout + that.timeout) / 1000
  //     )} second.`,
  //     e.reason
  //   )
  //
  //   that.timeout = that.timeout + that.timeout //increment retry interval
  //   connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)) //call check function after timeout
  // }
  // ws.onmessage = evt => {
  //   // listen to data sent from the websocket server
  //   const message = JSON.parse(evt.data)
  //   this.setState({ dataFromServer: message })
  //   console.log(message)
  // }
  // ws.onerror = err => {
  //   console.error('Socket encountered error: ', err.message, 'Closing socket')
  //
  //   ws.close()
  // }
  //}

  check = () => {
    const { ws } = this.state
    if (!ws || ws.readyState === WebSocket.CLOSED) this.connect()
  }

  componentDidMount() {
    // this.connect()
    this.handleSpecialRate()
    this.specialRateTimer = setInterval(
      this.handleSpecialRate,
      this.state.interval
    )
  }
  // componentDidMount() {
  //   this.handleSpecialRate()
  //   this.specialRateTimer = setInterval(
  //     this.handleSpecialRate,
  //     this.state.interval
  //   )
  // }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.interval !== prevState.interval) {
      clearInterval(this.specialRateTimer)
      this.specialRateTimer = setInterval(
        this.handleSpecialRate,
        this.state.interval
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.specialRateTimer)
  }

  handleSpecialRate = () => {
    // if (this.isDetailsOpen) return
    let notifications = [...this.state.notifications]
    fetchSpecialRatePendingTasksAjax().then(data => {
      this.setState({
        interval:
          !data || data.length === 0
            ? getConfigValue('current_task_polling_long_interval')
            : getConfigValue('current_task_polling_internval')
      })

      let tasks =
        data && data.length > 0
          ? data.filter(task => task.status === 'IsTemporary')
          : null
      if (tasks && tasks.length > 0) {
        tasks.map(task => {
          if (notifications.includes(task.paymentId) === false) {
            this.showNotification(task)
          }
        })
      }
    })
  }
  showNotification = task => {
    let notifications = [...this.state.notifications]
    let currentTasks = [...this.state.currentTasks]
    if (notifications.includes(task.paymentId) === false) {
      notifications.push(task.paymentId)
      currentTasks.unshift({
        id: task.paymentId,
        expiration: task.consentExpirationTime
      })

      this.setState(
        {
          notifications: notifications,
          currentTasks: currentTasks
        },
        () => {
          let expiration = moment(
            moment
              .unix(task.consentExpirationTime)
              .subtract(4, 'hours')
              .diff(moment())
          ).format('x')
          LbToast('warning', i18n.t('CurrentTasks.AwaitingYourApproval'), {
            onOpen: () => {
              setTimeout(
                () => this.autoOpenCurrentTaskModal(task.paymentId),
                10000
              )
            },
            autoClose: parseInt(expiration),
            toastId: task.paymentId,
            onClick: () => this.openCurrentTaskModal(task.paymentId)
          })
        }
      )
    }
  }
  hideNotification = taskId => {
    let notifications = [...this.state.notifications]
    let currentTasks = [...this.state.currentTasks]

    if (notifications.includes(taskId)) {
      let nots = notifications.filter(n => n !== taskId)
      let tasks = currentTasks.filter(task => task.id !== taskId)
      this.setState(
        {
          notifications: nots,
          currentTasks: tasks
        },
        () => {
          toast.dismiss(taskId)
          this.props.dispatch(
            currentTaskActions.fetchCurrentTasks(this.props.currentTasksFilter)
          )
        }
      )
    }
  }

  autoOpenCurrentTaskModal = taskId => {
    if (!this.props.currentTaskModalId) this.detailsModal.open({ id: taskId })
  }

  openCurrentTaskModal = taskId => {
    if (
      this.props.currentTaskModalId
      // &&
      // this.props.currentTaskModalId !== taskId
    ) {
      this.props.dispatch(currentTaskActions.fetchCurrentTaskDetail(taskId))
      this.props.dispatch(currentTaskActions.setCurrentTaskModalId(taskId))
    } else {
      this.detailsModal.open({ id: taskId })
    }
  }

  render() {
    return (
      <div>
        <CurrentTaskDetailsModal
          referer={'specRate'}
          onSuccess={this.hideNotification}
          ref={conn => {
            if (conn) this.detailsModal = conn
          }}
          currentTasks={this.state.currentTasks}
        />
      </div>
    )
  }
}

export default connect(state => {
  const { corporate, oidc } = state

  return {
    token: oidc.user.access_token,
    currentTasksFilter: corporate.currentTasksFilter,
    currentTaskModalId: corporate.currentTaskModalId
  }
})(SpecRateCurrentTasksGlobal)
