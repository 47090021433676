import { depositConstants } from '../../constants/constants'

const initialState = {
  depositCalculateCancellation: undefined,
  depositCalculateCancellationLoading: true,
  depositCalculateCancellationLoadingError: null,

  partlyCancellationData: undefined,
  partlyCancellationDataLoading: false,
  partlyCancellationDataLoadingError: null,

  depositFiledAmountHistory: undefined,
  depositFiledAmountHistoryLoading: false,
  depositFiledAmountHistoryLoadingError: null,

  depositStatements: [],
  depositStatementsLoading: false,
  depositStatementsLoadingError: null,

  depositListLoading: false,
  depositListLoadingError: null,
  depositInterestCalculator: ''
}

export default function depositReducer(state = initialState, action) {
  switch (action.type) {
    case depositConstants.RESET_DEPOSIT_DATA:
      return Object.assign({}, state, {
        depositCalculateCancellation: undefined,
        partlyCancellationData: undefined,
        depositFiledAmountHistory: undefined,
        depositStatements: undefined,
        calculator: ''
      })

    case depositConstants.FETCH_DEPOSIT_CALCULATE_CANCELLATION:
      return Object.assign({}, state, {
        depositCalculateCancellation: undefined,
        depositCalculateCancellationLoading: true,
        depositCalculateCancellationLoadingError: null
      })

    case depositConstants.FETCH_DEPOSIT_CALCULATE_CANCELLATION_SUCCESS:
      return Object.assign({}, state, {
        depositCalculateCancellation: action.payload,
        depositCalculateCancellationLoading: false,
        depositCalculateCancellationLoadingError: null
      })

    case depositConstants.FETCH_DEPOSIT_CALCULATE_CANCELLATION_ERROR:
      return Object.assign({}, state, {
        depositCalculateCancellation: undefined,
        depositCalculateCancellationLoading: false,
        depositCalculateCancellationLoadingError: action.error
      })

    case depositConstants.FETCH_PARTLY_CANCELLATION_DATA:
      return Object.assign({}, state, {
        partlyCancellationData: undefined,
        partlyCancellationDataLoading: true,
        partlyCancellationDataLoadingError: null
      })
    case depositConstants.FETCH_PARTLY_CANCELLATION_DATA_SUCCESS:
      return Object.assign({}, state, {
        partlyCancellationData: action.payload,
        partlyCancellationDataLoading: false,
        partlyCancellationDataLoadingError: null
      })
    case depositConstants.FETCH_PARTLY_CANCELLATION_DATA_ERROR:
      return Object.assign({}, state, {
        partlyCancellationData: undefined,
        partlyCancellationDataLoading: false,
        partlyCancellationDataLoadingError: action.error
      })

    case depositConstants.FETCH_DEPOSIT_FILED_AMOUNT_HISTORY:
      return Object.assign({}, state, {
        depositFiledAmountHistory: [],
        depositFiledAmountHistoryLoading: true,
        depositFiledAmountHistoryLoadingError: null
      })
    case depositConstants.FETCH_DEPOSIT_FILED_AMOUNT_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        depositFiledAmountHistory: action.payload,
        depositFiledAmountHistoryLoading: false,
        depositFiledAmountHistoryLoadingError: null
      })
    case depositConstants.FETCH_DEPOSIT_FILED_AMOUNT_HISTORY_ERROR:
      return Object.assign({}, state, {
        depositFiledAmountHistoryLoading: false,
        depositFiledAmountHistoryLoadingError: action.error
      })

    case depositConstants.FETCH_DEPOSITSTATEMENTS:
      return Object.assign({}, state, {
        depositStatements: [],
        depositStatementsLoading: true,
        depositStatementsLoadingError: null
      })
    case depositConstants.FETCH_DEPOSITSTATEMENTS_SUCCESS:
      return Object.assign({}, state, {
        depositStatements: action.payload,
        depositStatementsLoading: false,
        depositStatementsLoadingError: null
      })
    case depositConstants.FETCH_DEPOSITSTATEMENTS_ERROR:
      return Object.assign({}, state, {
        depositStatementsLoading: false,
        depositStatementsLoadingError: action.error
      })

    case depositConstants.FETCH_DEPOSIT_INTEREST_CALCULATOR:
      return Object.assign({}, state, {
        calculator: [],
        depositCalculateCancellationLoading: true,
        depositCalculateCancellationLoadingError: null
      })
    case depositConstants.FETCH_DEPOSIT_INTEREST_CALCULATOR_SUCCESS:
      return Object.assign({}, state, {
        calculator: action.payload,
        depositCalculateCancellationLoading: false,
        depositCalculateCancellationLoadingError: null
      })
    case depositConstants.FETCH_DEPOSIT_INTEREST_CALCULATOR_ERROR:
      return Object.assign({}, state, {
        calculator: false,
        depositCalculateCancellationLoadingError: action.error
      })

    default:
      return state
  }
}
