import React from 'react'
import { withRouter } from 'react-router-dom'
import Icon from '../../../common/SimpleComponents/Icon'
import { mailBox } from '../../../constants/constants'
import moment from 'moment'
function NavBarNotificationItem({
  id,
  body,
  date,
  isMustRead,
  isRead,
  onChooseMessage,
  history
}) {
  const handleClick = e => {
    e.stopPropagation()
    onChooseMessage(id, mailBox.STEP_INBOX, true)
    history.push('/notifications')
  }

  const messageTxt = body.substr(0, 30) + '...'
  return (
    <>
      <div
        id={`div-Header-${id}`}
        className="lb-subnav-item"
        onClick={handleClick}
      >
        <div
          className={`lb-tooltip-item fnt-low-m fnt-11 flex-aligned ${
            isRead ? 'item-read' : ''
          }`}
        >
          <div>
            <div className="flex-aligned">
              <div className="fnt-9 fnt-low-m mail-date" onClick={handleClick}>
                {moment.unix(date).format('DD/MM/YYYY h:mm')}
              </div>
              {isMustRead && (
                <i className="icn icn-warning-new ml-10 is-red">
                  <Icon name="icn-warning-new" view="0 0 32 32" />
                </i>
              )}
            </div>
            <div className="fnt-11 fnt-low mail-text" onClick={handleClick}>
              {messageTxt}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default withRouter(NavBarNotificationItem)
