import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import Icon from '../../../common/SimpleComponents/Icon'
import LbModal from '../../../common/Modal/LbModal'
import i18n from '../../../../i18n'
import { history } from '../../../helpers/history'
import hasUserPermission, {
  corporateUserPermissions
} from '../../../helpers/hasUserPermission'

export const canTransferToAccount = () =>
  hasUserPermission(corporateUserPermissions.TRANSFERS_OWN_ACCOUNT)

export const canTransferToSomeone = () =>
  hasUserPermission(corporateUserPermissions.TRANSFERS_INSIDE) &&
  hasUserPermission(
    corporateUserPermissions.TRANSFERS_OTHER_BANK_NATIONAL_CURRENCY
  ) &&
  hasUserPermission(
    corporateUserPermissions.TRANSFERS_OTHER_BANK_FOREIGN_CURRENCY
  )

export const canTransferToBudget = () =>
  hasUserPermission(corporateUserPermissions.TRANSFERS_BUDGET) &&
  hasUserPermission(corporateUserPermissions.TRANSFERS_BUDGET_IMPOSTOR)

export const canTopupMobile = () =>
  hasUserPermission(corporateUserPermissions.TRANSFERS_MOBILE_TOP_UP)

export const canPayUtility = () =>
  hasUserPermission(corporateUserPermissions.TRANSFERS_UTILITY_OTHERS)

export const canAnyTransfer = () =>
  canTransferToAccount() ||
  canTransferToSomeone() ||
  canTransferToBudget() ||
  canTopupMobile() ||
  canPayUtility()

const CreateTransferModal = ({ modalRef }) => (
  <LbModal
    ref={modalRef}
    header={i18n.t('Templates.ModalTitle')}
    className="mw-500"
    blockBodyClass="lb-block-body"
  >
    <Fragment>
      <div className="lb-add-template">
        {canTransferToAccount() && (
          <div
            id="div-addAccount-NewTemplateTypeChooser"
            className="popup-row-style"
            onClick={() =>
              history.push({
                pathname: '/transfer/toAccount'
              })
            }
          >
            <i className="icn icn-list-template">
              <Icon name="icn-arrows-new-retail" view="0 0 34.227 29.049" />
            </i>

            <span>
              <Trans i18nKey="CurrentTasks.TransferBetweenOwnAccounts" />
            </span>

            <i className="icn icn-arr-right">
              <Icon name="icn-arr-right" view="0 0 8.885 15.795" />
            </i>
          </div>
        )}

        {canTransferToSomeone() && (
          <div
            id="div-addOther-NewTemplateTypeChooser"
            className="popup-row-style"
            onClick={() =>
              history.push({
                pathname: '/transfer/toSomeone'
              })
            }
          >
            <i className="icn icn-list-template">
              <Icon name="icn-avatar-retail" view="0 0 17.722 22.327" />
            </i>

            <span>
              <Trans i18nKey="CurrentTasks.TransferToSomeoneElse" />
            </span>

            <i className="icn icn-arr-right">
              <Icon name="icn-arr-right" view="0 0 8.885 15.795" />
            </i>
          </div>
        )}

        {canTransferToBudget() && (
          <div
            id="div-addBudget-NewTemplateTypeChooser"
            className="popup-row-style"
            onClick={() =>
              history.push({
                pathname: '/transfer/trex'
              })
            }
          >
            <i className="icn icn-list-template">
              <Icon name="icn-budget3" view="0 0 62.646 53.764" />
            </i>

            <span>
              <Trans i18nKey="CurrentTasks.TransferToTreasury" />
            </span>

            <i className="icn icn-arr-right">
              <Icon name="icn-arr-right" view="0 0 8.885 15.795" />
            </i>
          </div>
        )}

        {canTopupMobile() && (
          <div
            id="div-addMobile-NewTemplateTypeChooser"
            className="popup-row-style"
            onClick={() =>
              history.push({
                pathname: '/payments/mobile'
              })
            }
          >
            <i className="icn icn-list-template">
              <Icon name="icn-phone-new-retail" view="0 0 19.904 35.385" />
            </i>

            <span>
              <Trans i18nKey="CurrentTasks.TransferTopUpMobile" />
            </span>

            <i className="icn icn-arr-right">
              <Icon name="icn-arr-right" view="0 0 8.885 15.795" />
            </i>
          </div>
        )}

        {canPayUtility() && (
          <div
            id="div-addUtility-NewTemplateTypeChooser"
            className="popup-row-style"
            onClick={() =>
              history.push({
                pathname: '/payments/utility'
              })
            }
          >
            <i className="icn icn-list-template">
              <Icon name="icn-home-new-retail" view="0 0 39.205 35.419" />
            </i>

            <span>
              <Trans i18nKey="CurrentTasks.TransferUtilityPayments" />
            </span>

            <i className="icn icn-arr-right">
              <Icon name="icn-arr-right" view="0 0 8.885 15.795" />
            </i>
          </div>
        )}
      </div>
    </Fragment>
  </LbModal>
)

CreateTransferModal.propTypes = {
  modalRef: PropTypes.func
}

export default CreateTransferModal
