const SET_OPERATION_ID = 'SET_OPERATION_ID'

export const setOperationId = id => ({ type: SET_OPERATION_ID, payload: id })

const initialState = { operationId: "" }

const operationReducer = (state = initialState, action) => {
  if (action.type === SET_OPERATION_ID) {
    return { ...state, operationId: action.payload }
  }
  return state
}

export default operationReducer
