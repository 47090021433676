import { downloadFileRequest, jsonRequest } from '../../helpers/apiRequest'
import { baseUrl } from '../../helpers/baseUrl'
import { unixEndDateWithTimestamp } from '../../helpers/dateFormatter'

const endpoint = '/currenttask/v1/corporate'

const httpErrorsOff = { internalServerError: false, badRequest: false }

export const fetchCurrentTasksAjax = (
  {
    senderIban,
    isGroupTransfer,
    toAmount,
    fromAmount,
    currency,
    paymentTypes = [],
    paymentName,
    creatorUserId,
    signedBy,
    receiverIban,
    receiverName,
    taxPayerCode,
    feeTo,
    feeFrom,
    needMyAuthorization,
    keyword,
    skip = 0,
    take = 100,
    startDate,
    endDate,
    status = [],
    loadMoreCurrentTasks = false
  },
  excelExport = false
) => {
  const params = {}

  if (senderIban) params.senderIban = senderIban
  if (isGroupTransfer) params.isGroupTransfer = isGroupTransfer
  if (toAmount) params.toAmount = toAmount
  if (fromAmount) params.fromAmount = fromAmount
  if (currency) params.currency = currency
  if (paymentName) params.paymentName = paymentName
  if (creatorUserId) params.creatorUserId = creatorUserId
  if (signedBy) params.signedBy = signedBy
  if (receiverIban) params.receiverIban = receiverIban
  if (receiverName) params.receiverName = receiverName
  if (taxPayerCode) params.taxPayerCode = taxPayerCode
  if (feeTo) params.feeTo = feeTo
  if (feeFrom) params.feeFrom = feeFrom
  if (typeof needMyAuthorization === typeof true)
    params.needMyAuthorization = needMyAuthorization

  if (keyword) params.keyword = keyword
  if (skip) params.skip = skip
  if (take) params.take = loadMoreCurrentTasks ? take + 1 : take
  if (startDate) params.startDate = startDate.startOf('day').unix()
  // if (endDate) params.endDate = endDate.endOf('day').unix()
  if (endDate) params.endDate = unixEndDateWithTimestamp(endDate)

  let url = `${baseUrl}${endpoint}/${
    excelExport ? 'export-excel' : 'get-current-task'
  }`
  let strObject = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

  if (status && status.length) {
    let statuses = status.map(s => `status=${encodeURIComponent(s)}`).join('&')
    if (statuses) strObject = strObject + '&' + statuses
  }

  if (paymentTypes && paymentTypes.length) {
    let types = paymentTypes
      .map(s => `paymentTypes=${encodeURIComponent(s)}`)
      .join('&')
    if (types) strObject = strObject + '&' + types
  }

  if (strObject) {
    url = `${url}?${strObject}`
  }

  if (excelExport) {
    return downloadFileRequest(url, 'current_transactions', 'xls', true)
  }

  return jsonRequest(url, true)
}

export const fetchCurrentTaskDetailsAjax = id => {
  return jsonRequest(`${baseUrl}${endpoint}/get-current-task/${id}`, true)
}

export const cancel = (id = '', reason = '') => {
  const paymentsId = `paymentsId=${encodeURIComponent(id)}`
  const reasonForCancel = reason ? `&reasonForCancel=${reason}` : ''

  return jsonRequest(
    `${baseUrl}${endpoint}/cancel?${paymentsId}${reasonForCancel}`,
    true,
    undefined,
    'DELETE',
    httpErrorsOff
  )
}

export const cancelCollection = (ids = [], reason = '') => {
  const paymentsId = ids
    .map(s => `paymentsId=${encodeURIComponent(s)}`)
    .join('&')
  const reasonForCancel = reason ? `&reasonForCancel=${reason}` : ''

  return jsonRequest(
    `${baseUrl}${endpoint}/cancel-collection?${paymentsId}${reasonForCancel}`,
    true,
    undefined,
    'DELETE',
    httpErrorsOff
  )
}

export const authorize = (id = '', code = '', validationId) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/authorize`,
    true,
    {
      paymentId: id,
      twoFactorCode: code || undefined,
      validationId: validationId
    },
    'PUT',
    httpErrorsOff
  )
}

export const authorizeCollection = (ids = [], code = '', validationId) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/authorize-collection`,
    true,
    {
      paymentsId: ids,
      twoFactorCode: code || undefined,
      validationId: validationId
    },
    'PUT',
    httpErrorsOff
  )
}

export const confirmCollection = (paymentsId = []) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/confirm-collection`,
    true,
    { paymentsId },
    'POST',
    httpErrorsOff
  )
}

export const confirm = (paymentId, description, additionalDescription) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/confirm`,
    true,
    { paymentId, description, additionalDescription },
    'POST',
    httpErrorsOff
  )
}

export const prepeareForAuthorization = (ids = []) => {
  const paymentsId = ids
    .map(s => `paymentsId=${encodeURIComponent(s)}`)
    .join('&')

  return jsonRequest(
    `${baseUrl}${endpoint}/preparation-for-authorization?${paymentsId}`,
    true,
    undefined,
    'GET',
    httpErrorsOff
  )
}
export const checkPaymentValidation = validationId => {
  return jsonRequest(
    `${baseUrl}/currenttask/v1/validation/Status?validationId=${validationId}`,
    true,
    null,
    'GET',
    httpErrorsOff
  )
}

export const removeCollection = (ids = []) => {
  const paymentsId = ids
    .map(s => `paymentsId=${encodeURIComponent(s)}`)
    .join('&')

  return jsonRequest(
    `${baseUrl}${endpoint}/remove-collection?${paymentsId}`,
    true,
    undefined,
    'DELETE',
    httpErrorsOff
  )
}

export const remove = id => {
  const paymentId = encodeURIComponent(id)

  return jsonRequest(
    `${baseUrl}${endpoint}/remove/${paymentId}`,
    true,
    undefined,
    'DELETE',
    httpErrorsOff
  )
}

export const edit = (id, description, additionalDescription, senderAmount) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/edit`,
    true,
    { paymentId: id, description, additionalDescription, senderAmount },
    'POST',
    httpErrorsOff
  )
}

export const editFutureDate = (paymentId, futureDate) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/edit-future-date`,
    true,
    { paymentId, futureDate },
    'PUT',
    httpErrorsOff
  )
}

export const getUsers = query => {
  return jsonRequest(
    `${baseUrl}${endpoint}/get-user-list?name=${encodeURIComponent(query)}`,
    true
  )
}

export const fetchSpecialRatePendingTasksAjax = () => {
  return jsonRequest(
    `${baseUrl}/currenttask/v1/current-task/payment-awaiting-approval`,
    true
  )
}

export function exportCurrentTaskDetails(id) {
  if (!id) {
    return
  }
  const url = `${baseUrl}${endpoint}/export/${id}/pdf`
  return downloadFileRequest(url, `Current_Task_Details_${id}`, 'pdf', true)
}
