import React, { Component } from 'react'
import Modal from 'react-modal'
import Icon from '../SimpleComponents/Icon'
import Background from 'assets/images/popup_pattern.svg'

Modal.setAppElement('#root')

class LbModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: props.isOpen ? props.isOpen : false
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.isOpened = this.isOpened.bind(this)
  }

  handleOpenModal() {
    this.setState({ showModal: true })
    if (this.props.modalOpenCallback) {
      this.props.modalOpenCallback()
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false })

    if (this.props.modalCloseCallback) {
      this.props.modalCloseCallback()
    }
  }
  isOpened() {
    return this.state.showModal
  }
  undefinedToEmptyString(className) {
    return className ? className : ''
  }

  render() {
    const isOpen = this.state.showModal || this.props.open
    return (
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        contentLabel="Modal #1 Global Style Override Example"
        onRequestClose={this.handleCloseModal}
        className={`lb-popup-body mw-600 ${this.undefinedToEmptyString(
          this.props.className
        )}`}
        overlayClassName={`lb-popup ${this.undefinedToEmptyString(
          this.props.overlayClassName
        )}`}
        style={{ ...this.props.style }}
        shouldCloseOnEsc={
          this.props.shouldCloseOnEsc === undefined
            ? true
            : this.props.shouldCloseOnEsc
        }
      >
        {(!this.props.notRenderChildren || isOpen) && (
          <>
            <div
              className="lb-block-head po-r"
              // style={{
              //   backgroundImage: `url(${Background})`,
              //   backgroundRepeat: 'no-repeat',
              //   backgroundPosition: 'top right',
              //   backgroundSize: 'auto 100%'
              // }}
            >
              <div className="flex-1 mr-10 header-title">
                {this.props.header}
              </div>
              {/** Hide close Button */}
              {!this.props.hideCloseTopButton && (
                <div
                  className="flex-align-center icn-block"
                  onClick={
                    this.props.customCloseEvent
                      ? this.props.customCloseEvent
                      : this.handleCloseModal
                  }
                >
                  <i className="icn icn-close" id="i-modalCloseButton-LbModal">
                    <Icon name="icn-close" view="0 0 12 12" />
                  </i>
                </div>
              )}

              <div className="lb-popup-line" />
            </div>
            <div
              className={`lb-block-body fnt-16 fnt-low-m p-0 ${this.undefinedToEmptyString(
                this.props.blockBodyClass
              )}`}
            >
              {this.props.children}
            </div>
          </>
        )}
      </Modal>
    )
  }
}

LbModal.defaultProps = {
  //Hide close Button (Icon)
  hideCloseTopButton: false
}
export default LbModal
