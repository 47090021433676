export default {
  left: [
    {
      name: 'accounts1',
      component: 'Accounts',
      hidden: false,
      draggable: true,
      position: 'left'
    },
    {
      name: 'credits1',
      component: 'CreditCards',
      hidden: false,
      draggable: true,
      position: 'left'
    },
    {
      name: 'deposits1',
      component: 'Deposits',
      hidden: false,
      draggable: true,
      position: 'left'
    },
    {
      name: 'loans',
      component: 'Loans',
      hidden: false,
      draggable: true,
      position: 'left'
    },
    {
      name: 'blockedAmounts',
      component: 'BlockedAmounts',
      hidden: false,
      draggable: true,
      position: 'left'
    },
    {
      name: 'otherBlockedAmounts',
      component: 'OtherBlockedAmounts',
      hidden: false,
      draggable: true,
      position: 'left'
    },
    {
      name: 'investments',
      component: 'Investments',
      hidden: false,
      draggable: true,
      position: 'left'
    },
    {
      name: 'transactions',
      component: 'Transactions',
      hidden: false,
      draggable: true,
      position: 'left'
    },
    {
      name: 'currentTasks',
      component: 'CurrentTasks',
      hidden: false,
      draggable: true,
      position: 'left'
    }
  ],
  right: [
    {
      name: 'companyName',
      component: 'CompanyName',
      hidden: false,
      draggable: true,
      position: 'right'
    },

    {
      name: 'personalbanker',
      component: 'PersonalBanker',
      hidden: false,
      draggable: true,
      position: 'right'
    },
    {
      name: 'currencyRates',
      component: 'CurrencyRates',
      hidden: false,
      draggable: true,
      position: 'right'
    },
    {
      name: 'pensionSavings',
      component: 'PensionSavings',
      hidden: false,
      draggable: false,
      position: 'right'
    },
    
  ]
}
