import React from 'react'
import { Helmet } from 'react-helmet'
import fav_16x16_eng from '../../../assets/images/favicons/16.png'
import fav_32x32_eng from '../../../assets/images/favicons/32.png'
import fav_57x57_eng from '../../../assets/images/favicons/57.png'
import fav_72x72_eng from '../../../assets/images/favicons/72.png'
import fav_114x114_eng from '../../../assets/images/favicons/114.png'
import fav_120x120_eng from '../../../assets/images/favicons/120.png'
import fav_144x144_eng from '../../../assets/images/favicons/144.png'
import fav_152x152_eng from '../../../assets/images/favicons/152.png'
import fav_180x180_eng from '../../../assets/images/favicons/180.png'

const Favicons = () => {
  return (
    <Helmet>
      <link rel="icon" type="image/png" sizes="16x16" href={fav_16x16_eng} />
      <link rel="icon" type="image/png" sizes="32x32" href={fav_32x32_eng} />
      <link rel="apple-touch-icon" sizes="57x57" href={fav_57x57_eng} />
      <link rel="apple-touch-icon" sizes="72x72" href={fav_72x72_eng} />
      <link rel="apple-touch-icon" sizes="114x114" href={fav_114x114_eng} />
      <link rel="apple-touch-icon" sizes="120x120" href={fav_120x120_eng} />
      <link rel="apple-touch-icon" sizes="144x144" href={fav_144x144_eng} />
      <link rel="apple-touch-icon" sizes="152x152" href={fav_152x152_eng} />
      <link rel="apple-touch-icon" sizes="180x180" href={fav_180x180_eng} />
    </Helmet>
  )
}

export default Favicons
