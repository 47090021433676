import { mailBox } from '../../constants/constants'
import {
  fetchMustReadMessage,
  fetchLastUnreadInboxMessages,
  fetchMessageTypes,
  fetchInboxMessagesCount,
  fetchInboxMessages,
  fetchMarkedAsRead,
  fetchSentMessages,
  fetchSentMessagesCount,
  fetchSingleMessage,
  replayInboxMesage,
  senNewMessage,
  deleteMessages,
  uploadFileAjax,
  downloadFileAjax,
  featchSearchedMessage
} from '../../api/mailbox.api'

function setLoading(payload) {
  return { type: mailBox.SET_MESSAGES_LOADING, payload }
}
export function setSingleMessageLoading(payload) {
  return { type: mailBox.SET_SINGEL_MESSAGE_LOADING, payload }
}

export function setSingelMessage(data) {
  return { type: mailBox.SET_SINGEL_MESSAGE, payload: data }
}

export function clearSingelMessage() {
  return { type: mailBox.CLEAR_SINGEL_MESSAGE }
}

function setInboxMessages(payload) {
  return { type: mailBox.SET_INBOX_MESSAGES, payload }
}
function setSentMessages(payload) {
  return { type: mailBox.SET_SENT_MESSAGES, payload }
}

function setTotal(payload) {
  return { type: mailBox.SET_TOTAL_MESSAGES, payload }
}
function setNewFetchedMessages(payload) {
  return { type: mailBox.SET_NEW_MESSAGES, payload }
}

function clearMessages(payload) {
  return { type: mailBox.CLEAR_MESSAGES, payload }
}

function setLastUnreadMessages(messages) {
  return { type: mailBox.SET_LAST_UNREAD_MESSAGES, payload: messages }
}

export function uploadFile(file) {
  return dispatch => {
    return uploadFileAjax(file)
  }
}

export function donwloadFile(notificationId, id, format, fileName) {
  return dispatch => {
    return downloadFileAjax(notificationId, id, format, fileName)
  }
}

export function MarkedAsRead(payload) {
  return dispatch => {
    dispatch({ type: mailBox.SET_MESSAGE_MARK_AS_READ, payload })
    return fetchMarkedAsRead(payload)
  }
}

export function getMessageTypes() {
  return dispatch => {
    fetchMessageTypes()
      .then(res => {
        dispatch({ type: mailBox.SET_MESSAGE_TYPES, payload: res })
      })
      .catch(err => console.log(err))
  }
}

export function checkForNewMessage() {
  return (dispatch, state) => {
    const { inboxes } = state().mailBox
    fetchInboxMessages({ skip: 0, take: mailBox.PER_PAGE }).then(messages => {
      if (inboxes && messages) {
        const newMessages = []

        messages.map(message => {
          const msgExist = inboxes.findIndex(msg => message.id === msg.id)
          if (msgExist == -1) {
            newMessages.push(message)
          }
        })

        if (newMessages.length) {
          dispatch(setNewFetchedMessages(newMessages))
        }
      }
    })
  }
}

export function getInboxMessages(
  clearPagination = false,
  markAsRead = false,
  fetchSingleNotification = true
) {
  return (dispatch, state) => {
    let { page, total, messageID } = state().mailBox
    let getPaginationPage = !total
      ? page + 1
      : page + 1 < Math.round(total / page)
      ? page + 1
      : false

    if (clearPagination) {
      getPaginationPage = 1
      page = 0
      dispatch(clearMessages('inboxes'))
    }
    // whene chnage tab amd get first mesage to open clearing
    if (getPaginationPage == 1) {
      dispatch(clearSingelMessage())
    }

    if (
      (getPaginationPage - 1 * mailBox.PER_PAGE < total && total != 0) ||
      (getPaginationPage == 1 && !total) ||
      clearPagination
    ) {
      dispatch(setLoading(true))

      return fetchInboxMessages({
        skip: page * mailBox.PER_PAGE,
        take: mailBox.PER_PAGE
      }).then(res => {
        if (clearPagination) {
          dispatch(clearMessages('inboxes'))
        }

        res = res ? res : []
        // res = res.filter(i => i.isRead);
        if (res.length) {
          fetchInboxMessagesCount().then(response => {
            dispatch(setTotal(response))
          })

          // whene chnage tab amd get first mesage to open
          let firstMesage = res[0]
          // if (getPaginationPage == 1 && messageID != firstMesage.id) {
          if (
            getPaginationPage == 1 &&
            !state().mailBox.messageID &&
            fetchSingleNotification
          ) {
            // dispatch(clearSingelMessage());
            dispatch(
              getSingleMessage(firstMesage.id, mailBox.STEP_INBOX, markAsRead)
            )
          }
        } else if (messageID) {
          dispatch(clearSingelMessage())
        }

        dispatch(setInboxMessages({ messages: res, page: getPaginationPage }))
        dispatch(setLoading(false))
      })
    }
  }
}

export function getSentMessages(clearPagination) {
  return (dispatch, state) => {
    let { page, total, messageID } = state().mailBox
    let getPaginationPage = !total
      ? page + 1
      : page + 1 < Math.round(total / page)
      ? page + 1
      : false

    if (clearPagination) {
      getPaginationPage = 1
      page = 0
      dispatch(clearMessages('sents'))
    }

    if (
      ((getPaginationPage - 1) * mailBox.PER_PAGE < total && total != 0) ||
      (getPaginationPage == 1 && !total) ||
      clearPagination
    ) {
      dispatch(setLoading(true))

      fetchSentMessages({
        skip: page * mailBox.PER_PAGE,
        take: mailBox.PER_PAGE
      }).then(res => {
        res = res ? res : []
        if (res.length) {
          fetchSentMessagesCount().then(response => {
            dispatch(setTotal(response))
          })
          // whene chnage tab amd get first mesage to open
          let firstMesage = res[0]
          // if (getPaginationPage == 1 && messageID != firstMesage.id) {
          if (getPaginationPage == 1) {
            dispatch(clearSingelMessage())
            dispatch(getSingleMessage(firstMesage.id, mailBox.STEP_SENT))
          }
        } else if (messageID) {
          dispatch(clearSingelMessage())
        }
        dispatch(setSentMessages({ messages: res, page: getPaginationPage }))
        dispatch(setLoading(false))
      })
    }
  }
}

export function checkedAll(status) {
  return dispatch => {
    dispatch({ type: mailBox.CHECKED_ALL_MESSAGES, payload: status })
  }
}
export function checkedSingleMesage(item) {
  return dispatch => {
    dispatch({ type: mailBox.SET_CHECK_STATUS_SINGLE_MESSAGE, payload: item })
  }
}

export function deleteCheckedMessages(step) {
  let url = 'Inbox'
  // if (step !== mailBox.STEP_INBOX) {
  //   url = 'Outbox';
  // }
  return (dispatch, state) => {
    let { items } = state().mailBox
    items = items.length ? items.filter(i => i.checked) : []
    const ids = items.length ? items.map(i => i.id) : null
    deleteMessages({ ids, url }).then(res => {
      dispatch({ type: mailBox.DELETE_MESSAGES })
    })
  }
}

export function deleteSingleMessage(id, step) {
  let url = 'Inbox'
  // if (step !== mailBox.STEP_INBOX) {
  //   url = 'Outbox';
  // }
  /** second paramteer delete from inbox or sent message */
  return dispatch => {
    deleteMessages({ ids: [id], url }).then(() => {
      dispatch({ type: mailBox.DELETE_SINGEL_MESSAGE, payload: id })
      // dispatch(getInboxMessages(true));
    })
  }
}

export function searchMessage(text, step) {
  let url = 'Inbox'
  if (step !== mailBox.STEP_INBOX) {
    url = 'Outbox'
  }

  return (dispatch, state) => {
    let { page: skip, total: take, search } = state().mailBox

    if (search != text) {
      skip = 0
      take = mailBox.PER_PAGE
    } else if (take != null) {
      skip = skip ? skip + mailBox.PER_PAGE : mailBox.PER_PAGE
      take = mailBox.PER_PAGE
    }

    if (!take) {
      return
    }

    if (!text.trim().length) {
      return
    }

    dispatch(setLoading(true))
    featchSearchedMessage({ text, url, skip, take })
      .then(res => {
        res = res ? res : []
        if (res.length < mailBox.PER_PAGE) {
          take = null
          skip = 0
        }

        dispatch({
          type: mailBox.SET_SEARCHED_MESSAGES,
          payload: { messages: res, page: skip, total: take, search: text }
        })

        dispatch(setLoading(false))
      })
      .catch(err => {
        dispatch(setLoading(false))
        console.log(err)
      })
  }
}

export function getSingleMessage(id, step, markAsRead) {
  let url = 'Inbox'
  if (step !== mailBox.STEP_INBOX) {
    url = 'Outbox'
  }
  return (dispatch, state) => {
    let { messagePage: skip, messageTotal: take, messageID } = state().mailBox
    if (messageID != id) {
      skip = 0
      take = mailBox.SINGLE_MESSAGE_PER_PAGE
      dispatch(clearSingelMessage())
    } else if (take != null) {
      skip = skip
        ? skip + mailBox.SINGLE_MESSAGE_PER_PAGE
        : mailBox.SINGLE_MESSAGE_PER_PAGE
      take = mailBox.SINGLE_MESSAGE_PER_PAGE
    }

    if (!take) {
      return
    }

    dispatch(setSingleMessageLoading(true))
    fetchSingleMessage({ id, url, skip, take }).then(res => {
      if (url == 'Inbox' && markAsRead && id) {
        dispatch(MarkedAsRead(id))
      }

      let message = res && res.length ? res : null

      if (res && res.length < mailBox.SINGLE_MESSAGE_PER_PAGE) {
        take = null
        skip = 0
      }

      dispatch(setSingelMessage({ message, id, page: skip, total: take }))

      dispatch(setSingleMessageLoading(false))
    })
  }
}

export function sendNewMessage({ subject, typeId, body, step, attachments }) {
  return (dispatch, state) => {
    let { messageID, sents } = state().mailBox
    if (step == mailBox.STEP_SENT) {
      messageID = sents.find(i => i.id == messageID)
      messageID = messageID ? messageID.parentId : null
    }

    if (!typeId) {
      return replayInboxMesage({
        parentId: messageID,
        body,
        subject,
        attachments
      }).then(res => {
        if (step == mailBox.STEP_SENT) {
          dispatch(getSentMessages(true))
        } else {
          dispatch(getInboxMessages(true))
        }
      })
    } else {
      return senNewMessage({ subject, typeId, body, attachments }).then(res => {
        if (step == mailBox.STEP_SENT) {
          dispatch(getSentMessages(true))
        } else {
          dispatch(getInboxMessages(true))
        }

        return res
      })
    }
  }
}

export function getLastUnreadInboxMessages() {
  return async dispatch => {
    try {
      let response = await fetchLastUnreadInboxMessages()
      if (response) {
        dispatch(setLastUnreadMessages(response))
      }
    } catch (error) {
      return error
    }
  }
}

export function getMustReadMessage() {
  return async dispatch => {
    try {
      const response = await fetchMustReadMessage()
      return response
    } catch (error) {
      return error
    }
  }
}
