import { downloadFileRequest, jsonRequest } from '../../helpers/apiRequest'
import { baseUrl } from '../../helpers/baseUrl'
import { getUrlWithQueryString } from '../../helpers/util'

export function fetchBatchCurrentTasksAjax(params) {
  let url = getUrlWithQueryString(
    `${baseUrl}/currenttask/v1/batch/current-tasks`,
    params
  )
  return jsonRequest(url, true, undefined, 'GET', {
    badRequest: true,
    internalServerError: true
  })
}

export function fetchBatchDetailAjax(batchId) {
  return jsonRequest(
    `${baseUrl}/currenttask/v1/batch/${batchId}/details`,
    true,
    undefined,
    'GET',
    {
      badRequest: true,
      internalServerError: true
    }
  )
}

export function fetchBatchTasksAjax(batchId, params) {
  let url = getUrlWithQueryString(
    `${baseUrl}/currenttask/v1/batch/${batchId}/current-tasks`,
    params
  )
  return jsonRequest(url, true, undefined, 'GET', {
    badRequest: true,
    internalServerError: true
  })
}

export function exportStaffList() {
  return downloadFileRequest(
    `${baseUrl}/currenttask/v1/batch/batchId/excel`,
    'staffList',
    'xls',
    true
  )
}

export function removeBatchTransfer(batchId) {
  return jsonRequest(
    `${baseUrl}/currenttask/v1/corporate/remove/${batchId}`,
    true,
    undefined,
    'DELETE',
    {
      badRequest: true,
      internalServerError: true
    }
  )
}

export function confirmBatchTransfer(batchId, description) {
  return jsonRequest(
    `${baseUrl}/currenttask/v1/corporate/confirm`,
    true,
    { paymentId: batchId, description: description, additionalDescription: '' },
    'POST'
  )
}

export function cancelBatchTransfer(batchId, cancelReason) {
  let params = { paymentsId: batchId }
  if (cancelReason) params.reasonForCancel = cancelReason
  let url = getUrlWithQueryString(
    `${baseUrl}/currenttask/v1/corporate/cancel`,
    params
  )
  return jsonRequest(url, true, undefined, 'DELETE')
}

export function cancel(paymentsId, reasonForCancel) {
  return jsonRequest(
    `${baseUrl}/currenttask/v1/batch/cancel`,
    true,
    { paymentsId, reasonForCancel },
    'DELETE',
    {
      badRequest: true,
      internalServerError: true
    }
  )
}

export function preparationForAuthorization(batchId) {
  return jsonRequest(
    `${baseUrl}/currenttask/v1/corporate/preparation-for-authorization?PaymentsId=${batchId}`,
    true,
    undefined,
    'GET',
    {
      badRequest: false,
      internalServerError: false
    }
  )
}

export function authorizeBatchTransfer(batchId, twoFactorCode, validationId) {
  let params = { paymentId: batchId }
  if (twoFactorCode) params.twoFactorCode = twoFactorCode
  if (validationId) params.validationId = validationId
  return jsonRequest(
    `${baseUrl}/currenttask/v1/corporate/authorize`,
    true,
    params,
    'PUT'
  )
}

export function editInBudget({
  id: paymentId,
  treasuryCode,
  taxPayerName,
  taxPayerCode,
  description,
  additionalDescription,
  senderAmount,
  docNumber
}) {
  let params = {
    treasuryCode,
    taxPayerName,
    taxPayerCode,
    paymentId,
    description,
    additionalDescription,
    senderAmount,
    docNumber
  }
  return jsonRequest(
    `${baseUrl}/currenttask/v1/batch/Edit/in-budget`,
    true,
    params,
    'PUT',
    {
      badRequest: false,
      internalServerError: true
    }
  )
}

export function editBetweenOwnAccount({
  id: paymentId,
  receiverAccountId,
  receiverAmount,
  description,
  additionalDescription,
  senderAmount,
  docNumber
}) {
  let params = {
    receiverAccountId,
    receiverAmount,
    paymentId,
    description,
    additionalDescription,
    senderAmount,
    docNumber
  }
  return jsonRequest(
    `${baseUrl}/currenttask/v1/batch/Edit/between-own-account`,
    true,
    params,
    'PUT',
    {
      badRequest: false,
      internalServerError: true
    }
  )
}

export function editToSomeone({
  id: paymentId,
  receiverName,
  receiverIban,
  receiverBankCountry,
  receiverSwiftCode,
  receiverBankName,
  intermediarySwiftCode,
  intermediaryBankName,
  chargeType,
  description,
  additionalDescription,
  senderAmount,
  docNumber
}) {
  let params = {
    receiverName,
    receiverIban,
    receiverBankCountry,
    receiverSwiftCode,
    receiverBankName,
    intermediarySwiftCode,
    intermediaryBankName,
    chargeType,
    paymentId,
    description,
    additionalDescription,
    senderAmount,
    docNumber
  }
  return jsonRequest(
    `${baseUrl}/currenttask/v1/batch/Edit/to-someone`,
    true,
    params,
    'PUT',
    {
      badRequest: false,
      internalServerError: true
    }
  )
}
