import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import i18n from '../../../../i18n'
import moment from 'moment'
import LbModal from '../../../common/Modal/LbModal'
import numberFormat from '../../../helpers/numberFormat'

class AuthenticationHistoryModal extends Component {
  historyModalOpenHandler = () => {
    this.authenHistoryModal.handleOpenModal()
  }

  render() {
    const {
      senderAmount,
      senderCurrency,
      status,
      statusText,
      awaitingSignRoles,
      signatures,
      regDate,
      createdBy
    } = this.props.currentTask

    // TODO: check if signatures need sorting
    return (
      <LbModal
        ref={modal => {
          this.authenHistoryModal = modal
        }}
        header={i18n.t('Common.AuthenticationHistory')}
        className="mw-700"
        style={{ overlay: { zIndex: 12 } }}
      >
        <div className="lb-grid history-grid">
          <h3 className="pl-15 mb-5 fnt-13 fnt-upper-m fnt-n">
            <Trans i18nKey="CurrentTasks.Details" />:
          </h3>
          <div className="lb-block-row flex-top">
            <div className="lb-ellipsis-wrap w-25">
              <div className="is-title lb-ellipsis">
                <Trans i18nKey="CurrentTasks.Amount" />:
              </div>
              <div className="is-subtext lb-ellipsis">
                {numberFormat(senderAmount)} {senderCurrency}
              </div>
            </div>
            <div className="lb-ellipsis-wrap w-35">
              <div className="is-name lb-ellipsis">
                <Trans i18nKey="CurrentTasks.CurrentTaskStatus" />:
              </div>
              <div className="is-subtext lb-ellipsis">
                {i18n.t(`CurrentTasks.${status}`)}
              </div>
            </div>
            {!!awaitingSignRoles && (
              <div className="lb-ellipsis-wrap w-40">
                <div className="is-name lb-ellipsis">
                  <Trans i18nKey="CurrentTasks.AwaitingSignRoles" />:
                </div>
                <div className="is-subtext lb-ellipsis">
                  {awaitingSignRoles}{' '}
                  {awaitingSignRoles && (
                    <Trans
                      i18nKey={`${
                        awaitingSignRoles === 'I'
                          ? 'Administration.LevelSignature'
                          : 'Administration.UpperLevelSignature'
                      }`}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="lb-block-row flex-wrap p-0">
            <div className="w-100 lb-ellipsis-wrap flex-aligned">
              <div className="is-title lb-ellipsis w-25">
                <Trans i18nKey="CurrentTasks.ActionDate" />:
              </div>
              <div className="is-name lb-ellipsis w-35">
                <Trans i18nKey="CurrentTasks.CurrentTaskAssigner" />:
              </div>
              <div className="is-name lb-ellipsis w-40">
                <Trans i18nKey="CurrentTasks.CurrentTaskAssignerAction" />:
              </div>
            </div>

            <div className="w-100 lb-ellipsis-wrap flex-aligned fnt-low-b mt-5">
              <div className="is-title lb-ellipsis w-25 fnt-b">
                {moment.unix(regDate).format('DD/MM/YYYY')}
              </div>
              <div className="is-name lb-ellipsis w-35 fnt-b">{createdBy}</div>
              <div className="is-name lb-ellipsis w-40 fnt-b">
                {i18n.t('CurrentTasks.Create')}
              </div>
            </div>

            {signatures &&
              !!signatures.length &&
              signatures
                .filter(s => s.updateDate && s.userId)
                .sort((a, b) => {
                  return a.updateDate - b.updateDate
                })
                .map((signature, index) => (
                  <div
                    key={index}
                    className="w-100 lb-ellipsis-wrap flex-aligned fnt-low-b mt-5"
                  >
                    <div className="is-title lb-ellipsis w-25 fnt-b">
                      {signature.updateDate &&
                        moment.unix(signature.updateDate).format('DD/MM/YYYY')}
                    </div>
                    <div className="is-name lb-ellipsis w-35 fnt-b">
                      {signature.fullName}
                    </div>
                    <div className="is-name lb-ellipsis w-40 fnt-b">
                      {i18n.t(`CurrentTasks.${signature.userAction}`)}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </LbModal>
    )
  }
}

export default AuthenticationHistoryModal
