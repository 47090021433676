import React from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import './CropImage.css'

export default class CropImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cropResult: null,
      save: this.props.saveCroppedImage
    }

    this.cropImage = this.cropImage.bind(this)
  }

  cropImage() {
    return this.cropper.getCroppedCanvas().toDataURL()
  }

  render() {
    return (
      <Cropper
        ref={cropper => {
          this.cropper = cropper
        }}
        src={this.props.image}
        style={{ maxHeight: 400, width: '100%' }}
        viewMode={1}
        aspectRatio={1 / 1}
        guides={false}
        background={false}
        minCropBoxWidth={100}
      />
    )
  }
}
