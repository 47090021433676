import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import {
  MarkedAsRead,
  donwloadFile
} from '../../../redux/actions/mailbox.actions'
import NavBarNotificationItem from './NavBarNotificationItem'
import LbModal from '../../../common/Modal/LbModal'
import moment from 'moment'
import i18n from '../../../../i18n'
import { fetchMustReadMessages } from '../../../api/mailbox.api'
import { Scrollbars } from 'react-custom-scrollbars'
import { mailBox } from '../../../constants/constants'
import { LbLoader } from '../../../common/SimpleComponents/LBLoader'

class NavBarNotificalitonList extends Component {
  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.handlechange = this.handlechange.bind(this)
    this.handleScrollUpdate = this.handleScrollUpdate.bind(this)
    this.handelDownloadFile = this.handelDownloadFile.bind(this)
    this.state = {
      checked: false,
      message: null,
      mustReadModalIsOpen: false,
      isDonloading: {}
    }
  }

  handleClose() {
    let mustReadMessage = this.state.message
    if (mustReadMessage && this.state.checked) {
      this.props.MarkedAsRead(mustReadMessage.id).then(() => {
        this.setState(
          prevState => ({ ...prevState, mustReadModalIsOpen: false }),
          () => {
            if (
              this.props.history &&
              this.props.history.location.pathname != '/notifications'
            ) {
              this.props.getLastUnreadInboxMessages()
            }
          }
        )
      })

      this.requiredToReadModal.handleCloseModal()
    }
  }
  handlechange(e) {
    this.setState(ps => ({ checked: !ps.checked }))
  }

  renderThumbVertical = () => {
    return <div className="thumb-vertical" />
  }

  renderTrackVertical = () => {
    return <div className="track-vertical" />
  }

  handleScrollUpdate(event) {
    const { scrollHeight, scrollTop, clientHeight } = event
    if (scrollHeight - clientHeight - 100 < scrollTop) {
      this.setState(prev => ({
        ...prev,
        checked: true
      }))
    }
  }

  componentDidMount() {
    // open modal if message is required to read
    if (
      this.props.history &&
      (this.props.history.location.pathname == '/notifications' ||
        this.props.history.location.pathname == '/')
    ) {
      fetchMustReadMessages()
        .then(message => {
          if (message) {
            this.setState({ message, mustReadModalIsOpen: true })
          } else {
            this.props.getLastUnreadInboxMessages()
          }
        })
        .catch(() => {
          this.setState({ mustReadModalIsOpen: false })
        })
    } else {
      this.props.getLastUnreadInboxMessages()
    }
  }

  handelDownloadFile(notificationId, attachmentId, type, fileName) {
    this.setState(prevState => ({
      ...prevState,
      isDonloading: {
        ...prevState.isDonloading,
        [attachmentId]: true
      }
    }))
    this.props
      .downloadFile(notificationId, attachmentId, type, fileName)
      .then(() => {
        this.setState(prevState => ({
          ...prevState,
          isDonloading: {
            ...prevState.isDonloading,
            [attachmentId]: false
          }
        }))
      })
      .catch(() => {
        this.setState(prevState => ({
          ...prevState,
          isDonloading: {
            ...prevState.isDonloading,
            [attachmentId]: false
          }
        }))
      })
  }

  render() {
    let { messages, onChooseMessage, downloadFile } = this.props
    const { message } = this.state

    return (
      <>
        <div className="lb-sub-navbar">
          {messages && messages.length
            ? messages.map(msg => (
                <NavBarNotificationItem
                  key={msg.id}
                  {...msg}
                  onChooseMessage={onChooseMessage}
                />
              ))
            : null}
        </div>
        {message && (
          <LbModal
            ref={modal => {
              this.requiredToReadModal = modal
            }}
            isOpen={
              this.state.mustReadModalIsOpen &&
              this.props.history &&
              (this.props.history.location.pathname == '/notifications' ||
                this.props.history.location.pathname == '/')
            }
            shouldCloseOnOverlayClick={false}
            className="mw-750 important-message"
            //header={mustReadMessage.subject}
            header={i18n.t('Common.ImportantMessage')}
            customCloseEvent={() => this.handleClose()}
            hideCloseTopButton={true}
            shouldCloseOnEsc={false}
          >
            <div className="lb-confirm-text">
              <div className="notification-date-block pb-10 mt-0">
                <div className="notification-date pb-10">{message.subject}</div>
                <div className="fnt-15 fnt-low-m pb-15">
                  {moment.unix(message.date).format('DD/MM/YYYY')}
                </div>
                <div className="bb" />
              </div>
              <Scrollbars
                autoHeight
                autoHeightMax={200}
                renderThumbVertical={this.renderThumbVertical}
                renderTrackVertical={this.renderTrackVertical}
                onUpdate={this.handleScrollUpdate}
              >
                <div
                  className="lb-content-message"
                  style={{ paddingLeft: '0' }}
                >
                  <div
                    className="fnt-15 fnt-low ovf-wrap pr-20 "
                    dangerouslySetInnerHTML={{ __html: message.body }}
                  />
                </div>
              </Scrollbars>
              <div className="bb pt-10"></div>

              {message.attachments && message.attachments.length ? (
                <div className="attachments-file">
                  {message.attachments.map(attach => (
                    <div
                      className="downloadfile"
                      onClick={() =>
                        this.handelDownloadFile(
                          message.id,
                          attach.id,
                          attach.type.toLowerCase(),
                          attach.name
                        )
                      }
                    >
                      {attach.name && attach.name.length > 20
                        ? `${attach.name.substr(0, 53)}...${attach.name.substr(
                            attach.name.lastIndexOf('.') + 1
                          )}`
                        : attach.name}
                      {attach.id in this.state.isDonloading &&
                      this.state.isDonloading[attach.id] ? (
                        <LbLoader isLoading size="xxs" />
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="lb-popup-footer small-btn-group flex-end pt-0">
              <button
                onClick={() => this.handleClose()}
                className="lb-btn btn-border btn-white "
                disabled={!this.state.checked}
              >
                <Trans i18nKey="Common.Understandably" />
              </button>
            </div>
          </LbModal>
        )}
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  MarkedAsRead: id => dispatch(MarkedAsRead(id)),
  downloadFile: (notificationId, id, format, fileName) =>
    dispatch(donwloadFile(notificationId, id, format, fileName))
})

export default withRouter(
  connect(null, mapDispatchToProps)(NavBarNotificalitonList)
)
