import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

export function fetchAvailableMarketingConsents() {
  return jsonRequest(
    `${baseUrl}/configuration/v2/push-notifications/advertisement-status`,
    true
  )
}
export function updateAvailableMarketingConsents(body) {
  return jsonRequest(
    `${baseUrl}/configuration/v2/push-notifications/change-advertisements`,
    true,
    body,
    // {
    //   typeAnswers
    //   // approveAll: true
    // },
    'PUT'
  )
}
