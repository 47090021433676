import React, { useMemo } from 'react'
import Icon from '../SimpleComponents/Icon'

const LBCheckbox = props =>
  useMemo(() => {
    let newProps = {}
    Object.keys(props)
      .filter(key => key !== 'label')
      .filter(key => key !== 'wrapClassName')
      .filter(key => key !== 'onLabelClick')
      .forEach(key => {
        newProps[key] = props[key]
      })

    const checkProps = element => {
      return element ? element : ''
    }

    return (
      <label
        id={`label-${props.id}`}
        className={`checkbox-wrapper ${checkProps(
          props.wrapClassName
        )} ${checkProps(props.IconClass)}`}
        htmlFor={checkProps(props.id)}
        data-tip={props.dataTip}
        data-for={props.dataFor}
        onClick={props.onLabelClick}
      >
        {props.label ? <span className="nowrap">{props.label}</span> : ''}
        <input
          id={`input-${props.id}`}
          type="checkbox"
          className="checkbox"
          {...newProps}
          checked={props.checked}
        />
        <span className="checkmark">
          {checkProps(props.IconClass) ? (
            <Icon name="icn-minus" viewBox="0 0 14.46.21" />
          ) : (
            <Icon name="icn-tick" view="0 0 13 9.6" />
          )}
        </span>
      </label>
    )
  }, [props])

export default LBCheckbox
