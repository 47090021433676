import { parkingConstants } from '../../constants/constants'

const initialState = {
  carItems: [],
  carItemsLoading: false,
  carItemsError: false,
  carsRequestId: undefined,

  providerItems: undefined,
  providerItemsLoading: false,
  providerItemsError: false,
  providersRequestId: undefined,

  fineRequestIds: [],
  passRequestIds: [],

  saveCarLoading: false,
  saveCarError: null,

  deleteCarLoadingId: false,
  deleteCarError: false,

  confirmPaymentLoading: false,

  chosenFines: [],
  //----- ჯარიმის ერთჯერადი გადახდა-------
  fineServiceProviders: [],
  fineServiceProvidersLoading: false,
  fineServiceProvidersError: false,

  oneTimePayFineInfo: undefined,
  oneTimePayCheckFineLoading: false,
  oneTimePayCheckFineError: null,
  //---------- საშვის ყიდვა ----------
  passServices: undefined,
  passServicesLoading: false,
  passServicesError: null
}

export default function parkingReducer(state = initialState, action) {
  // window.state = state
  switch (action.type) {
    //---------------------------- FETCH CARS ----------------------------------
    case parkingConstants.FETCH_CARS:
      return {
        ...state,
        carItemsLoading: true,
        carItems: [],
        carsRequestId: action.requestId
      }

    case parkingConstants.FETCH_CARS_SUCCESS: {
      let carItems = []
      if (action.requestId === state.carsRequestId) {
        carItems = action.payload
        if (state.providerItems && carItems) {
          carItems.forEach(e => {
            e.leftFinesToFetch = state.providerItems.penalties.length
            e.leftPassesToFetch = state.providerItems.services.length
          })
        }
      }
      return {
        ...state,
        carItemsLoading: false,
        carItemsError: false,
        carItems,
        fineRequestIds: [],
        passRequestIds: []
      }
    }
    case parkingConstants.FETCH_CARS_ERROR:
      return {
        ...state,
        carItems: [],
        carItemsError: true,
        carItemsLoading: false
      }
    //------------------------------- FETCH PROVIDERS -------------------------------
    case parkingConstants.FETCH_CAR_PROVIDERS:
      return {
        ...state,
        providerItemsLoading: true,
        providersRequestId: action.requestId
      }

    case parkingConstants.FETCH_CAR_PROVIDERS_SUCCESS: {
      let newCarItems
      if (state.carItems && state.carItems.length) {
        newCarItems = [...state.carItems]
        if (action.requestId === state.providersRequestId) {
          newCarItems.forEach(e => {
            e.leftFinesToFetch = action.payload.penalties.length
            e.leftPassesToFetch = action.payload.services.length
          })
        }
      }

      return {
        ...state,
        providerItems:
          action.requestId === state.providersRequestId
            ? action.payload
            : state.providerItems,
        providerItemsError: false,
        providerItemsLoading: false,
        carItems: newCarItems ? newCarItems : [],
        fineRequestIds: [],
        passRequestIds: []
      }
    }

    case parkingConstants.FETCH_CAR_PROVIDERS_ERROR:
      return {
        ...state,
        providerItemsError: true,
        providerItemsLoading: false,
        providerItems: []
      }
    //------------------------------- FETCH CAR FINE -------------------------------
    case parkingConstants.FETCH_CAR_FINE: {
      return {
        ...state,
        fineRequestIds: [...state.fineRequestIds, action.payload.requestId]
      }
    }
    case parkingConstants.FETCH_CAR_FINE_SUCCESS: {
      let newCarItems = [...state.carItems]
      if (state.fineRequestIds.includes(action.payload.requestId)) {
        let car = newCarItems.find(c => c.id == action.payload.carId)
        if (car) {
          let carIndex = newCarItems.indexOf(car)
          let newCar = { ...car }
          if (action.payload.fines) {
            action.payload.fines.forEach(fine => {
              newCar.fines = newCar.fines
                ? [...newCar.fines, fine].sort(
                    (a, b) => Number(b.fineDate) - Number(a.fineDate)
                  )
                : [fine]
            })
          }
          newCar.leftFinesToFetch = newCar.leftFinesToFetch - 1
          if (
            newCar.leftFinesToFetch === 0 &&
            newCar.atLeastOneFineFetchError
          ) {
            newCar.showAtLeastOneFineFetchError = true
          }
          newCarItems[carIndex] = newCar
        }
      }
      return { ...state, carItems: newCarItems }
    }

    case parkingConstants.FETCH_CAR_FINE_ERROR: {
      let newCarItems = [...state.carItems]
      if (state.fineRequestIds.includes(action.payload.requestId)) {
        let car = newCarItems.find(c => c.id == action.payload.carId)
        if (car) {
          let carIndex = newCarItems.indexOf(car)
          let newCar = { ...car }
          newCar.leftFinesToFetch = newCar.leftFinesToFetch - 1
          if (
            action.payload.error &&
            action.payload.error.code === 'BillingBalanceNotAvaiable'
          ) {
            newCar.atLeastOneFineFetchError = true
          }
          if (
            newCar.leftFinesToFetch === 0 &&
            newCar.atLeastOneFineFetchError
          ) {
            newCar.showAtLeastOneFineFetchError = true
          }
          newCarItems[carIndex] = newCar
        }
      }
      return { ...state, carItems: newCarItems }
    }

    //------------------------------- FETCH CAR PASS -------------------------------
    case parkingConstants.FETCH_CAR_PASS:
      return {
        ...state,
        passRequestIds: [...state.passRequestIds, action.payload.requestId]
      }

    case parkingConstants.FETCH_CAR_PASS_SUCCESS: {
      let newCarItems = [...state.carItems]
      if (state.passRequestIds.includes(action.payload.requestId)) {
        let car = newCarItems.find(c => c.id == action.payload.carId)
        if (car) {
          let carIndex = newCarItems.indexOf(car)
          let newCar = { ...car }
          if (action.payload.passes) {
            action.payload.passes.forEach(pass => {
              newCar.passes = newCar.passes ? [...newCar.passes, pass] : [pass]
            })
          }
          newCar.leftPassesToFetch = newCar.leftPassesToFetch - 1
          newCarItems[carIndex] = newCar
        }
      }
      return { ...state, carItems: newCarItems }
    }
    case parkingConstants.FETCH_CAR_PASS_ERROR: {
      let newCarItems = [...state.carItems]
      if (state.passRequestIds.includes(action.payload.requestId)) {
        let car = newCarItems.find(c => c.id == action.payload.carId)
        if (car) {
          let carIndex = newCarItems.indexOf(car)
          let newCar = { ...car }
          newCar.leftPassesToFetch = newCar.leftPassesToFetch - 1
          if (
            action.payload.error &&
            action.payload.error.code === 'BillingBalanceNotAvaiable'
          ) {
            newCar.atLeastOnePassFetchError = true
          }
          newCarItems[carIndex] = newCar
        }
      }
      return { ...state, carItems: newCarItems }
    }
    //------------------------------- SAVE CAR --------------------------------------
    case parkingConstants.SAVE_CAR:
      return { ...state, saveCarLoading: true, saveCarError: null }
    case parkingConstants.SAVE_CAR_SUCCESS:
      return { ...state, saveCarLoading: false, saveCarError: null }
    case parkingConstants.SAVE_CAR_ERROR:
      return {
        ...state,
        saveCarLoading: false,
        saveCarError: action.payload.errors[0]
      }
    //-------------------------------------- DELETE CAR --------------------------------
    case parkingConstants.DELETE_CAR:
      return {
        ...state,
        deleteCarLoadingId: action.carId,
        deleteCarError: false
      }
    case parkingConstants.DELETE_CAR_SUCCESS:
      let carItemsCopy = [...state.carItems]
      let newCarItems = carItemsCopy.filter(car => car.id !== action.carId)
      return {
        ...state,
        carItems: newCarItems,
        deleteCarLoadingId: null,
        deleteCarError: false
      }
    case parkingConstants.DELETE_CAR_ERROR:
      return { ...state, deleteCarLoadingId: null, deleteCarError: true }
    //-------------------------------- DISDPLAY SETTINGS ----------------------------
    case parkingConstants.DISPLAY_SETTINGS: {
      let newCarItems = state.carItems.map(car => {
        let toggleDisplay =
          car.displaySettings !== undefined ? !car.displaySettings : true
        return car === action.payload
          ? { ...car, displaySettings: toggleDisplay }
          : car.displaySettings === true
          ? { ...car, displaySettings: false }
          : car
      })
      return { ...state, carItems: newCarItems }
    }

    case parkingConstants.DISPLAY_SETTINGS_OFF: {
      if (state.carItems.some(car => car.displaySettings === true)) {
        let newCarItems = state.carItems.map(car => {
          return car.displaySettings === true
            ? { ...car, displaySettings: false }
            : car
        })
        return { ...state, carItems: newCarItems }
      } else {
        return state
      }
    }

    case parkingConstants.TOGGLE_CHOOSE_FINE: {
      let toggleChoose =
        action.fine.isChosen !== undefined ? !action.fine.isChosen : true

      let chosenFines = []
      let newCarItems = [...state.carItems]

      let car = newCarItems.find(car => car.id === action.carId)
      let newCar = { ...car }
      let carIndex = newCarItems.indexOf(car)

      let newFines = [...newCar.fines]
      let fine = newFines.find(fine => fine === action.fine)
      let newFine = { ...fine, isChosen: toggleChoose }
      let fineIndex = newCar.fines.indexOf(fine)

      newFines[fineIndex] = newFine
      newCar.fines = newFines
      newCarItems[carIndex] = newCar

      newCarItems.forEach(car => {
        if (car.fines) {
          car.fines.forEach(fine => {
            if (fine.isChosen) chosenFines.push(fine)
          })
        }
      })

      return { ...state, carItems: newCarItems, chosenFines }
    }
    //------------------------------------------------- CONFIRM PAYMENT -----------------------------------------

    case parkingConstants.CONFIRM_PAYMENT:
      return { ...state, confirmPaymentLoading: true }
    case parkingConstants.CONFIRM_PAYMENT_STOP_LOADING:
      return { ...state, confirmPaymentLoading: false }

    // -------------------------------- CLEANING STATES -----------------------------------------------------------

    case parkingConstants.CLEAN_CARS_AND_FINES_STATE:
      return { ...state, chosenFines: [] }
    //------------------------------------ ONE TIME PAY PROVIDERS -----------------------------------
    case parkingConstants.FETCH_FINE_SERVICE_PROVIDERS:
      return {
        ...state,
        fineServiceProvidersLoading: true,
        fineServiceProvidersError: false,
        fineServiceProviders: []
      }
    case parkingConstants.FETCH_FINE_SERVICE_PROVIDERS_SUCCESS:
      return {
        ...state,
        fineServiceProvidersLoading: false,
        fineServiceProvidersError: false,
        fineServiceProviders: action.payload
      }
    case parkingConstants.FETCH_FINE_SERVICE_PROVIDERS_ERROR:
      return {
        ...state,
        fineServiceProvidersLoading: false,
        fineServiceProvidersError: true,
        fineServiceProviders: []
      }
    //----------------------- ONE TIME PAY  --------------------------
    case parkingConstants.CHECK_ONE_TIME_PAY_FINE:
      return {
        ...state,
        oneTimePayCheckFineLoading: true,
        oneTimePayCheckFineError: null,
        oneTimePayFineInfo: undefined
      }
    case parkingConstants.CHECK_ONE_TIME_PAY_FINE_SUCCESS:
      return {
        ...state,
        oneTimePayCheckFineLoading: false,
        oneTimePayCheckFineError: null,
        oneTimePayFineInfo: action.payload
      }
    case parkingConstants.CHECK_ONE_TIME_PAY_FINE_ERROR:
      return {
        ...state,
        oneTimePayCheckFineLoading: false,
        oneTimePayCheckFineError: action.payload.errors[0],
        oneTimePayFineInfo: undefined
      }
    //----------------------- BUY PASS ---------------------
    case parkingConstants.FETCH_PASS_SERVICES:
      return {
        ...state,
        passServices: undefined,
        passServicesLoading: true,
        passServicesError: null
      }
    case parkingConstants.FETCH_PASS_SERVICES_SUCCESS:
      return {
        ...state,
        passServices: action.payload,
        passServicesLoading: false,
        passServicesError: null
      }
    case parkingConstants.FETCH_PASS_SERVICES_ERROR:
      return {
        ...state,
        passServices: undefined,
        passServicesLoading: false,
        passServicesError: action.payload.errors[0]
      }
    //------------------ CLEANING STATE --------------------
    case parkingConstants.CLEAR_ONE_TIME_PAY_STATE:
      return {
        ...state,
        fineServiceProviders: [],
        fineServiceProvidersLoading: false,
        fineServiceProvidersError: false,
        oneTimePayFineInfo: undefined,
        oneTimePayCheckFineLoading: false,
        oneTimePayCheckFineError: null
      }
    case parkingConstants.CLEAR_BUY_PASS_STATE:
      return {
        ...state,
        passServices: undefined,
        passServicesLoading: false,
        passServicesError: null
      }
    case parkingConstants.CLEAR_PARKING_FINES_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}
