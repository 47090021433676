import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

export function fetchIBBuildInfo() {
  return jsonRequest('/buildinfo.json', true, undefined, 'GET', {
    badRequest: false,
    internalServerError: false
  })
}

export function fetchAPIBuildInfo() {
  return jsonRequest(
    `${baseUrl}/account/buildinfo.json`,
    true,
    undefined,
    'GET',
    { badRequest: false, internalServerError: false }
  )
}
