import React from 'react'
import Icon from './Icon'

const errorClass = {
  widget: 'error-icon',
  small: 'error-sm',
  large: 'error-lg',
  medium: 'error-md',
  currency: 'error-currency',
  account: 'error-accoount',
  selecterror: 'error-select',
  DecimalInputerror: 'error-DecimalInput',
  QuickPayment: 'error-quick',
  modal: 'error-modal'
}

const LbErrorMessage = ({ message, type }) => {
  return (
    <div className="error-box">
      <div className={errorClass[type]}>
        <i className="icn icn-warning mr-10">
          <Icon name="icn-warning-new2" view="0 0 26.864 23.74" />
        </i>
        <span className="flex-1"> {message}</span>
      </div>
    </div>
  )
}

export default LbErrorMessage
