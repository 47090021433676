import { administrationConstants } from '../../constants/constants'
import { nullToArray, updateObject } from '../../helpers/util'

const initialState = {
  users: [],
  usersLoading: false,
  usersLoadingError: null,

  userActions: [],
  userActionsLoading: false,
  userActionsLoadingError: null,

  userRoles: [],
  userRolesLoading: false,
  userRolesLoadingError: null,

  selectedRole: null,
  selectedRoleActions: [],
  selectedRoleActionsLoading: false,
  selectedRoleActionsLoadingError: null
}

export default function administrationReducer(state = initialState, action) {
  switch (action.type) {
    case administrationConstants.FETCH_USERS:
      return updateObject(state, {
        usersLoading: true,
        usersLoadingError: null
      })
    case administrationConstants.FETCH_USERS_SUCCESS:
      return updateObject(state, {
        users: nullToArray(action.payload),
        usersLoading: false,
        usersLoadingError: null
      })
    case administrationConstants.FETCH_USERS_ERROR:
      return updateObject(state, {
        usersLoading: false,
        usersLoadingError: action.error
      })

    case administrationConstants.FETCH_USER_ACTIONS:
      return updateObject(state, {
        userActionsLoading: true,
        userActionsLoadingError: null
      })
    case administrationConstants.FETCH_USER_ACTIONS_SUCCESS:
      return updateObject(state, {
        userActions: nullToArray(action.payload),
        userActionsLoading: false,
        userActionsLoadingError: null
      })
    case administrationConstants.FETCH_USER_ACTIONS_ERROR:
      return updateObject(state, {
        userActionsLoading: false,
        userActionsLoadingError: action.error
      })

    case administrationConstants.FETCH_USER_ROLES:
      return updateObject(state, {
        selectedRole: null,
        userRolesLoading: true,
        userRolesLoadingError: null
      })
    case administrationConstants.FETCH_USER_ROLES_SUCCESS:
      return updateObject(state, {
        userRoles: nullToArray(action.payload),
        userRolesLoading: false,
        userRolesLoadingError: null
      })
    case administrationConstants.FETCH_USER_ROLES_ERROR:
      return updateObject(state, {
        userRolesLoading: false,
        userRolesLoadingError: action.error
      })

    case administrationConstants.SELECT_USER_ROLE:
      return updateObject(state, {
        selectedRole: action.payload,
        userRoles: state.userRoles.filter(f => f.id !== -1)
      })

    case administrationConstants.FETCH_USER_ROLE_ACTIONS:
      return updateObject(state, {
        selectedRoleActionsLoading: true,
        selectedRoleActionsLoadingError: null
      })
    case administrationConstants.FETCH_USER_ROLE_ACTIONS_SUCCESS:
      return updateObject(state, {
        selectedRoleActions: nullToArray(action.payload),
        selectedRoleActionsLoading: false,
        selectedRoleActionsLoadingError: null
      })
    case administrationConstants.FETCH_USER_ROLE_ACTIONS_ERROR:
      return updateObject(state, {
        selectedRoleActionsLoading: false,
        selectedRoleActionsLoadingError: action.error
      })
    case administrationConstants.ADD_USER_ROLE:
      // eslint-disable-next-line no-case-declarations
      let newRole = { name: '', id: -1, default: false, createdByCurrent: true }
      return updateObject(state, {
        userRoles: [...state.userRoles, newRole],
        selectedRole: newRole,
        selectedRoleActions: []
      })
    case administrationConstants.UPDATE_USER_ROLE:
      return updateObject(state, {
        userRoles: state.userRoles.map(r =>
          r.id === action.payload.oldId ? { ...action.payload.role } : { ...r }
        ),
        selectedRole: action.payload.role,
        selectedRoleActions: action.payload.roleActions
      })
    case administrationConstants.CANCEL_ADD_USER_ROLE:
      // eslint-disable-next-line no-case-declarations
      return updateObject(state, {
        userRoles: state.userRoles.slice(0, state.userRoles.length - 1),
        selectedRole: null,
        selectedRoleActions: []
      })
    case administrationConstants.DELETE_USER_ROLE:
      // eslint-disable-next-line no-case-declarations
      const newArray = [...state.userRoles]
      // eslint-disable-next-line no-case-declarations
      const index = newArray.findIndex(i => i.id === action.payload)
      newArray.splice(index, 1)
      return updateObject(state, {
        userRoles: newArray,
        selectedRole: null,
        selectedRoleActions: []
      })
    default:
      return state
  }
}
