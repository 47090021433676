/**
 *
 * check for authy type
 * there are two types right now  Authy and Verify
 *
 * @param {*} type String
 * @returns boolean
 * HIGH_LEVEL
 */
export function isAuthyType(type) {
  return ['Authy', 'Verify'].includes(type)
}
