import { jsonRequest } from '../helpers/apiRequest'
import { authorizationUrl, baseUrl } from '../helpers/baseUrl'

function fetchUserSettingsAjax() {
  return jsonRequest(
    `${baseUrl}/configuration/v1/user-settings`,
    true,
    null,
    'GET',
    {
      internalServerError: false,
      badRequest: false
    }
  )
}

function saveUserSettingsAjax(settings, global = false) {
  return jsonRequest(
    `${baseUrl}/configuration/v1/user-settings?global=${global}`,
    true,
    settings,
    'POST'
  )
}

export function toggleTwoFactor() {
  return jsonRequest(
    `${baseUrl}/identity/v1/manage/two-factor`,
    true,
    undefined,
    'PUT'
  )
}

export function resendSmsTwoFactor(id) {
  return jsonRequest(
    `${baseUrl}/identity/v1/operation/send`,
    true,
    { id },
    'POST'
  )
}

export function approveTwoFactor(id, code) {
  return jsonRequest(
    `${baseUrl}/identity/v1/manage/approve`,
    true,
    {
      code,
      id
    },
    'POST',
    { internalServerError: false, badRequest: false }
  )
}

export function fetchUserInfo() {
  return jsonRequest(`${authorizationUrl}/connect/userinfo`, true)
  // return jsonRequest(`${baseUrl}/identity/v1/userinfo`, true)
}

export function fetchCorporateUserSettings() {
  return jsonRequest(`${baseUrl}/corporate/v1/organization/users/current`, true)
}

export function fetchSecurityLevels() {
  return jsonRequest(
    `${baseUrl}/configuration/v1/security-levels`,
    true,
    null,
    'GET',
    {
      internalServerError: false,
      badRequest: false
    }
  )
}

export function fetchCurrentSecurityLevel() {
  return jsonRequest(
    `${baseUrl}/configuration/v1/security-levels/current`,
    true,
    null,
    'GET',
    {
      internalServerError: false,
      badRequest: false
    }
  )
}

export function initiateSecurityLevelChange(securityLevelId) {
  return jsonRequest(
    `${baseUrl}/configuration/v1/security-levels/change/initiate`,
    true,
    { securityLevelId },
    'POST',
    {
      internalServerError: false,
      badRequest: false
    }
  )
}

export function confirmSecurityLevelChange(
  operationId,
  otpCode,
  authyCode,
  validationId
) {
  return jsonRequest(
    `${baseUrl}/configuration/v1/security-levels/change/confirm`,
    true,
    { operationId, otpCode, authyCode, validationId },
    'PUT',
    {
      internalServerError: false,
      badRequest: false
    }
  )
}

export function challengeSecurityLevelChange(operationId, twoFactorType) {
  return jsonRequest(
    `${baseUrl}/configuration/v1/security-levels/change/challenge`,
    true,
    { operationId, twoFactorType },
    'POST',
    {
      internalServerError: false,
      badRequest: false
    }
  )
}

export const checkCreditInfoStatus = () => {
  return jsonRequest(
    `${baseUrl}/configuration/v1/credit-info`,
    true,
    null,
    'GET'
  )
}

export const getCreditInfoAgreement = () => {
  return jsonRequest(
    `${baseUrl}/configuration/v1/credit-info/agreement/get`,
    true,
    null,
    'GET'
  )
}

export const getCreditInfoSessionId = () => {
  return jsonRequest(
    `${baseUrl}/configuration/v1/credit-info/twofactor/send`,
    true,
    null,
    'POST'
  )
}
export const resendCreditInfoSms = sessionId => {
  return jsonRequest(
    `${baseUrl}/configuration/v1/credit-info/twofactor/resend`,
    true,
    { sessionId },
    'POST'
  )
}

export const activateCreditInfo = (sessionId, validationId, twoFactorCode) => {
  return jsonRequest(
    `${baseUrl}/configuration/v1/credit-info/activate`,
    true,
    { sessionId, twoFactorCode, validationId },
    'PUT'
  )
}
export const deactivateCreditInfo = () => {
  return jsonRequest(
    `${baseUrl}/configuration/v1/credit-info/deactivate`,
    true,
    null,
    'PUT'
  )
}

export const checkPensionSavingsStatus = () => {
  return jsonRequest(`${baseUrl}/configuration/v1/pensions/status`, true)
}
export const getPensionSavingsAmouont = () => {
  return jsonRequest(`${baseUrl}/configuration/v1/pensions/amount`, true)
}
export const togglePensionSavings = () => {
  return jsonRequest(
    `${baseUrl}/configuration/v1/pensions/revoke`,
    true,
    null,
    'POST'
  )
}
export const getPensionSavingsMembership = () => {
  return jsonRequest(`${baseUrl}/configuration/v1/pensions/is-member`, true)
}
export const getPensionSavingsAgreement = () => {
  return jsonRequest(
    `${baseUrl}/configuration/v1/pensions/agreement/get`,
    true,
    null,
    'GET'
  ).then(res => res.blob())
}

export default {
  fetchUserSettingsAjax,
  saveUserSettingsAjax,
  fetchUserInfo,
  fetchCorporateUserSettings,
  fetchSecurityLevels,
  fetchCurrentSecurityLevel,
  initiateSecurityLevelChange,
  confirmSecurityLevelChange,
  challengeSecurityLevelChange
}
