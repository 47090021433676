import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

function logApplicationError(data, isPrivate = true) {
  return jsonRequest(
    `${baseUrl}/logger/v1/logger?data=${data}`,
    isPrivate,
    undefined,
    'POST'
  )
}

export function logGeneralError(data, isPrivate = true) {
  return jsonRequest(
    `${baseUrl}/logger/v1/logger/send-error?data=${data}`,
    isPrivate,
    undefined,
    'POST'
  )
}

export default logApplicationError
