import {
  jsonRequest,
  uploadFileRequest,
  downloadFileRequest
} from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

export function fetchMessageTypes() {
  return jsonRequest(`${baseUrl}/notifications/v1/types`, true)
}

export function fetchInboxMessagesCount() {
  return jsonRequest(`${baseUrl}/notifications/v1/Inbox/count`, true)
}
export function fetchInboxMessages({ skip, take }) {
  return jsonRequest(
    `${baseUrl}/notifications/v1/Inbox?skip=${skip}&take=${take}`,
    true
  )
}
export function fetchSentMessagesCount() {
  return jsonRequest(`${baseUrl}/notifications/v1/Outbox/count`, true)
}

export async function fetchSentMessages({ skip, take }) {
  return jsonRequest(
    `${baseUrl}/notifications/v1/Outbox?skip=${skip}&take=${take}`,
    true
  )
}

export function featchSearchedMessage({ text, url, skip, take }) {
  return jsonRequest(
    `${baseUrl}/notifications/v1/${url}?query=${text}&skip=${skip}&take=${take}`,
    true
  )
}

export function fetchSingleMessage({ id, url, skip, take }) {
  return jsonRequest(
    `${baseUrl}/notifications/v1/${url}/${id}/thread?skip=${skip}&take=${take}`,
    true
  )
}

export function fetchMarkedAsRead(id) {
  return jsonRequest(
    `${baseUrl}/notifications/v1/Inbox/markasread`,
    true,
    { notificationIds: [id] },
    'PUT'
  )
}

export function senNewMessage({ typeId, subject, body, attachments }) {
  return jsonRequest(
    `${baseUrl}/notifications/v1/Outbox/new`,
    true,
    { typeId, subject, body, attachments },
    'POST',
    {
      internalServerError: false,
      badRequest: false
    }
  )
}

export function replayInboxMesage({ parentId, body, subject, attachments }) {
  return jsonRequest(
    `${baseUrl}/notifications/v1/Inbox/replay`,
    true,
    { parentId, body, subject, attachments },
    'POST',
    {
      internalServerError: false,
      badRequest: false
    }
  )
}

export function deleteMessages({ ids, url }) {
  return jsonRequest(
    `${baseUrl}/notifications/v1/${url}/delete`,
    true,
    { notificationIds: ids },
    'DELETE'
  )
}

export function fetchMustReadMessages() {
  return jsonRequest(`${baseUrl}/notifications/v1/Inbox/mustread`, true)
}

export function uploadFileAjax(File) {
  return uploadFileRequest(
    `${baseUrl}/notifications/v1/Attachments/file`,
    true,
    File,
    'POST',
    'File'
  )
}

export function downloadFileAjax(notificationId, id, format, fileName = '') {
  return downloadFileRequest(
    `${baseUrl}/notifications/v1/Attachments/for/${notificationId}/${id}`,
    `${fileName ? fileName : notificationId}`,
    format,
    true
  )
}

export function fetchLastUnreadInboxMessages() {
  return jsonRequest(
    `${baseUrl}/notifications/v1/Inbox?unread=true&take=3`,
    true
  )
}

export function fetchMustReadMessage() {
  return jsonRequest(`${baseUrl}/notifications/v1/Inbox/mustread`, true)
}
