import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

function fetchTaxBeneficiary() {
  return jsonRequest(`${baseUrl}/transfer/v2/in-budget/tax-beneficiary`, true)
}

function fetchRegions(taxBeneficiaryId) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/in-budget/regions?taxBeneficiaryId=${taxBeneficiaryId}`,
    true
  )
}

function fetchTaxType(regionId, taxBeneficiaryId) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/in-budget/tax-type?regionId=${regionId}&taxBeneficiaryId=${taxBeneficiaryId}`,
    true
  )
}

function fetchValidateCode(code) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/in-budget/validate-code?code=${code}`,
    true
  )
}

function fetchTransferTemplatesAjax() {
  return jsonRequest(`${baseUrl}/transfer/v2/templates`, true)
}

function fetchPiTransferTemplatesAjax() {
  return jsonRequest(
    `${baseUrl}/transfer/v2/to-someone-by-client-info/template`,
    true
  )
}

function addPiTransferTemplateAjax(params, showError = true) {
  params.name = params.name.trim()
  return jsonRequest(
    `${baseUrl}/transfer/v2/to-someone-by-client-info/template`,
    true,
    params,
    'POST',
    {
      badRequest: showError,
      internalServerError: showError
    }
  )
}
function addTransferTemplateAjax(params, showError = true) {
  params.name = params.name.trim()
  return jsonRequest(`${baseUrl}/transfer/v2/templates`, true, params, 'POST', {
    badRequest: showError,
    internalServerError: showError
  })
}

function editPiTransferTemplateAjax(params, showError = true) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/to-someone-by-client-info/template`,
    true,
    params,
    'PUT',
    {
      badRequest: showError,
      internalServerError: showError
    }
  )
}
function editTransferTemplateAjax(params, showError = false) {
  return jsonRequest(`${baseUrl}/transfer/v2/templates`, true, params, 'PUT', {
    badRequest: showError,
    internalServerError: showError
  })
}

function deletePiTransferTemplateAjax(id) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/to-someone-by-client-info/template?id=${id}`,
    true,
    undefined,
    'DELETE'
  )
}
function deleteTransferTemplateAjax(id) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/templates?id=${id}`,
    true,
    undefined,
    'DELETE'
  )
}

function addMobileTemplateAjax(template, showError = false) {
  return jsonRequest(
    `${baseUrl}/billing/v1/mobile/templates`,
    true,
    template,
    'POST',
    {
      badRequest: showError,
      internalServerError: showError
    }
  )
}

function editMobileTemplateAjax(template, showError = false) {
  return jsonRequest(
    `${baseUrl}/billing/v1/mobile/templates`,
    true,
    template,
    'PUT',
    { badRequest: showError, internalServerError: showError }
  )
}

function deleteMobileTemplateAjax(id) {
  return jsonRequest(
    `${baseUrl}/billing/v1/mobile/templates/${id}`,
    true,
    undefined,
    'DELETE'
  )
}

export function fetchMobileTemplatesAjax() {
  return jsonRequest(`${baseUrl}/billing/v1/mobile/templates`, true)
}

export function checkUserAvatarAjax(phoneNumber) {
  return jsonRequest(
    `${baseUrl}/billing/v1/mobile/templates/${phoneNumber}/avatar`,
    true
  )
}
function fetchRemittanceTemplatesAjax() {
  return jsonRequest(`${baseUrl}/remittance/v1/templates`, true)
}

function addRemittanceTemplateAjax(params, showError = false) {
  return jsonRequest(
    `${baseUrl}/remittance/v1/templates`,
    true,
    params,
    'POST',
    {
      badRequest: showError,
      internalServerError: showError
    }
  )
}

function editRemittanceTemplateAjax(params, showError = false) {
  return jsonRequest(
    `${baseUrl}/remittance/v1/templates`,
    true,
    params,
    'PUT',
    {
      badRequest: showError,
      internalServerError: showError
    }
  )
}

function deleteRemittanceTemplateAjax(id) {
  return jsonRequest(
    `${baseUrl}/remittance/v1/templates?templateIds=${id}`,
    true,
    undefined,
    'DELETE'
  )
}

export function fetchP2pTemplatesAjax() {
  return jsonRequest(`${baseUrl}/transfer/v2/p2p/tempalte`, true)
}

export function fetchP2pSaveCardTemplateAjax({
  temporaryCardId,
  name,
  imageId,
  description,
  receiver
}) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/p2p/tempalte`,
    true,
    { temporaryCardId, name, imageId, description, receiver },
    'POST'
  )
}

export function fetchP2pUpdateCardTemplateAjax(
  { id, name, imageId, receiver },
  showError = false
) {
  let params = { id, name, receiver }
  if (imageId) params.imageId = imageId
  return jsonRequest(
    `${baseUrl}/transfer/v2/p2p/tempalte`,
    true,
    params,
    'PUT',
    {
      badRequest: showError,
      internalServerError: showError
    }
  )
}

function deleteP2PTemplateAjax(id) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/p2p/tempalte/${id}`,
    true,
    undefined,
    'DELETE'
  )
}

export function fetchPrepareTemplateTrustworthy({ id, ...body }) {
  return jsonRequest(
    `${baseUrl}/billing/v2/utility/templates/${id}/trust`,
    true,
    body,
    'PUT'
  )
}
export function fetchTemplateTrustworthy({ validationId, operationId, otp }) {
  return jsonRequest(
    `${baseUrl}/billing/v2/utility/templates/trust/validate-update`,
    true,
    { validationId, otp, operationId },
    'POST'
  )
}
export function fetchSendAuthCode({ operationId }) {
  return jsonRequest(
    `${baseUrl}/billing/v2/utility/templates/send`,
    true,
    { operationId },
    'POST'
  )
}

export default {
  fetchValidateCode,
  fetchTaxBeneficiary,
  fetchTaxType,
  fetchRegions,
  fetchTransferTemplatesAjax,
  addTransferTemplateAjax,
  editTransferTemplateAjax,
  deleteTransferTemplateAjax,
  addMobileTemplateAjax,
  editMobileTemplateAjax,
  deleteMobileTemplateAjax,
  fetchMobileTemplatesAjax,
  checkUserAvatarAjax,
  fetchRemittanceTemplatesAjax,
  addRemittanceTemplateAjax,
  editRemittanceTemplateAjax,
  deleteRemittanceTemplateAjax,
  fetchP2pSaveCardTemplateAjax,
  fetchP2pUpdateCardTemplateAjax,
  deleteP2PTemplateAjax,
  fetchPiTransferTemplatesAjax,
  addPiTransferTemplateAjax,
  editPiTransferTemplateAjax,
  deletePiTransferTemplateAjax
}
