export const config = {
  receiverName: {
    transKey: 'ReceiverName',
    inTooltip: true,
    receiverName: true
  },
  // senderAmount: {
  //   transKey: 'senderAmount',
  //   inTooltip: true,
  //   senderAmount: true
  // },
  description: {
    transKey: 'Description',
    inTooltip: true,
    description: true
  },
  payType: {
    transKey: 'PayType',
    inTooltip: true,
    payType: true
  },
  receiverIban: {
    transKey: 'ReceiverIban',
    inTooltip: true,
    iban: true
  },
  fee: {
    transKey: 'TransactionFee',
    inTooltip: true,
    fee: true
  },
  taxAmount: {
    transKey: 'TaxAmount',
    inTooltip: true,
    taxAmount: true
  },
  pensionAmount: {
    transKey: 'PensionAmount',
    inTooltip: true,
    pensionAmount: true
  }
  // calculatePensionAndTax: {
  //   transKey: 'calculatePensionAndTax',
  //   inTooltip: true,
  //   visible: true
  // }
}

export default [
  {
    cellKey: 'receiverName',
    visible: true
  },
  {
    cellKey: 'senderAmount',
    visible: true
  },
  {
    cellKey: 'description',
    visible: true
  },
  {
    cellKey: 'payType',
    visible: false
  },
  {
    cellKey: 'receiverIban',
    visible: true
  },
  {
    cellKey: 'fee',
    visible: false
  },
  {
    cellKey: 'taxAmount',
    visible: false
  },
  {
    cellKey: 'pensionAmount',
    visible: false
  }
  // {
  //   cellKey: 'calculatePensionAndTax',
  //   visible: false
  // }
]
