import { thirdParty } from '../../constants/constants'

const initialState = {
  isLoading: false,
  items: [],
  error: null
}
export default function(state = initialState, action) {
  switch (action.type) {
    case thirdParty.SET_LOADING: {
      state = {
        ...state,
        isLoading: action.payload
      }
      break
    }
    case thirdParty.SET_THIRDPARTY_ITEMS: {
      state = {
        ...state,
        items: action.payload,
        isLoading: false
      }
      break
    }
    case thirdParty.SET_THIRDPARTY_ERROR: {
      state = {
        ...state,
        error: action.payload,
        isLoading: false
      }
      break
    }
    case thirdParty.SET_THIRDPARTY_ITEM_HIDDEN: {
      state = {
        ...state,
        items: state.items.map(item => {
          if (item.name === action.payload.name) {
            return {
              ...item,
              hidden: action.payload.hidden
            }
          }
          return item
        })
      }

      break
    }
    case thirdParty.SET_THIRDPARTY_ITEM_ACTIVATE: {
      console.log('resss', action.payload)
      state = {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.productId) {
            return {
              ...item,
              activated: action.payload.activated
            }
          }
          return item
        })
      }

      break
    }
    default:
      return state
  }
  return state
}
