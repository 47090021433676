import { requestMoney } from '../../constants/constants'
import { updateObject } from '../../helpers/util'

const initialState = {
  isLoading: false,
  quantity: 0,
  error: null
}

export default function RequestMoneyReducer(state = initialState, action) {
  switch (action.type) {
    case requestMoney.SET_LOADER:
      return updateObject(state, {
        isLoading: action.payload
      })
    case requestMoney.SET_ERROR:
      return updateObject(state, {
        isLoading: false,
        error: action.payload
      })
    case requestMoney.SET_QUANTITY:
      return updateObject(state, {
        isLoading: false,
        quantity: action.payload
      })

    default:
      return state
  }
}
