import React, { Component } from 'react'
import { connect } from 'react-redux'
import userActions from '../redux/actions/user.actions'
import config from '../constants/config'
import { fetchIBBuildInfo, fetchAPIBuildInfo } from '../api/infrastructure.api'
import { store } from '../redux/state'
import hasUserPermission from '../helpers/hasUserPermission'
import { startFetchThirdPartys } from '../redux/actions/thirdparty.actions'

class LoadGlobalSettings extends Component {
  state = {
    IBBuildInfo: null,
    APIBuildInfo: null
  }

  componentDidMount() {
    this.props.dispatch(userActions.fetchUserSettings())
    if (config.serverConfig.showBuildInfo) {
      fetchIBBuildInfo()
        .then(result => {
          this.setState({ IBBuildInfo: result })
        })
        .catch(e => {
          this.setState({ IBBuildInfo: null })
        })
      fetchAPIBuildInfo()
        .then(result => this.setState({ APIBuildInfo: result }))
        .catch(e => this.setState({ APIBuildInfo: null }))
    }
    if (hasUserPermission(false, true)) {
      this.props.dispatch(startFetchThirdPartys())
    }
  }

  onCopyBearerClick = () => {
    const str = store.getState().oidc.user.access_token
    const el = document.createElement('textarea')
    el.value = `Bearer ${str}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  render() {
    if (config.serverConfig.showBuildInfo) {
      return (
        <div className="global-settings">
          <div
            className="fnt-15"
            onClick={this.onCopyBearerClick}
            style={{ cursor: 'pointer', color: 'blue' }}
          >
            copy bearer
          </div>
          {this.state.IBBuildInfo && (
            <div className="bb">
              <div className="fnt-10 fnt-low-b">{`Branch: ${this.state.IBBuildInfo.Branch}`}</div>
              <div>{`BuildNumber: ${this.state.IBBuildInfo.BuildNumber}`}</div>

              <div>{`Date: ${this.state.IBBuildInfo.Date}`}</div>
            </div>
          )}

          {this.state.APIBuildInfo && (
            <div>
              <div className="fnt-10 fnt-low-b">{`Branch: ${this.state.APIBuildInfo.Branch}`}</div>
              <div>{`BuildNumber: ${this.state.APIBuildInfo.BuildNumber}`}</div>

              <div>{`Date: ${this.state.APIBuildInfo.Date}`}</div>
            </div>
          )}
        </div>
      )
    }
    return null
  }
}

export default connect(null)(LoadGlobalSettings)
