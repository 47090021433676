import { corporateConstants } from '../../constants/constants'
import { updateObject } from '../../helpers/util'
import moment from 'moment'
import 'moment-timezone'
const TAKE = 100

const initialState = {
  filter: {
    filtered: false,
    additionalFilters: false,
    filterParams: {
      keyword: '',
      fromAccount: null,
      startDate: moment()
        .tz('UTC')
        .local()
        .subtract('months', 1),
      endDate: moment()
        .tz('UTC')
        .local()
        .endOf('day'),
      status: '',
      amountRangeStart: null,
      amountRangeEnd: null,
      commissionRangeStart: null,
      commissionRangeEnd: null,
      transactionsCount: null,
      author: '',
      authorizer: '',
      creator: ''
    },
    optionParams: {
      needMyAuthorization: true,
      showFutureBatchTransfers: false,
      createdByMe: false
    }
  }
}

export default function batchTransfersReducer(state = initialState, action) {
  switch (action.type) {
    case corporateConstants.UPDATE_BATCH_FILTER_STATE:
      return updateObject(state, {
        filter: updateObject(state.filter, action.payload)
      })
    case corporateConstants.CLEAR_BATCH_FILTER_STATE:
      return updateObject(state, { filter: initialState.filter })

    default:
      return state
  }
}
