import i18n from '../../../i18n'
import { getUnWatchedRequests } from '../../api/request-money.api'
import { requestMoney } from '../../constants/constants'

export const setRequestMoneyLoader = payload => ({
  type: requestMoney.SET_LOADER,
  payload
})

export const setRequestMoneyError = payload => ({
  type: requestMoney.SET_ERROR,
  payload
})

export const setRequestMoneyQuantity = payload => ({
  type: requestMoney.SET_QUANTITY,
  payload
})

export function startFetchRequestMoneyQuantity() {
  return async dispatch => {
    try {
      dispatch(setRequestMoneyLoader(true))
      let payload = await getUnWatchedRequests()

      dispatch(setRequestMoneyQuantity(payload))
    } catch (error) {
      let payload =
        error && error.errors
          ? error.errors[0]
          : i18n.t('P2p.ErrorCardsLoading')

      dispatch(setRequestMoneyError(payload))
    }
  }
}
