import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'
import Download from 'downloadjs'

export async function fetchAccountsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/account`, true)
}
export async function fetchAllAccountsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/account/all-accounts`, true)
}

export function fetchDebitsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/debit/accounts`, true)
}

export function fetchSavingsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/saving/accounts`, true)
}

export function fetchCurrentsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/current/accounts`, true)
}

export function fetchDebitCardsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/debit/accounts/card`, true)
}

export function fetchCreditsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/credit/accounts`, true)
}

export function fetchCreditCardsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/credit/accounts/card`, true)
}

export function fetchDepositsAjax() {
  return jsonRequest(`${baseUrl}/deposit/v2/deposits`, true)
}

export function fetchLoansAjax() {
  return jsonRequest(`${baseUrl}/Loan/v1/loans`, true)
}

export function fetchQisaLoansAjax() {
  return jsonRequest(`${baseUrl}/Loan/v1/loans/qisa-loans`, true)
}

export function saveFriendlyName(iban, friendlyName, accountId) {
  let params = {
    iban: iban,
    friendlyName: friendlyName,
    accountId: accountId
  }
  return jsonRequest(
    `${baseUrl}/account/v4/account/property/friendlyname`,
    true,
    params,
    'PUT'
  )
}

export function setPrimary(iban, accountId) {
  let params = {
    iban: iban,
    accountId: accountId
  }
  return jsonRequest(
    `${baseUrl}/account/v4/account/property/primary`,
    true,
    params,
    'PUT',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export function setHidden(iban, hidden, accountId) {
  let params = {
    iban: iban,
    hidden: hidden,
    accountId
  }
  return jsonRequest(
    `${baseUrl}/account/v4/account/property/hidden`,
    true,
    params,
    'PUT'
  )
}

export function fetchAccountInfo(
  iban,
  currency,
  error = { badRequest: false }
) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/to-someone/receiver?Iban=${iban}&Currency=${currency}`,
    true,
    null,
    'GET',
    error
  )
}

export function fetchCardsAjax() {
  return jsonRequest(`${baseUrl}/account/v4/account/card`, true)
}
export function blockCard(cardId) {
  return jsonRequest(
    `${baseUrl}/account/v4/account/card/${cardId}/block`,
    true,
    null,
    'PUT'
  )
}
export function unblockCard(cardId, body) {
  return jsonRequest(
    `${baseUrl}/account/v4/account/card/${cardId}/unblock`,
    true,
    body,
    'PUT'
  )
}

export function fetchAccountsRestrictedAjax() {
  return jsonRequest(`${baseUrl}/account/v4/account/restricted`, true)
}

export function downloadRequisited(iban, currency, success, error) {
  if (!iban) {
    return
  }
  const targetFilename = `Account_Details_${currency}`
  const mime = 'application/pdf'
  const url = `${baseUrl}/account/v4/account/${iban}/${currency}/requisites/pdf`
  jsonRequest(url, true)
    .then(res => {
      res
        .blob()
        .then(blb => {
          Download(blb, targetFilename, mime)
          if (success) success()
        })
        .catch(() => {
          if (error) error()
        })
    })
    .catch(() => error())
}

export const setCurrencyPriority = (iban, accountCurrencies) => {
  return jsonRequest(
    `${baseUrl}/account/v4/account/SetAccountCurrencyPriorities`,
    true,
    { iban, accountCurrencies },
    'PUT'
  )
}

export async function fetchP2pCardsAjax() {
  return jsonRequest(`${baseUrl}/account/v2/p2p/card`, true)
}

export function setP2pCardFriendlyName(id, friendlyName) {
  return jsonRequest(
    `${baseUrl}/account/v2/p2p/card/friendly-name`,
    true,
    { id, friendlyName },
    'PUT'
  )
}

export function setP2pCardFavorite(id) {
  return jsonRequest(
    `${baseUrl}/account/v2/p2p/card/favorite`,
    true,
    { id },
    'PUT'
  )
}

export function deleteP2pCard(id) {
  return jsonRequest(`${baseUrl}/account/v2/p2p/card`, true, { id }, 'DELETE')
}

export function createP2pCardOrder() {
  return jsonRequest(
    `${baseUrl}/account/v2/p2p/card/create-order`,
    true,
    null,
    'POST'
  )
}
export function createP2pCardOrderTemp() {
  return jsonRequest(
    `${baseUrl}/account/v2/p2p/card/create-order/temporary`,
    true,
    null,
    'POST'
  )
}
export function getTransferMatrixAjax() {
  return jsonRequest(
    `${baseUrl}/account/v4/account/service/matrix`,
    true,
    null,
    'GET'
  )
}
export function fetchP2pTempCard(orderId) {
  return jsonRequest(
    `${baseUrl}/account/v2/p2p/card/temporary/${orderId}`,
    true,
    null,
    'GET'
  )
}

export async function fetchP2pNewCardsAjax(orderId) {
  return jsonRequest(`${baseUrl}/account/v2/p2p/card/${orderId}`, true)
}

export async function fetchP2PSaveNewCardAjax(orderId) {
  return jsonRequest(
    `${baseUrl}/account/v2/p2p/card/save/${orderId}`,
    true,
    null,
    'POST'
  )
}
export function fetchP2PCardBanksAjax() {
  return jsonRequest(`${baseUrl}/account/v2/p2p/card/banks`, true)
}

export function updateP2PCardBankAjax({ p2PCardId, bankId }) {
  return jsonRequest(
    `${baseUrl}/account/v2/p2p/card/bank`,
    true,
    { p2PCardId, bankId },
    'PUT'
  )
}

export function checkP2PCardAddLimit() {
  return jsonRequest(`${baseUrl}/account/v2/p2p/card/check-limit`, true)
}

export function fetchP2pCardsTrnsferLimitsAjax() {
  return jsonRequest(`${baseUrl}/transfer/v2/p2p/configuration`, true)
}

// card restriction functions
export function sendTwoFactorCode(sessionId = null) {
  return jsonRequest(
    `${baseUrl}/account/v4/debit/sendTwoFactor`,
    true,
    { sessionId },
    'POST'
  )
}
export function submitCardRestriction(data) {
  return jsonRequest(
    `${baseUrl}/account/v4/debit/changeRiskCategory`,
    true,
    data,
    'POST'
  )
}

// reset pin for cards
export function sendTwoFactor(data) {
  return jsonRequest(
    `${baseUrl}/account/v4/account/send-two-factor`,
    true,
    data,
    'POST'
  )
}

export function resetPin(data) {
  return jsonRequest(
    `${baseUrl}/account/v4/account/reset-pin`,
    true,
    data,
    'PUT'
  )
}

export function downloadSavingsDetailsPdf(id, fileName = 'Important Terms') {
  return jsonRequest(
    `${baseUrl}/account/v4/saving/details/${id}/pdf`,
    true
  ).then(res =>
    res.blob().then(blb => {
      return Download(blb, fileName, 'application/pdf')
    })
  )
}

export function downloadDebitDetailsPdf(iban, fileName = 'Important Terms') {
  return jsonRequest(
    `${baseUrl}/account/v4/debit/details/${iban}/pdf`,
    true
  ).then(res =>
    res.blob().then(blb => {
      return Download(blb, fileName, 'application/pdf')
    })
  )
}

export function downloadCreditDetailsPdf(id, fileName = 'Important Terms') {
  return jsonRequest(
    `${baseUrl}/account/v4/credit/details/${id}/pdf`,
    true
  ).then(res =>
    res.blob().then(blb => {
      return Download(blb, fileName, 'application/pdf')
    })
  )
}
