import { accountConstants } from '../../constants/constants'
import * as client from '../../api/account.api'
import { rename } from '../../api/moneyBox.api'
import { mergeAccountsWithCards, nullToArray } from '../../helpers/util'

function fetchLoans() {
  return async dispatch => {
    dispatch({ type: accountConstants.FETCH_LOANS })
    let results = []
    let loanError = null
    let qisaLoanError = null

    try {
      results = await client.fetchLoansAjax()
    } catch (err) {
      loanError = err
    }

    try {
      results = [
        ...nullToArray(results),
        ...nullToArray(await client.fetchQisaLoansAjax())
      ]
    } catch (error) {
      qisaLoanError = error
    }

    if (results && !results.length && (loanError || qisaLoanError)) {
      dispatch({
        type: accountConstants.FETCH_LOANS_ERROR,
        error: loanError || qisaLoanError
      })
    }

    dispatch({
      type: accountConstants.FETCH_LOANS_SUCCESS,
      payload: results
    })
  }
}

export function fetchAccounts() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_ACCOUNT })
    return client.fetchAccountsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_ACCOUNTS_SUCCESS,
          payload: result
            ? mergeAccountsWithCards(result.accounts, result.cards)
            : result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_ACCOUNT_ERROR, error })
      }
    )
  }
}

export function fetchAllAccounts() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_ACCOUNT })
    return client.fetchAllAccountsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_ALL_ACCOUNTS_SUCCESS,
          payload: result
            ? mergeAccountsWithCards(result.accounts, result.cards)
            : result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_ALL_ACCOUNT_ERROR, error })
      }
    )
  }
}

function fetchTransferMatrix() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_TRANSFER_MATRIX })
    client.getTransferMatrixAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_TRANSFER_MATRIX_SUCCESS,
          payload: result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_TRANSFER_MATRIX_ERROR, error })
      }
    )
  }
}

function fetchDeposits() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_DEPOSITS })
    client.fetchDepositsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_DEPOSITS_SUCCESS,
          payload: result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_DEPOSIT_SERROR, error })
      }
    )
  }
}

function fetchCredits() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_CREDITS })
    client.fetchCreditsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_CREDITS_SUCCESS,
          payload: result
            ? mergeAccountsWithCards(result.accounts, result.cards)
            : result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_CREDITS_ERROR, error })
      }
    )
  }
}

function fetchCreditCards() {
  return dispatch => {
    client.fetchCreditCardsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_CREDIT_CARDS_SUCCESS,
          payload: result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_CREDIT_CARDS_ERROR, error })
      }
    )
  }
}

function fetchDebits() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_DEBITS_LIST })
    client.fetchDebitsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_DEBITS_LIST_SUCCESS,
          payload: result
            ? mergeAccountsWithCards(result.accounts, result.cards)
            : result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_DEBITS_LIST_ERROR, error })
      }
    )
  }
}

function fetchSavings() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_SAVING_LIST })
    client.fetchSavingsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_SAVING_LIST_SUCCESS,
          payload: result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_SAVING_LIST_ERROR, error })
      }
    )
  }
}

function fetchCurrents() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_CURRENT_LIST })
    client.fetchCurrentsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_CURRENT_LIST_SUCCESS,
          payload: result
            ? mergeAccountsWithCards(result.accounts, result.cards)
            : result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_CURRENT_LIST_ERROR, error })
      }
    )
  }
}

function fetchDebitCards() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_DEBIT_CARDS })
    client.fetchDebitCardsAjax().then(
      result => {
        dispatch({
          type: accountConstants.FETCH_DEBIT_CARDS_SUCCESS,
          payload: result
        })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_DEBIT_CARDS_ERROR, error })
      }
    )
  }
}

function saveFriendlyNameSuccess(payload) {
  return {
    type: accountConstants.FRIENDLY_NAME_SAVE_SUCCESS,
    payload: payload
  }
}

function saveFriendlyName(iban, friendlyName, accountId, isMoneybox) {
  return dispatch => {
    isMoneybox
      ? rename(accountId, friendlyName).then(() => {
          dispatch(
            saveFriendlyNameSuccess({
              iban: iban,
              friendlyName: friendlyName,
              accountId
            })
          )
        })
      : client.saveFriendlyName(iban, friendlyName, accountId).then(
          // TODO: Please check the *result* before dispatching action
          () => {
            dispatch(
              saveFriendlyNameSuccess({
                iban: iban,
                friendlyName: friendlyName,
                accountId
              })
            )
          },
          () => {}
        )
  }
}

function setPrimary(iban, accountId) {
  return dispatch => {
    dispatch({
      type: accountConstants.SET_PRIMARY_SUCCESS,
      payload: { iban: iban, accountId: accountId }
    })
    client.setPrimary(iban, accountId).then(
      () => {},
      () => {}
    )
  }
}

function fetchCards() {
  return dispatch => {
    dispatch({ type: accountConstants.FETCH_CARD })
    client.fetchCardsAjax().then(
      result => {
        dispatch({ type: accountConstants.FETCH_CARD_SUCCESS, payload: result })
      },
      error => {
        dispatch({ type: accountConstants.FETCH_CARD_ERROR, error })
      }
    )
  }
}

function updateDebitCardStatus(result) {
  return { type: accountConstants.UPDATE_DEBIT_CARD_STATUS, payload: result }
}

function updateCreditCardStatus(result) {
  return { type: accountConstants.UPDATE_CREDIT_CARD_STATUS, payload: result }
}

function resetPrevAccounts(array, transferType) {
  return {
    type: accountConstants.RESET_PREV_ACCOUNTS,
    payload: { accounts: array, transferType: transferType }
  }
}

function updateCurrencyPriority(iban, accounts) {
  return {
    type: accountConstants.UPDATE_CURRENCY_PRIORITY,
    payload: { iban, accounts }
  }
}

const accountsActions = {
  fetchAccounts,
  fetchCredits,
  fetchCreditCards,
  fetchDeposits,
  fetchLoans,
  fetchDebits,
  fetchSavings,
  fetchDebitCards,
  saveFriendlyName,
  setPrimary,
  fetchCards,
  fetchCurrents,
  updateDebitCardStatus,
  updateCreditCardStatus,
  resetPrevAccounts,
  updateCurrencyPriority,
  fetchTransferMatrix
}

export default accountsActions
