import { corporateConstants } from '../../../constants/constants'
import { store } from '../../state'
import { currentTask as client } from '../../../api/corporate'

export const filterToParams = (filter, sub) => ({
  senderIban: filter.account && filter.account.iban,
  //isGroupTransfer: false,
  toAmount: filter.amountTo,
  fromAmount: filter.amountFrom,
  currency: filter.currency,
  paymentTypes: filter.type
    ? filter.type === 'ToSomeone'
      ? ['ToSomeoneInternal', 'ToSomeoneExtrenal']
      : [filter.type]
    : [],
  paymentName: filter.pattern,
  creatorUserId:
    (!filter.status || filter.status === 'IsTemporary') && filter.createdByMe
      ? sub
      : filter.creator && filter.creator.userId,
  signedBy: filter.authorizer && filter.authorizer.userId,
  receiverIban: filter.receiverIban,
  receiverName: filter.receiverName,
  taxPayerCode: filter.receiverCadastralCode,
  feeFrom: filter.feeFrom,
  feeTo: filter.feeTo,
  needMyAuthorization:
    filter.status === 'ToBeApproved' ? filter.needMyAuth : undefined,
  startDate: filter.startDate,
  endDate: filter.endDate,
  status: filter.status
    ? filter.status === 'RejectedCanceled'
      ? filter.onlyCanceled
        ? ['Canceled']
        : ['Rejected', 'Canceled']
      : filter.status === 'Pending'
      ? [
          'Pending',
          'SendBankApproval',
          'WaitBankApproval',
          'WaitAmlScan',
          'WaitAmlCheck',
          'SendTranFilter',
          'WaitTranFilter',
          'BlockedTransaction'
        ]
      : [filter.status]
    : filter.onlyFuture
    ? ['Future']
    : [],
  keyword: filter.pattern,
  skip: filter.skip,
  take: filter.take,
  loadMoreCurrentTasks: filter.loadMoreCurrentTasks
})

export function clearCurrentTasks() {
  return { type: corporateConstants.CLEAR_CURRENT_TASKS_LIST }
}
export function setCurrentTaskModalId(id) {
  return {
    type: corporateConstants.SET_CURRENT_TASK_DETAIL_MODAL_ID,
    payload: id
  }
}

export function fetchCurrentTasks(params) {
  return dispatch => {
    dispatch({ type: corporateConstants.FETCH_CURRENT_TASKS, payload: params })

    const {
      corporate: { currentTasksFilter },
      oidc: { user }
    } = store.getState()

    client
      .fetchCurrentTasksAjax(
        filterToParams(currentTasksFilter, user.profile.sub)
      )
      .then(
        result => {
          dispatch({
            type: corporateConstants.FETCH_CURRENT_TASKS_SUCCESS,
            payload: result
          })
        },
        error => {
          dispatch({
            type: corporateConstants.FETCH_CURRENT_TASKS_ERROR,
            error
          })
        }
      )
  }
}

export function fetchMoreCurrentTasks() {
  return dispatch => {
    dispatch({ type: corporateConstants.FETCH_MORE_CURRENT_TASKS })

    const {
      corporate: { currentTasksFilter },
      oidc: { user }
    } = store.getState()

    client
      .fetchCurrentTasksAjax(
        filterToParams(currentTasksFilter, user.profile.sub)
      )
      .then(
        result => {
          dispatch({
            type: corporateConstants.FETCH_MORE_CURRENT_TASKS_SUCCESS,
            payload: result
          })
        },
        error => {
          dispatch({
            type: corporateConstants.FETCH_MORE_CURRENT_TASKS_ERROR,
            error
          })
        }
      )
  }
}

export function fetchCurrentTaskDetail(id) {
  return dispatch => {
    dispatch({ type: corporateConstants.FETCH_CURRENT_TASK_DETAIL })

    client.fetchCurrentTaskDetailsAjax(id).then(
      result => {
        dispatch({
          type: corporateConstants.FETCH_CURRENT_TASK_DETAIL_SUCCESS,
          payload: result
        })
      },
      error => {
        dispatch({
          type: corporateConstants.FETCH_CURRENT_TASK_DETAIL_ERROR,
          error
        })
      }
    )
  }
}

export function fetchCurrentTaskDetails(id) {
  return dispatch => {
    return client.exportCurrentTaskDetails(id)
  }
}
