import { uiConstants } from '../../constants/constants'
import { fetchPhoneMetaDataAjax } from '../../api/identity.api'
import { nullToArray } from '../../helpers/util'

export function setHeaderShade() {
  return dispatch => {
    dispatch({ type: uiConstants.SET_HEADER_SHADE })
  }
}
export function removeHeaderShade() {
  return dispatch => {
    dispatch({ type: uiConstants.REMOVE_HEADER_SHADE })
  }
}

export function fixUtilityBasket() {
  return dispatch => {
    dispatch({ type: uiConstants.FIX_UTILITY_BASKET })
  }
}
export function unfixUtilityBasket() {
  return dispatch => {
    dispatch({ type: uiConstants.UNFIX_UTILITY_BASKET })
  }
}

export function addScrollEventHandler(payload) {
  return { type: uiConstants.ADD_SCROLL_EVENT_HANDLER, payload: payload }
}

export function removeScrollEventHandler(payload) {
  return { type: uiConstants.REMOVE_SCROLL_EVENT_HANDLER, payload: payload }
}

export function fetchPhoneMetaData() {
  return dispatch => {
    dispatch({ type: uiConstants.FETCH_PHONE_METADATA })
    return fetchPhoneMetaDataAjax().then(
      result => {
        dispatch({
          type: uiConstants.FETCH_PHONE_METADATA_SUCCESS,
          payload: nullToArray(result).map(r => ({
            ...r,
            label: r.code,
            value: r.code
          }))
        })
      },
      error => {
        dispatch({ type: uiConstants.FETCH_PHONE_METADATA_ERROR, error })
      }
    )
  }
}

export function showGlobalMessage(payload) {
  return { type: uiConstants.SHOW_GLOBAL_MESSAGE, payload: payload }
}
