export const config = {
  regDate: {
    transKey: 'RegDate',
    date: true,
    inTooltip: true,
    visible: false
  },
  name: {
    transKey: 'name',
    inTooltip: true,
    visible: true
  },
  transactionCount: {
    transKey: 'transactionCount',
    inTooltip: true,
    visible: true,
    wssCanChange: true
  },
  canceledBy: {
    transKey: 'canceledBy',
    inTooltip: true,
    visible: true
  },
  awaitingSignRoles: {
    transKey: 'awaitingSignRoles',
    inTooltip: true,
    visible: true
  },
  iban: {
    transKey: 'iban',
    iban: true,
    inTooltip: true,
    visible: true
  },
  paymentDate: {
    transKey: 'paymentDate',
    date: true,
    inTooltip: true,
    visible: false
  },
  feeSum: {
    transKey: 'feeSum',
    inTooltip: true,
    visible: true
  },
  createdBy: {
    transKey: 'createdBy',
    inTooltip: true,
    visible: true
  },
  signedBy: {
    transKey: 'signedBy',
    inTooltip: true,
    visible: true
  }
}

export default [
  {
    cellKey: 'regDate',
    visible: true
  },
  {
    cellKey: 'name',
    visible: true
  },
  {
    cellKey: 'transactionCount',
    visible: true
  },
  {
    cellKey: 'canceledBy',
    visible: false
  },
  {
    cellKey: 'awaitingSignRoles',
    visible: false
  },
  {
    cellKey: 'iban',
    visible: false
  },
  {
    cellKey: 'paymentDate',
    visible: false
  },
  {
    cellKey: 'feeSum',
    visible: false
  },
  {
    cellKey: 'createdBy',
    visible: false
  },
  {
    cellKey: 'signedBy',
    visible: false
  }
]
