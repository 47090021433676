import { jsonRequest } from '../../helpers/apiRequest'
import { baseUrl } from '../../helpers/baseUrl'

const endpoint = '/corporate/v1/offers/business-account'

export function fetchBranchesAjax() {
  return jsonRequest(`${baseUrl}${endpoint}/branches`, true)
}

export function fetchSmsFormatsAjax() {
  return jsonRequest(`${baseUrl}${endpoint}/sms-formats`, true)
}

export function fetchCurrenciesAjax() {
  return jsonRequest(`${baseUrl}${endpoint}/currencies`, true)
}

export function fetchCheckAjax() {
  return jsonRequest(`${baseUrl}${endpoint}/check`, true)
}

export function fetchAgreementAjax() {
  return jsonRequest(`${baseUrl}${endpoint}/agreement`, true)
}

export function fetchConsentTemplate() {
  return jsonRequest(`${baseUrl}${endpoint}/template`, true).then(res =>
    res.blob()
  )
}

export const createBusinessAccount = data => {
  return jsonRequest(`${baseUrl}${endpoint}`, true, data, 'POST')
}

export const sendCode = session => {
  return jsonRequest(
    `${baseUrl}${endpoint}/send-code`,
    true,
    { sessionId: session },
    'POST'
  )
}
