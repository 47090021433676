export const config = {
  receiverName: {
    transKey: 'ReceiverName',
    inTooltip: true,
    receiverName: true
  },
  description: {
    transKey: 'Description',
    inTooltip: true,
    description: true
  },
  payType: {
    transKey: 'PayType',
    inTooltip: true,
    payType: true
  },
  receiverIban: {
    transKey: 'ReceiverIban',
    inTooltip: true,
    iban: true
  },
  fee: {
    transKey: 'TransactionFee',
    inTooltip: true,
    fee: true
  }
}

export default [
  {
    cellKey: 'receiverName',
    visible: true
  },
  {
    cellKey: 'description',
    visible: true
  },
  {
    cellKey: 'payType',
    visible: true
  },
  {
    cellKey: 'receiverIban',
    visible: false
  },
  {
    cellKey: 'fee',
    visible: false
  }
]
