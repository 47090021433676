import { userSettingsConstants } from '../../constants/constants'
import initialBlocks from '../../helpers/blocks-mock'
import { updateObject } from '../../helpers/util'
import defaultCellLayout from '../../constants/currentTasksLayout'
import defaultBatchCellLayout from '../../constants/batchTransfersLayout'
import defaultBatchCellDetailsLayout from '../../constants/batchTransfersDetailsLayout'
import defaultPayRollTransferEmployeeLayout from '../../constants/payrolltransfersEmployeeLayout'
import defaultPayRollTransferCurrentTasksLayout from '../../constants/payrolltransfersCurrenttasksLayout'
import defaultPayrollTransfersDetailListLayout from '../../constants/payrolltransfersDetailListLayout'
import defaultPayrollTransfersDetailCalcListLayout from '../../constants/payrolltransfersDetailCalcListLayout'

const initialConfiguration = {
  dashboardBlocks: {
    leftBlocks: initialBlocks.left,
    rightBlocks: initialBlocks.right
  },
  transactionsCount: 5,
  currentTasksCount: 5,
  blockedAmountsCount: 5,
  otherBlockedAmountsCount: 5,
  accountSettings: {
    hideZeroBalancedAccounts: false,
    showMoreAccountsInDashboard: false
  },
  orderings: {
    account: [],
    creditCard: [],
    deposit: [],
    loan: [],
    transaction: []
  },
  currentTasksOrder: defaultCellLayout,
  batchTransfersOrder: defaultBatchCellLayout,
  batchTransferDetailsOrder: defaultBatchCellDetailsLayout,
  payrollTransferEmployeeOrder: defaultPayRollTransferEmployeeLayout,
  payrollTransferCurrentTasksOrder: defaultPayRollTransferCurrentTasksLayout,
  payrollTransferDetailListOrder: defaultPayrollTransfersDetailListLayout,
  payrollTransfersDetailCalculateListOrder: defaultPayrollTransfersDetailCalcListLayout
}

const initialState = {
  configuration: { ...initialConfiguration },
  configurationLoading: true,
  configurationLoadingError: null,
  browserIE:
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > 0 // detect IE 11 and lower. TODO: test in Edge
}

const mergeConfigs = (oldConfig, newConfig) => {
  let result = updateObject(oldConfig, newConfig)
  result.dashboardBlocks = {
    leftBlocks: [
      ...result.dashboardBlocks.leftBlocks,
      ...initialBlocks.left.filter(c =>
        result.dashboardBlocks.leftBlocks.every(
          d => d.component !== c.component
        )
      )
    ],
    rightBlocks: [
      ...result.dashboardBlocks.rightBlocks,
      ...initialBlocks.right.filter(c =>
        result.dashboardBlocks.rightBlocks.every(
          d => d.component !== c.component
        )
      )
    ]
  }
  return result
}

export default function userSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case userSettingsConstants.FETCH_USER_SETTINGS:
      return updateObject(state, {
        configurationLoading: true,
        configurationLoadingError: null
      })
    case userSettingsConstants.FETCH_USER_SETTINGS_SUCCESS:
      return updateObject(state, {
        configuration: mergeConfigs(state.configuration, action.payload),
        configurationLoading: false,
        configurationLoadingError: null
      })
    case userSettingsConstants.FETCH_USER_SETTINGS_ERROR:
      return updateObject(state, {
        configurationLoading: false,
        configurationLoadingError: action.error
      })
    case userSettingsConstants.UPDATE_CORPORATE_USER_SETTINGS:
      return updateObject(state, { corporateSettings: action.payload })
    default:
      return state
  }
}
