import numeral from 'numeral'

const countDecimals = function(value, number) {
  if (number) return `.${'0'.repeat(number)}`
  if (Math.floor(value) === value) return ''
  let count = String(value).split('.')[1].length || 0
  if (count > 2) count = 2
  return `.${'0'.repeat(count)}`
}

export function percent(value, number) {
  if (typeof value === 'number') {
    const decimals = countDecimals(value, number)
    return `${numeral(value).format(`0${decimals}`)}`
  }
  return ''
}

export default (value, decimalCount, removeExtraZeroes) => {
  if (!decimalCount) return numeral(value).format('0,0.00')
  if (decimalCount === 1) return numeral(value).format('0,0.0')

  let extraZeroes = ''
  for (let i = 0; i < decimalCount - 1; i++) {
    extraZeroes += '0'
  }

  if (removeExtraZeroes) extraZeroes = `[${extraZeroes}]`

  return numeral(value).format(`0,0.0${extraZeroes}`)
}

export const formatwithComma = number => numeral(number).format('0,0')
