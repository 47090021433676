import React, { useEffect, useState } from 'react'

const renderSwitch = size => {
  switch (size) {
    case 'xxs':
      return 'loader-xxs'
    case 'xs':
      return 'loader-xs'

    case 'xsmall':
      return 'loader-xsm'

    case 'small':
      return 'loader-sm'

    case 'large':
      return 'loader-lg'

    default:
      return ''
  }
}

export const LbLoader = ({ isLoading, inCenter, size, className, delay }) => {
  /* hide loader if there is a delay. Set delay in milliseconds */
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (delay && isLoading)
      setTimeout(() => {
        setShow(true)
      }, delay)
    else if (!delay && isLoading) setShow(true)
    else {
      setShow(isLoading)
    }
  }, [delay, setShow, isLoading, show])

  return show ? (
    <div
      className={`loader-out${inCenter ? ' full-size ' : ''} ${
        className ? className : ''
      } ${renderSwitch(size)}`}
    >
      <div className={`loader ${renderSwitch(size)}`}></div>
    </div>
  ) : null
}
