import { p2p } from '../../constants/constants'
import { updateObject } from '../../helpers/util'

const initialState = {
  //P2P
  P2PCardsList: [],
  P2PCardsLoading: false,
  P2PCardsError: null,
  //
  transactionLimits: []
}

export default function P2PReducer(state = initialState, action) {
  switch (action.type) {
    case p2p.SET_P2P_CARDS:
      return updateObject(state, {
        P2PCardsList: action.payload,
        P2PCardsLoading: false,
        P2PCardsError: null
      })
    case p2p.SET_P2P_CARDS_LOADER:
      return updateObject(state, {
        P2PCardsLoading: action.payload,
        P2PCardsError: null
      })
    case p2p.SET_P2P_CARDS_ERROR:
      return updateObject(state, {
        P2PCardsLoading: false,
        P2PCardsError: action.payload
      })
    case p2p.ADD_NEW_CARD: {
      return updateObject(state, {
        P2PCardsList: [
          action.payload,
          ...state.P2PCardsList.filter(
            i => !('orderId' in i && i.orderId === action.orderId)
          )
        ],
        P2PCardsLoading: false
      })
    }

    case p2p.DELETE_CARD:
      return updateObject(state, {
        P2PCardsList: state.P2PCardsList.filter(i => i.id !== action.payload),
        P2PCardsLoading: false
      })
    case p2p.SET_FAVORITE:
      return updateObject(state, {
        P2PCardsList: state.P2PCardsList.map(i => {
          if (i.id === action.payload.id) {
            return { ...i, favorite: !i.favorite }
          }
          return { ...i, favorite: false }
        })
      })
    case p2p.SET_P2PCARD_BLOCK_STATUS:
      return updateObject(state, {
        P2PCardsList: state.P2PCardsList.map(i => {
          if (i.id === action.payload) {
            return { ...i, active: !i.active }
          }
          return i
        })
      })
    case p2p.UPDATE_CARD_BANKNAME:
      return updateObject(state, {
        P2PCardsList: state.P2PCardsList.map(i => {
          if (i.id === action.payload.id) {
            return {
              ...i,
              bankName: action.payload.bankName,
              friendlyName: action.payload.bankName
            }
          }
          return i
        })
      })
    case p2p.SET_FRIENDLY_NAME:
      return updateObject(state, {
        P2PCardsList: state.P2PCardsList.map(i => {
          if (i.id === action.payload.id) {
            return { ...i, friendlyName: action.payload.name }
          }
          return i
        })
      })
    case p2p.SET_P2P_TRANSACTION_LIMITS:
      return updateObject(state, {
        transactionLimits: action.payload
      })

    default:
      return state
  }
}
