import {
  accountConstants,
  depositConstants,
  loanConstants
} from '../../constants/constants'
import { nullToArray, updateObject } from '../../helpers/util'

const initialState = {
  accountList: [],
  accountsLoading: false,
  accountsLoadingError: null,
  setPrimaryLoading: false,
  setPrimaryError: null,
  prevAccountList: [],
  prevTransferType: null,

  allAccountList: [],
  allAccountsLoading: false,
  allAccountsError: null,

  savingsList: [],
  savingsLoading: false,
  savingsLoadingError: null,

  debitsList: [],
  debitsListLoading: false,
  debitsListLoadingError: null,

  transferMatrix: [],
  transferMatrixLoading: false,
  transferMatrixLoadingError: null,

  currentsList: [],
  currentsListLoading: false,
  currentsListLoadingError: null,

  debitCardsList: [],
  debitCardsListLoading: false,
  debitCardsListLoadingError: null,

  creditCardsList: [],
  creditCardsLoading: false,
  creditCardsLoadingError: null,

  depositList: [],
  depositListLoading: false,
  depositListLoadingError: null,

  loanAccountsList: [],
  loanAccountsListLoading: false,
  loanAccountsListLoadingError: null,

  creditsList: [],
  creditsListLoading: false,
  creditsListLoadingError: null,

  cardsList: [],
  cardsLoading: false,
  cardsLoadingError: null
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case accountConstants.FETCH_ACCOUNT:
      return Object.assign({}, state, {
        accountList: [],
        accountsLoading: true,
        accountsLoadingError: null
      })
    case accountConstants.FETCH_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        accountList: nullToArray(action.payload),
        accountsLoading: false,
        accountsLoadingError: null
      })
    case accountConstants.FETCH_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        accountList: [],
        accountsLoading: false,
        accountsLoadingError: action.error
      })
    case accountConstants.FETCH_ALL_ACCOUNT:
      return Object.assign({}, state, {
        allAccountList: [],
        allAccountsLoading: true,
        allAccountsError: null
      })
    case accountConstants.FETCH_ALL_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        allAccountList: nullToArray(action.payload),
        allAccountsLoading: false,
        allAccountsError: null
      })
    case accountConstants.FETCH_ALL_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        allAccountList: [],
        allAccountsLoading: false,
        allAccountsError: action.error
      })

    case accountConstants.FETCH_DEPOSITS:
      return Object.assign({}, state, {
        depositList: [],
        depositListLoading: true,
        depositListLoadingError: null
      })
    case accountConstants.FETCH_DEPOSITS_SUCCESS:
      return Object.assign({}, state, {
        depositList: action.payload,
        depositListLoading: false,
        depositListLoadingError: null
      })
    case accountConstants.FETCH_DEPOSIT_SERROR:
      return Object.assign({}, state, {
        depositListLoading: false,
        depositListLoadingError: action.error
      })

    case accountConstants.FETCH_TRANSFER_MATRIX:
      return Object.assign({}, state, {
        transferMatrix: [],
        transferMatrixLoading: true,
        transferMatrixLoadingError: null
      })
    case accountConstants.FETCH_TRANSFER_MATRIX_SUCCESS:
      return Object.assign({}, state, {
        transferMatrix: action.payload,
        transferMatrixLoading: false,
        transferMatrixLoadingError: null
      })
    case accountConstants.FETCH_TRANSFER_MATRIX_ERROR:
      return Object.assign({}, state, {
        transferMatrixLoading: false,
        transferMatrixLoadingError: action.error
      })

    case accountConstants.FETCH_CREDIT_CARDS:
      return Object.assign({}, state, {
        creditCardsList: [],
        creditCardsLoading: true,
        creditCardsLoadingError: null
      })
    case accountConstants.FETCH_CREDIT_CARDS_SUCCESS:
      return Object.assign({}, state, {
        creditCardsList: action.payload,
        creditCardsLoading: false,
        creditCardsLoadingError: null
      })
    case accountConstants.FETCH_CREDIT_CARDS_ERROR:
      return Object.assign({}, state, {
        creditCardsLoading: false,
        creditCardsLoadingError: action.error
      })

    case accountConstants.FETCH_LOANS:
      return Object.assign({}, state, {
        loanAccountsList: [],
        loanAccountsListLoading: true,
        loanAccountsListLoadingError: null
      })
    case accountConstants.FETCH_LOANS_SUCCESS:
      return Object.assign({}, state, {
        loanAccountsList: action.payload,
        loanAccountsListLoading: false,
        loanAccountsListLoadingError: null
      })
    case accountConstants.FETCH_LOANS_ERROR:
      return Object.assign({}, state, {
        loanAccountsListLoading: false,
        loanAccountsListLoadingError: action.error
      })

    case accountConstants.FETCH_DEBITS_LIST:
      return Object.assign({}, state, {
        debitsList: [],
        debitsListLoading: true
      })
    case accountConstants.FETCH_DEBITS_LIST_SUCCESS:
      return Object.assign({}, state, {
        debitsList: action.payload,
        debitsListLoading: false
      })
    case accountConstants.FETCH_DEBITS_LIST_ERROR:
      return updateObject(state, {
        debitsListLoadingError: action.error,
        debitsListLoading: false
      })

    case accountConstants.FETCH_SAVING_LIST:
      return Object.assign({}, state, { savingsList: [], savingsLoading: true })
    case accountConstants.FETCH_SAVING_LIST_SUCCESS:
      return Object.assign({}, state, {
        savingsList: action.payload,
        savingsLoading: false
      })
    case accountConstants.FETCH_SAVING_LIST_ERROR:
      return updateObject(state, {
        savingsLoadingError: action.error,
        savingsLoading: false
      })

    case accountConstants.FETCH_CURRENT_LIST:
      return updateObject(state, {
        currentsList: [],
        currentsListLoading: true,
        currentsListLoadingError: null
      })
    case accountConstants.FETCH_CURRENT_LIST_SUCCESS:
      return updateObject(state, {
        currentsList: action.payload,
        currentsListLoading: false
      })
    case accountConstants.FETCH_CURRENT_LIST_ERROR:
      return updateObject(state, {
        currentsListLoadingError: action.error,
        currentsListLoading: false
      })

    case accountConstants.FETCH_DEBIT_CARDS:
      return Object.assign({}, state, {
        debitCardsList: [],
        debitCardsListLoading: true
      })
    case accountConstants.FETCH_DEBIT_CARDS_SUCCESS:
      return Object.assign({}, state, {
        debitCardsList: action.payload,
        debitCardsListLoading: false
      })

    case accountConstants.FETCH_CREDITS:
      return Object.assign({}, state, {
        creditsList: [],
        creditsListLoading: true,
        creditsListLoadingError: null
      })
    case accountConstants.FETCH_CREDITS_SUCCESS:
      return Object.assign({}, state, {
        creditsList: nullToArray(action.payload),
        creditsListLoading: false,
        creditsListLoadingError: null
      })
    case accountConstants.FETCH_CREDITS_ERROR:
      return Object.assign({}, state, {
        creditsListLoading: false,
        creditsListLoadingError: action.error
      })

    case accountConstants.FRIENDLY_NAME_SAVE_SUCCESS: {
      let newState
      let listName
      let account
      if (state.debitsList) {
        newState = state.debitsList.slice()
        account = state.debitsList.find(
          account =>
            account.iban == action.payload.iban &&
            account.accounts.some(a => a.accountId === action.payload.accountId)
        )
        listName = 'debitsList'
      }
      if (!account && state.creditsList) {
        newState = state.creditsList.slice()
        account = state.creditsList.find(
          account =>
            account.iban == action.payload.iban &&
            account.accounts.some(a => a.accountId === action.payload.accountId)
        )
        listName = 'creditsList'
      }
      if (!account && state.savingsList) {
        newState = state.savingsList.slice()
        account = state.savingsList.find(
          account =>
            account.iban == action.payload.iban &&
            account.accounts.some(a => a.accountId === action.payload.accountId)
        )
        listName = 'savingsList'
      }
      if (!account && state.currentsList) {
        newState = state.currentsList.slice()
        account = state.currentsList.find(
          account =>
            account.iban == action.payload.iban &&
            account.accounts.some(a => a.accountId === action.payload.accountId)
        )
        listName = 'currentsList'
      }
      if (account) {
        account.friendlyName = action.payload.friendlyName
      }
      return Object.assign({}, state, { [listName]: newState })
    }

    case depositConstants.FRIENDLY_NAME_SAVE_SUCCESS: {
      let newState = state.depositList.slice()
      let deposit = newState.find(deposit => deposit.id == action.payload.id)
      if (deposit) {
        deposit.name = action.payload.friendlyName
      }
      return Object.assign({}, state, { depositList: newState })
    }

    case loanConstants.FRIENDLY_NAME_SAVE_SUCCESS: {
      let newState = state.loanAccountsList.slice()
      let loan = newState.find(loan => loan.id == action.payload.loanId)
      if (loan) {
        loan.friendlyName = action.payload.friendlyName
      }
      return Object.assign({}, state, { loanAccountsList: newState })
    }

    case accountConstants.SET_PRIMARY_SUCCESS: {
      let newState = state.accountList.slice()
      let newState2 =
        state.allAccountList.length && state.allAccountList.slice()

      newState.map(a => {
        if (
          a.iban === action.payload.iban &&
          a.id === action.payload.accountId
        ) {
          a.primary = !a.primary
        } else {
          a.primary = false
        }
      })
      newState2.map(a => {
        if (
          a.iban === action.payload.iban &&
          a.id === action.payload.accountId
        ) {
          a.primary = !a.primary
        } else {
          a.primary = false
        }
      })

      return Object.assign({}, state, {
        accountList: newState,
        allAccountList: newState2
      })
    }

    case accountConstants.FETCH_CARD:
      return Object.assign({}, state, { cardsList: [], cardsLoading: true })
    case accountConstants.FETCH_CARD_SUCCESS:
      return Object.assign({}, state, {
        cardsList: nullToArray(action.payload),
        cardsLoading: false
      })
    case accountConstants.FETCH_CARD_ERROR:
      return Object.assign({}, state, { cardsLoading: false })

    case accountConstants.UPDATE_DEBIT_CARD_STATUS:
      return Object.assign({}, state, {
        debitsList: state.debitsList.map(d => {
          if (d.iban !== action.payload.iban) return d
          return updateObject(d, {
            cards: d.cards.map(c => {
              return c.id === action.payload.id ? c : action.payload
            })
          })
        })
      })

    case accountConstants.UPDATE_CREDIT_CARD_STATUS:
      return Object.assign({}, state, {
        debitsList: state.creditsList.map(d => {
          if (d.iban !== action.payload.iban) return d
          return updateObject(d, {
            cards: d.cards.map(c => {
              return c.id === action.payload.id ? c : action.payload
            })
          })
        })
      })

    case accountConstants.RESET_PREV_ACCOUNTS:
      return Object.assign({}, state, {
        prevAccountList: action.payload.accounts,
        prevTransferType: action.payload.transferType
      })

    case accountConstants.UPDATE_CURRENCY_PRIORITY:
      // eslint-disable-next-line no-case-declarations
      let newDebitsList = []
      if (state.debitsList) {
        newDebitsList = [...state.debitsList]
        let account = newDebitsList.find(
          acc => acc.iban === action.payload.iban
        )
        let newAccount = action.payload.accounts.find(
          acc => acc.iban === action.payload.iban
        )
        if (account && newAccount) {
          let index = newDebitsList.indexOf(account)
          newDebitsList[index] = newAccount
        }
      }
      return { ...state, debitsList: newDebitsList }
    default:
      return state
  }
}
