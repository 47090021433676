import Download from 'downloadjs'
import { store } from '../redux/state'
import { httpErrorConstants } from '../constants/constants'
import userManager from '../helpers/userManager'
import { getCurrentLanguage } from './languageManager'
import moment from 'moment'
import ReactGA from 'react-ga'
import config from '../constants/config'
import { updateObject } from './util'
import i18n from '../../i18n'

function handleResponse(response, internalServerError, badRequest) {
  if (response.status === 401) {
    userManager.object.signoutRedirect()
  }

  return new Promise((resolve, reject) => {
    if (response.ok) {
      if (response.status === 200) {
        let contentType = response.headers.get('content-type')
        if (contentType && contentType.includes('application/json')) {
          //setTimeout(() => response.json().then(json => resolve(json)), 5000);
          response.json().then(json => resolve(json))
        } else {
          // setTimeout(() => resolve(response), 5000);
          resolve(response)
        }
      } else if (response.status === 204) {
        resolve(null)
      }
    } else {
      response.text().then(text => {
        let error = text

        try {
          error = JSON.parse(error)
        } catch (err) {
          if (response.status === 403)
            error = {
              code: 'UNAUTHORIZED_ERROR',
              errors: [i18n.t('Common.UnauthorizedError')]
            }
          else error = { code: 'UNHANDLED_ERROR', errors: ['Unhandled Error'] }
        }

        if (
          badRequest &&
          (response.status === 400 ||
            response.status === 409 ||
            response.status === 406 ||
            response.status === 403 ||
            response.status === 500) &&
          (error.code !== 'UNHANDLED_ERROR' ||
            (error.code === 'UNHANDLED_ERROR' && !text))
        ) {
          store.dispatch({
            type: httpErrorConstants.BAD_REQUEST,
            payload: error
          })
        } else if (internalServerError && response.status === 500) {
          if (error.code === 'UNHANDLED_ERROR') {
            store.dispatch({
              type: httpErrorConstants.INTERNAL_SERVER_ERROR,
              payload: `${response.url}`
            })
          } else {
            store.dispatch({
              type: httpErrorConstants.BAD_REQUEST,
              payload: error
            })
          }
        }

        reject(error)
      })
    }
  })
}

function handleError(error, internalServerError, requestUrl) {
  let err = error

  try {
    err = JSON.parse(err)
  } catch (e) {
    err = { code: 'UNHANDLED_ERROR', errors: ['Unhandled Error'] }
  }
  if (internalServerError) {
    if (err.code === 'UNHANDLED_ERROR') {
      store.dispatch({
        type: httpErrorConstants.INTERNAL_SERVER_ERROR,
        payload: requestUrl
      })
    } else {
      store.dispatch({
        type: httpErrorConstants.BAD_REQUEST,
        payload: err
      })
    }
  }
  return Promise.reject(err) //error && error.message
}

export function jsonRequest(
  url,
  isPrivate = true,
  body,
  method = 'GET',
  httpErrors = {},
  signal = undefined
) {
  httpErrors = updateObject(
    {
      internalServerError: config.serverConfig.server !== 'production',
      badRequest: false
    },
    httpErrors
  )

  const headers = new Headers()

  headers.append('Accept', 'application/json')
  headers.append('Accept-Language', getCurrentLanguage())

  if (isPrivate === true) {
    const user = store.getState().oidc.user
    if (user) headers.append('Authorization', `Bearer ${user.access_token}`)
  }
  let options = { headers, mode: 'cors', method: method }
  if (body) {
    options.method = method
    options.body = JSON.stringify(body)
    headers.append('Content-Type', 'application/json')
  }

  if (signal) {
    options.signal = signal
  }
  const millis = moment().valueOf()

  return fetch(url, options).then(
    response => {
      signal = undefined
      requestTiming(url, method, millis)
      return handleResponse(
        response,
        httpErrors.internalServerError,
        httpErrors.badRequest
      )
    },
    error => {
      signal = undefined
      if (error.name === 'AbortError')
        return Promise.reject({ code: 'AbortError' })
      requestTiming(url, method, millis, true)
      return handleError(error, httpErrors.internalServerError, url)
    }
  )
}

function requestTiming(url, method, millis, error) {
  if (config.serverConfig.server === 'production')
    ReactGA.timing({
      category: 'API Request',
      variable: `load ${url} ${method}${error ? ' with error code' : ''} `,
      value: moment().valueOf() - millis,
      label: `${url} ${method}`
    })
}

export function downloadFileRequest(url, fileName, format, isPrivate) {
  const formatProps = {
    xls: { fileExtension: 'xlsx', mimeType: 'application/vnd.ms-excel' },
    xlsx: {
      fileExtension: 'xlsx',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    csv: { fileExtension: 'csv', mimeType: 'text/csv; charset=utf-8' },
    pdf: { fileExtension: 'pdf', mimeType: 'application/pdf' },
    zip: { fileExtension: 'zip', mimeType: 'application/zip' },
    doc: { fileExtension: 'doc', mimeType: 'application/msword' },
    docx: {
      fileExtension: 'docx',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },
    xml: { fileExtension: 'xml', mimeType: 'application/xml' },
    'ascii-xml': { fileExtension: 'xml', mimeType: 'application/xml' },
    png: { fileExtension: 'png', mimeType: 'image/png' },
    jpg: { fileExtension: 'jpg', mimeType: 'image/jpeg' },
    tif: { fileExtension: 'tif', mimeType: 'image/tiff' }
  }
  const targetFilename = `${fileName}.${formatProps[format].fileExtension}`
  const mime = formatProps[format].mimeType
  return new Promise((resolve, reject) => {
    jsonRequest(url, isPrivate)
      .then(res => {
        res
          .blob()
          .then(blb => {
            Download(blb, targetFilename, mime)
            resolve()
          })
          .catch(e => reject(e))
      })
      .catch(e => reject(e))
  })
}

export function uploadFileRequest(
  url,
  isPrivate = true,
  file,
  method = 'POST',
  fileName = 'ImageSource'
) {
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  //headers.append("Content-Type", "multipart/form-data");
  if (isPrivate === true) {
    const token = store.getState().oidc.user.access_token
    headers.append('Authorization', `Bearer ${token}`)
  }
  let formData = new FormData()
  formData.append(fileName, file)
  let options = { headers, mode: 'cors', method: method, body: formData }
  return fetch(url, options).then(handleResponse, handleError)
}
