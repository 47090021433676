import React, { Component, Fragment } from 'react'
import InputMask from 'react-input-mask'
import moment from 'moment'
import Icon from '../SimpleComponents/Icon'
import i18n from '../../../i18n'

class LBDateInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: props.value,
      isMinDateError: false
    }
    this.onBlurHandler = this.onBlurHandler.bind(this)
    this.onFocusHandler = this.onFocusHandler.bind(this)
    this.onChange = this.onChange.bind(this)
    this.dateValid = this.dateValid.bind(this)
    //this.onBlurHandler = this.onBlurHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ input: this.props.value })
    }
  }

  onBlurHandler() {
    if (!this.dateValid().isValid) {
      this.setState({ invalidDate: true })
    }
  }

  onChange(e) {
    if (e.target.value === this.state.input) return

    this.setState(
      {
        input: e.target.value
      },
      () => {
        let data = this.dateValid()
        if (this.props.inErrorStateHandler) {
          this.props.inErrorStateHandler(!data.isValid && this.state.input)
        }
        if ((data.isValid && this.props.onChange) || !this.state.input) {
          this.props.onChange(data.parsedDate)
        }
        if (this.props.minDate) {
          this.setState({
            isMinDateError:
              data.parsedDate && data.parsedDate < this.props.minDate
          })
        }
        if (this.props.onChangeText) {
          this.props.onChangeText(this.state.input)
        }
      }
    )
  }

  onFocusHandler(e) {
    e.target.select()
    if (this.state.invalidDate) {
      this.setState({ invalidDate: false })
    }
  }

  dateValid() {
    let parsedDate = moment(this.state.input, 'DD/MM/YYYY', true)
    if (parsedDate.isValid()) {
      if (this.props.minDate && parsedDate < this.props.minDate)
        return { isValid: false, parsedDate }
      return { isValid: true, parsedDate }
    }
    return { isValid: false, parsedDate: null }
  }

  hasError() {
    let isValid = this.dateValid().isValid
    return !isValid && this.state.input
  }

  render() {
    return (
      <Fragment>
        <InputMask
          id={`input-inputMask-${this.props.id}`}
          alwaysShowMask={false}
          maskChar=""
          className={
            'lb-datepicker lb-input' +
            (this.hasError() || this.props.hasError ? ' has-error' : '')
          }
          mask="99/99/9999"
          value={this.state.input}
          onChange={this.onChange}
          onBlur={this.onBlurHandler}
          onFocus={this.onFocusHandler}
          disabled={this.props.disabled}
          placeholder={'//'}
          {...this.props.inputProps}
        />
        {this.props.icon && (
          <i
            id={`i-${this.props.id}`}
            className="icn icn-calendar medium-grey ml-15 pointer"
            onClick={this.props.click}
          >
            <Icon name="icn-calendar" view="0 0 19.274 17.266" />
          </i>
        )}
        {this.state.isMinDateError && (
          <span className="po-a min-date">
            {i18n.t('Common.MinDateError', {
              minDate: this.props.minDate.format('DD/MM/YYYY')
            })}
          </span>
        )}
      </Fragment>
    )
  }
}

export default LBDateInput
