import { bannerConstants } from '../../constants/constants'
import { updateObject } from '../../helpers/util'

const initialState = {}
export default function bannerReducer(state = initialState, action) {
  switch (action.type) {
    case bannerConstants.FETCH_BANNER:
      return updateObject(state, {
        [action.payload.banner_type]: {
          loading: true,
          loadingError: null
        }
      })
    case bannerConstants.FETCH_BANNER_SUCCESS:
      return updateObject(state, {
        [action.payload.banner_type]: {
          loading: false,
          banner: action.payload.banner,
          loadingError: null
        }
      })
    case bannerConstants.FETCH_BANNER_ERROR:
      return updateObject(state, {
        [action.payload.banner_type]: {
          loading: false,
          loadingError: action.payload.error
        }
      })

    case bannerConstants.FETCH_LOYALTYCASHBACK:
      return updateObject(state, {
        loyalty_cashback: { loading: true, loadingError: null }
      })
    case bannerConstants.FETCH_LOYALTYCASHBACK_SUCCESS:
      return updateObject(state, {
        loyalty_cashback: {
          loading: false,
          totalEarnedCashback: action.payload.totalEarnedCashback,
          loadingError: null
        }
      })
    case bannerConstants.FETCH_LOYALTYCASHBACK_ERROR:
      return updateObject(state, {
        loyalty_cashback: {
          loading: false,
          totalEarnedCashback: -1,
          loadingError: action.payload.error
        }
      })
    case bannerConstants.FETCH_SOCIAL_PENSION:
      return updateObject(state, {
        socialPension: { loading: true, loadingError: null }
      })
    case bannerConstants.FETCH_SOCIAL_PENSION_SUCCESS:
      return updateObject(state, {
        socialPension: {
          loading: false,
          banner: action.payload,
          loadingError: null
        }
      })
    case bannerConstants.FETCH_SOCIAL_PENSION_ERROR:
      return updateObject(state, {
        socialPension: {
          loading: false,
          banner: null,
          loadingError: action.payload.error
        }
      })

    default:
      return state
  }
}
