import moment from 'moment'
import { getCookie } from './util'
import 'moment-timezone'
import config from '../constants/config'

const LB_LNG_KEY = 'lang'

const langValues = { ka: 'ka', en: 'en-US' }
const langKeys = { ka: 'ka', 'en-US': 'en' }

export const dateLocales = {
  ka: {
    months: {
      standalone: 'იანვარი_თებერვალი_მარტი_აპრილი_მაისი_ივნისი_ივლისი_აგვისტო_სექტემბერი_ოქტომბერი_ნოემბერი_დეკემბერი'.split(
        '_'
      ),
      format: 'იანვარი_თებერვალი_მარტი_აპრილი_მაისი_ივნისი_ივლისი_აგვისტო_სექტემბერი_ოქტომბერი_ნოემბერი_დეკემბერი'.split(
        '_' //'იანვარს_თებერვალს_მარტს_აპრილის_მაისს_ივნისს_ივლისს_აგვისტს_სექტემბერს_ოქტომბერს_ნოემბერს_დეკემბერს'.split(
      )
    },
    monthsShort: 'იან_თებ_მარ_აპრ_მაი_ივნ_ივლ_აგვ_სექ_ოქტ_ნოე_დეკ'.split('_'),
    weekdays: {
      standalone: 'კვირა_ორშაბათი_სამშაბათი_ოთხშაბათი_ხუთშაბათი_პარასკევი_შაბათი'.split(
        '_'
      ),
      format: 'კვირას_ორშაბათს_სამშაბათს_ოთხშაბათს_ხუთშაბათს_პარასკევს_შაბათს'.split(
        '_'
      ),
      isFormat: /(წინა|შემდეგ)/
    },
    weekdaysShort: 'კვი_ორშ_სამ_ოთხ_ხუთ_პარ_შაბ'.split('_'),
    weekdaysMin: 'კვ_ორ_სა_ოთ_ხუ_პა_შა'.split('_'),
    longDateFormat: {
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY h:mm A',
      LLLL: 'dddd, D MMMM YYYY h:mm A'
    },
    calendar: {
      sameDay: '[დღეს] LT[-ზე]',
      nextDay: '[ხვალ] LT[-ზე]',
      lastDay: '[გუშინ] LT[-ზე]',
      nextWeek: '[შემდეგ] dddd LT[-ზე]',
      lastWeek: '[წინა] dddd LT-ზე',
      sameElse: 'L'
    },
    relativeTime: {
      future: function(s) {
        return /(წამი|წუთი|საათი|წელი)/.test(s)
          ? s.replace(/ი$/, 'ში')
          : s + 'ში'
      },
      past: function(s) {
        if (/(წამი|წუთი|საათი|დღე|თვე)/.test(s)) {
          return s.replace(/(ი|ე)$/, 'ის წინ')
        }
        if (/წელი/.test(s)) {
          return s.replace(/წელი$/, 'წლის წინ')
        }
      },
      s: 'რამდენიმე წამი',
      ss: '%d წამი',
      m: 'წუთი',
      mm: '%d წუთი',
      h: 'საათი',
      hh: '%d საათი',
      d: 'დღე',
      dd: '%d დღე',
      M: 'თვე',
      MM: '%d თვე',
      y: 'წელი',
      yy: '%d წელი'
    },
    dayOfMonthOrdinalParse: /0|1-ლი|მე-\d{1,2}|\d{1,2}-ე/,
    ordinal: function(number) {
      if (number === 0) {
        return number
      }
      if (number === 1) {
        return number + '-ლი'
      }
      if (
        number < 20 ||
        (number <= 100 && number % 20 === 0) ||
        number % 100 === 0
      ) {
        return 'მე-' + number
      }
      return number + '-ე'
    },
    week: {
      dow: 1,
      doy: 7
    }
  }
}

export function getCurrentLanguage() {
  let language = getCookie(LB_LNG_KEY)
  if (!language) {
    language = 'ka'
  }
  return langValues[language]
}

export function setCurrentLanguage(key) {
  // setCookie(LB_LNG_KEY, langKeys[key], 300);
  //if(dateLocales[key] )
  if (key === 'en-US') moment.locale(key)
  else moment.updateLocale(key, dateLocales[key])
  moment.tz.setDefault('UTC') // it's breaks hours in out timezone
  config.updateBanners = true
}
