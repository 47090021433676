import React, { Component, Fragment } from 'react'
import { Trans } from 'react-i18next'
import Input from '../SimpleComponents/Input'
import storageApi from '../../api/storage.api'
import { connect } from 'react-redux'
import templateApi, { checkUserAvatarAjax } from '../../api/template.api'
import CropImage from './CropImage'
import formatter from '../../helpers/formatter'
import { LbLoader } from '../SimpleComponents/LBLoader'
import Icon from '../SimpleComponents/Icon'
import i18n from '../../../i18n'
import { LbToast } from '../SimpleComponents/LBToasts'

class SaveTemplateCorp extends Component {
  state = {
    templateName: '',
    imageId: '',
    myImage: this.props.currentTask.isMyNumber, // if my phonenumber - check parameter!
    defaultUserAvatar: '', // if user has uploaded avatar
    uploadImage: false,
    cropImage: '',
    validExt: true,
    className: 'drop-zone-hide',
    loadingImgSave: false,
    errorResponseImgSave: null,
    loadingSubmit: false,
    errorSubmit: null
  }

  CropImage = React.createRef()

  componentDidMount = () => {
    const { paytype, identifier } = this.props.currentTask

    if (paytype === 'MobileTopup') {
      checkUserAvatarAjax(identifier).then(res => {
        this.setState({ defaultUserAvatar: res })
      })
    }
  }

  modalOpenHandler() {
    this.templateModal.handleOpenModal()
  }

  modalCloseHandler() {
    this.templateModal.handleCloseModal()
  }

  templateNameChanged = e => {
    this.setState({ templateName: e.target.value, errorSubmit: null })
  }

  setDragboxRef = node => {
    this.dragboxRef = node

    if (this.dragboxRef) {
      this.dragboxRef.addEventListener('mouseup', this.onDragLeave)
      this.dragboxRef.addEventListener('dragenter', this.onDragEnter)
      this.dragboxRef.addEventListener('dragover', this.onDragOver)
      this.dragboxRef.addEventListener('drop', this.onDrop)
    }
  }

  onDragEnter = e => {
    this.setState({ className: 'drop-zone-show' })
    e.stopPropagation()
    e.preventDefault()
    return false
  }

  onDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  onDragLeave = e => {
    this.setState({ className: 'drop-zone-hide' })
    e.stopPropagation()
    e.preventDefault()
    return false
  }

  onDrop = e => {
    e.preventDefault()
    if (e.dataTransfer.files[0]) {
      if (!this.checkExtension(e.dataTransfer.files[0])) {
        this.setState({ validExt: false })
        return
      }

      const reader = new window.FileReader()

      reader.onload = e => {
        this.setState({ cropImage: e.target.result, validExt: true })
      }

      reader.readAsDataURL(e.dataTransfer.files[0])
      this.setState({ className: 'drop-zone-hide' })
    }
    return false
  }

  imageUploaded = () => {
    let input = this.fileInputRef

    if (input.files && input.files[0]) {
      if (!this.checkExtension(input.files[0])) {
        this.setState({ validExt: false })
        return
      }

      var reader = new window.FileReader()

      reader.onload = e => {
        this.setState({ cropImage: e.target.result, validExt: true })
      }

      reader.readAsDataURL(input.files[0])
    }
  }

  checkExtension = element => {
    const filePath = element.name
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.bmp)$/i

    if (!allowedExtensions.exec(filePath)) {
      return false
    }
    return true
  }

  imageSave = () => {
    const res = this.CropImage.current.cropImage()
    this.setState({ loadingImgSave: true, errorResponseImgSave: null })

    setTimeout(() => {
      storageApi.uploadBase64(res.split(',')[1]).then(
        result => {
          this.setState({
            cropImage: '',
            imageId: result,
            loadingImgSave: false,
            errorResponseImgSave: null,
            uploadImage: true
          })
        },
        error => {
          this.setState({ loadingImgSave: false, errorResponseImgSave: error })
        }
      )
    }, 2000)
  }

  uploadFile = () => {
    this.fileInputRef.click()
  }

  setFileInputRef = node => {
    this.fileInputRef = node
  }

  saveTemplateHandler = () => {
    if (this.state.templateName) {
      this.setState({ loadingSubmit: true, errorSubmit: null })

      let data = this.props.currentTask
      let info = {}

      info.name = this.state.templateName
      info.uploadImage = this.state.uploadImage
      info.description = data.description ? data.description : ''

      // TODO: no receiver country in data - ask if there must be

      switch (data.payType) {
        case 'ToSomeoneExtrenal':
        case 'ToSomeoneInternal':
        case 'InBudget':
          if (data.payType === 'InBudget') info.transferType = 'Budget'
          else info.transferType = 'Transfer'

          info.imageId = this.state.imageId
          if (!data.isThirdPerson) {
            info.receiverIban = data.receiverIban
            info.receiverName = data.receiverName ? data.receiverName : ''
            info.receiverCurrency = data.receiverCurrency || data.senderCurrency

            if (data.receiverSwiftCode)
              info.receiverSwiftCode = data.receiverSwiftCode
            if (data.receiverBankName)
              info.receiverBankName = data.receiverBankName
            if (data.receiverBankCountry)
              info.country = data.receiverBankCountry
            if (data.intermediaryBankName)
              info.intermediaryBankName = data.intermediaryBankName
            if (data.intermediarySwiftCode)
              info.intermediarySwiftCode = data.intermediarySwiftCode
          }

          // budget
          if (data.taxPayerName) info.taxPayerName = data.taxPayerName
          if (data.taxPayerCode) info.taxPayerCode = data.taxPayerCode
          if (data.treasuryCode) info.treasuryCode = data.treasuryCode

          // RUB occupant side Have to delete
          if (data.receiverINN) info.receiverINN = data.receiverINN
          if (data.receiverKPP) info.receiverKPP = data.receiverKPP
          if (data.receiverAddress) info.receiverAddress = data.receiverAddress

          if (data.receiverBankAccount)
            info.receiverBankAccount = data.receiverBankAccount

          if (data.receiverBankAddress)
            info.receiverBankAddress = data.receiverBankAddress
          // RUB
          if (info.receiverCurrency === 'RUB' && !info.receiverKPP)
            info.rubleOutsideRussia = true

          templateApi.addTransferTemplateAjax(info, false).then(
            () => {
              this.setState({ errorSubmit: null, loadingSubmit: false }, () =>
                this.props.handleModalClose()
              )
              LbToast('success', i18n.t('CurrentTasks.TemplateAddSuccess'))
            },
            error => {
              this.setState({
                errorSubmit: error.errors[0],
                loadingSubmit: false
              })
            }
          )

          break
        case 'MobileTopup':
          // TODO: no info is comming in current task Details!
          info.photoAddress = this.state.imageId
          info.phoneNumber = data.identifier

          // info.providerId = data.providerId;
          // info.service = data.service;
          // info.isMyPhone = info.isMyPhone;

          templateApi
            .addMobileTemplateAjax(info, true)
            .then(() => {
              this.setState({ errorSubmit: null, loadingSubmit: false }, () =>
                this.props.handleModalClose()
              )
            })
            .catch(error => {
              this.setState({
                errorSubmit: error.errors[0],
                loadingSubmit: false
              })
            })
          break
      }
    }
  }

  cancelCropHandler = () => {
    this.setState({ cropImage: '' })
  }

  render() {
    let avatarImg = ''
    if (this.state.imageId)
      avatarImg = formatter.getImageUrl(this.state.imageId, 'template')
    else if (this.state.myImage) avatarImg = this.props.profileImageURL
    else if (this.state.defaultUserAvatar)
      avatarImg = this.state.defaultUserAvatar

    let defaultAvatar =
      this.props.currentTask.payType === 'InBudget' ? (
        <i
          id="i-avatar-SaveTemplateCorp-1"
          className="avatar-round-dark"
          onClick={this.uploadFile}
        >
          <Icon name="icn-budget3" view="0 0 62.646 53.764" />
        </i>
      ) : (
        <i
          id="i-avatar-SaveTemplateCorp-2"
          className="avatar-round"
          onClick={this.uploadFile}
        >
          <Icon name="icn-avatar" view="0 0 17.722 22.327" />
        </i>
      )

    return this.state.cropImage ? (
      <Fragment>
        {!this.state.errorResponseImgSave ? (
          <Fragment>
            <div className="cropping-image-body w-70 ml-auto mr-auto">
              <div className="cropping-image-out">
                <CropImage ref={this.CropImage} image={this.state.cropImage} />
              </div>
            </div>

            <div className="lb-popup-footer">
              <button
                id="button-closeModal-SaveTemplateCorp-1"
                className="lb-btn btn-border btn-white"
                onClick={this.cancelCropHandler}
              >
                <Trans i18nKey="Common.Cancel" />
              </button>
              <button
                id="button-saveButton-SaveTemplateCorp-2"
                className="lb-btn btn-red"
                onClick={this.imageSave}
              >
                <Trans i18nKey="Common.Save" />
              </button>
            </div>
          </Fragment>
        ) : (
          <div className="mh-20 text-center rows-wrap fnt-low-m fnt-14 is-warrning">
            <div className="record-notfound white-bg pt-30 pb-40 ph-45">
              <i className="icn icon-notfoundrecord mb-5">
                <Icon name="icn-warning-new2" view="0 0 26.864 23.74" />
              </i>
              <p>
                <Trans i18nKey="Templates.ErrorNotAvailable" />
              </p>
            </div>
          </div>
        )}

        {this.state.loadingImgSave && (
          <LbLoader isLoading inCenter size="small" />
        )}
      </Fragment>
    ) : (
      <Fragment>
        <div className="w-80 ml-auto mr-auto pt-25">
          <label htmlFor="template-name" className="lb-label fnt-low-m">
            <Trans i18nKey="Templates.TemplateName" />:
          </label>
          <Input
            id="templateName-SaveTemplateCorp"
            className="lb-input mt-10"
            type="text"
            placeholder=""
            maxLength={100}
            value={this.state.templateName}
            onChange={this.templateNameChanged}
          />

          <div
            className="lb-avatar-upload corp-avatar-upload"
            ref={this.setDragboxRef}
          >
            <div className={`${this.state.errorSubmit ? '' : 'mb-20'}`}>
              <span className="fnt-14 fnt-upper-m">
                <Trans i18nKey="Common.ProfilePicture" />
              </span>
              <span className="fnt-12 fnt-low tr-grey ml-5">
                (<Trans i18nKey="Common.Optional" />)
              </span>
            </div>
            {!this.state.errorSubmit && (
              <Fragment>
                <input
                  id="input-imageUploader-SaveTemplateCorp"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={this.imageUploaded}
                  accept=".jpg, .png, .jpeg, .bmp"
                  ref={this.setFileInputRef}
                />

                {this.state.validExt ? (
                  ''
                ) : (
                  <div className="error-msg is-red pb-25">
                    <Trans i18nKey="Common.ValidText" />
                  </div>
                )}

                <div className={this.state.className}>
                  {avatarImg ? (
                    <img
                      className="avatar-round img-border"
                      src={avatarImg}
                      alt=""
                    />
                  ) : (
                    defaultAvatar
                  )}
                </div>

                <p className="fnt-low-m">
                  <Trans i18nKey="Common.DragPicture" />
                </p>
                <p className="fnt-low-m">
                  <Trans i18nKey="Common.Or" />
                </p>
                <div className="avatar-upload-box">
                  <button
                    id="button-uploadFile-SaveTemplateCorp"
                    className="lb-btn btn-border btn-white"
                    onClick={this.uploadFile}
                  >
                    <Trans i18nKey="Common.UploadComputer" />
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </div>

        {this.state.errorSubmit && (
          <div className="mh-20 text-center rows-wrap fnt-low-m fnt-14 is-warrning">
            <div className="record-notfound white-bg pt-30 pb-40 ph-45">
              <i className="icn icon-notfoundrecord mb-5">
                <Icon name="icn-warning-new2" view="0 0 26.864 23.74" />
              </i>
              <p>{this.state.errorSubmit}</p>
            </div>
          </div>
        )}

        <div className="lb-popup-footer">
          <button
            id="button-closeModal-SaveTemplateCorp-1"
            className="lb-btn btn-border btn-white"
            onClick={this.props.handleModalClose}
          >
            <Trans i18nKey="Common.Cancel" />
          </button>
          <button
            id="button-saveButton-SaveTemplateCorp-2"
            className="lb-btn btn-red"
            onClick={this.saveTemplateHandler}
            disabled={!!this.state.errorSubmit || !this.state.templateName}
          >
            <Trans i18nKey="Common.Save" />
          </button>
        </div>

        {this.state.loadingSubmit && (
          <LbLoader isLoading inCenter size="small" />
        )}
      </Fragment>
    )
  }
}

export default connect(state => {
  const picture =
    state.oidc.user && state.oidc.user.profile.picture
      ? state.oidc.user.profile.picture
      : null
  return { profileImageURL: picture }
})(SaveTemplateCorp)
