import { remittanceProvidersConstants } from '../../constants/constants'

const initialState = {
  remittanceProviders: [],
  remittanceProvidersLoading: true,
  remittanceProvidersLoadingError: null
}
export default function remittanceReducer(state = initialState, action) {
  switch (action.type) {
    case remittanceProvidersConstants.FETCH_REMITTANCE_RECEIVE_PROVIDERS:
      return Object.assign({}, state, {
        remittanceProviders: [],
        remittanceProvidersLoading: true,
        remittanceProvidersLoadingError: null
      })
    case remittanceProvidersConstants.FETCH_REMITTANCE_RECEIVE_PROVIDERS_SUCCESS:
      return Object.assign({}, state, {
        remittanceProviders: action.payload,
        remittanceProvidersLoading: false,
        remittanceProvidersLoadingError: null
      })
    case remittanceProvidersConstants.FETCH_REMITTANCE_RECEIVE_PROVIDERS_ERROR:
      return Object.assign({}, state, {
        remittanceProvidersLoading: false,
        remittanceProvidersLoadingError: action.error
      })
    default:
      return state
  }
}
