import React, { Component } from 'react'
import { uuidv4 } from '../../helpers/uuidv4'

const BACKSPACE_KEY = 8
const LEFT_ARROW_KEY = 37
const UP_ARROW_KEY = 38
const RIGHT_ARROW_KEY = 39
const DOWN_ARROW_KEY = 40
const DELETE_KEY = 46

class ReactCodeInput extends Component {
  constructor(props) {
    super(props)

    const {
      value,
      fields,
      type,
      isValid,
      disabled,
      filterKeyCodes,
      className
    } = props

    this.state = {
      value,
      fields,
      type,
      input: [],
      lastClassName: 'lb-input sms-input',
      isValid,
      disabled,
      filterKeyCodes,
      defaultInputStyle: {
        fontFamily: 'monospace',
        MozAppearance: 'textfield',
        borderRadius: '6px',
        border: '1px solid',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
        margin: '4px',
        paddingLeft: '8px',
        width: '36px',
        height: '42px',
        fontSize: '32px',
        boxSizing: 'border-box'
      }
    }

    for (let i = 0; i < Number(this.state.fields); i += 1) {
      if (i < 32) {
        const value = this.state.value[i] || ''
        this.state.input.push(value)
      }
    }

    this.textInput = []

    this.uuid = uuidv4()
    this.focus = this.focus.bind(this)
  }

  componentDidMount() {
    let numberKeyCodeLeftKeyBoard = []
    let numberKeyCodeRightKeyBoard = []
    for (let i = 48; i <= 57; i++) {
      numberKeyCodeLeftKeyBoard.push(i)
      numberKeyCodeRightKeyBoard.push(i + 48)
    }
    // this.setState({ numberKeyCodeLeftKeyBoard: numberKeyCodeLeftKeyBoard });
    this.numberKeyCodeLeftKeyBoard = numberKeyCodeLeftKeyBoard
    this.numberKeyCodeRightKeyBoard = numberKeyCodeRightKeyBoard
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      isValid: nextProps.isValid,
      value: nextProps.value,
      disabled: nextProps.disabled,
      input: !nextProps.value ? Array(this.state.fields).fill('') : state.input
    }))

    if (!nextProps.value && nextProps.value != this.props.value) {
      this.textInput[0].focus()
      this.textInput[0].select()
    }
  }

  focus() {
    if (this.textInput && this.textInput[0]) {
      this.textInput[0].focus()
      this.textInput[0].select()
    }
  }

  handleBlur(e) {
    this.handleTouch(e.target.value)
  }

  handleTouch(value) {
    const { touch, untouch, name } = this.props

    if (typeof touch === 'function' && typeof untouch === 'function') {
      if (value === '') {
        touch(name)
      } else {
        untouch(name)
      }
    }
  }

  handleChange(e) {
    let value = String(e.target.value)
    if (this.state.type === 'number') {
      value = value.replace(/\D/g, '')
    }

    let fullValue = value

    if (value !== '') {
      const input = this.state.input.slice()

      if (value.length > 1) {
        value.split('').map((chart, i) => {
          if (Number(e.target.dataset.id) + i < this.props.fields) {
            input[Number(e.target.dataset.id) + i] = chart
          }
          return false
        })
      } else {
        input[Number(e.target.dataset.id)] = value
      }

      input.map((s, i) => {
        if (this.textInput[i]) {
          this.textInput[i].value = s
        }
        return false
      })

      const newTarget = this.textInput[
        e.target.dataset.id < input.length
          ? Number(e.target.dataset.id) + 1
          : e.target.dataset.id
      ]

      if (newTarget) {
        newTarget.focus()
        newTarget.select()
      }

      fullValue = input.join('')

      this.setState({ value: input.join(''), input }, () =>
        this.setLastInputClassname(this.props.fields - 1)
      )
      this.handleTouch(fullValue)
    }

    if (this.props.onChange && fullValue) {
      this.props.onChange(fullValue)
    }
  }

  handleKeyDown(e) {
    //49-57 ----------- left digital keyboard codes 0-9
    //96-105 ---------- right digital keyboard codes 0-9
    const target = Number(e.target.dataset.id),
      nextTarget = this.textInput[target + 1],
      prevTarget = this.textInput[target - 1]

    let input, value
    // console.log('------------------');
    // console.log('hadnleKeyDown', e);
    // console.log('handleKey', e.target.value);
    // console.log('keyCode', e.keyCode);
    // console.log('------------------');
    if (
      this.numberKeyCodeLeftKeyBoard.indexOf(e.keyCode) ===
        parseInt(e.target.value) ||
      this.numberKeyCodeRightKeyBoard.indexOf(e.keyCode) ===
        parseInt(e.target.value)
    ) {
      if (Number(e.target.dataset.id) + 1 < this.props.fields) {
        e.preventDefault()
        this.textInput[target + 1].focus()
        this.textInput[target + 1].select()
      }
      // return true;
    }

    if (this.state.filterKeyCodes.length > 0) {
      this.state.filterKeyCodes.map(item => {
        if (item === e.keyCode) {
          e.preventDefault()
          return true
        }
      })
    }

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault()
        input = this.state.input.slice()
        if (this.textInput[target].value !== '') {
          this.textInput[target].value = ''
          input[target] = ''
        } else {
          if (prevTarget) {
            prevTarget.value = ''
            input[prevTarget.dataset.id] = ''
            prevTarget.focus()
            prevTarget.select()
          }
        }
        value = input.join('')
        this.setState({ value, input })
        break
      case DELETE_KEY:
        e.preventDefault()
        this.textInput[target].value = ''
        input = this.state.input.slice()
        input[target] = ''
        value = input.join('')

        this.setState({ value, input })
        break

      case LEFT_ARROW_KEY:
        e.preventDefault()
        if (prevTarget) {
          prevTarget.focus()
          prevTarget.select()
        }
        break

      case RIGHT_ARROW_KEY:
        e.preventDefault()
        if (nextTarget) {
          nextTarget.focus()
          nextTarget.select()
        }
        break

      case UP_ARROW_KEY:
        e.preventDefault()
        break

      case DOWN_ARROW_KEY:
        e.preventDefault()
        break

      default:
        break
    }

    if (this.props.onChange && value) {
      this.props.onChange(value)
    }

    this.handleTouch(value)
  }

  setClassName = i => {
    let className = 'lb-input sms-input'
    if (this.state.input[i + 1] !== '') className += ' dot-input'
    if (!(this.state.input.length > i && this.state.input[i] !== ''))
      className += ' empty'
    return className
  }
  setLastInputClassname = i => {
    if (i === this.state.fields - 1 && this.state.input[i] !== '') {
      setTimeout(
        () => this.setState({ lastClassName: 'lb-input sms-input dot-input' }),
        1000
      )
    } else {
      this.setState({ lastClassName: 'lb-input sms-input' })
    }
  }
  render() {
    const {
        className,
        style = {},
        inputStyle = {},
        inputStyleInvalid = {},
        autoFocus
      } = this.props,
      { disabled, input, isValid, defaultInputStyle } = this.state,
      styles = {
        container: style,
        input: isValid ? inputStyle : inputStyleInvalid
      }

    Object.assign(styles.container, {
      display: 'inline-block'
    })

    if (!className && Object.keys(inputStyle).length === 0) {
      Object.assign(inputStyle, {
        ...defaultInputStyle,
        color: 'black',
        backgroundColor: 'white',
        borderColor: 'lightgrey'
      })
    }

    if (!className && Object.keys(inputStyleInvalid).length === 0) {
      Object.assign(inputStyleInvalid, {
        ...defaultInputStyle,
        color: '#b94a48',
        backgroundColor: '#f2dede',
        borderColor: '#eed3d7'
      })
    }

    if (disabled) {
      Object.assign(styles.input, {
        cursor: 'not-allowed',
        color: 'lightgrey',
        borderColor: 'lightgrey',
        backgroundColor: '#efeff1'
      })
    }
    return (
      <div className={`flex-end ${className ? className : ''}`}>
        {input.map((value, i) => {
          return (
            <input
              ref={ref => {
                this.textInput[i] = ref
              }}
              id={`input-${this.uuid}-${i}`}
              data-id={i}
              autoFocus={autoFocus && i === 0 ? 'autoFocus' : ''}
              defaultValue={value}
              key={`input_${i}`}
              type="text"
              min={0}
              max={9}
              maxLength={input.length === i + 1 ? 1 : input.length}
              autoComplete="off"
              onFocus={e => e.target.select(e)}
              onBlur={e => this.handleBlur(e)}
              onChange={e => this.handleChange(e)}
              onKeyDown={e => this.handleKeyDown(e)}
              disabled={disabled}
              data-valid={isValid}
              value={this.state.input.length > i ? this.state.input[i] : ''}
              className={
                i === this.state.fields - 1 && this.state.input[i] !== ''
                  ? this.state.lastClassName
                  : this.setClassName(i)
              }
            />
          )
        })}
      </div>
    )
  }
}

ReactCodeInput.defaultProps = {
  autoFocus: true,
  isValid: true,
  disabled: false,
  fields: 4,
  value: '',
  type: 'text',
  filterKeyCodes: [189, 190]
}

export default ReactCodeInput
