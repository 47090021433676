import { corporateConstants } from '../../constants/constants'
import { nullToArray, updateObject } from '../../helpers/util'

const initialState = {
  //current tasks
  currentTasks: [],
  currentTasksLoading: false,
  currentTasksLoadingError: null,

  currentTaskDetail: null,
  currentTaskDetailLoading: false,
  currentTaskDetailLoadingError: null,

  currentTaskModalId: null,

  loadMoreCurrentTasksLoading: false,
  currentTasksFilter: {
    skip: 0,
    take: 100,
    loadMoreCurrentTasks: false
  }
}

export default function corporateReducer(state = initialState, action) {
  switch (action.type) {
    case corporateConstants.FETCH_CURRENT_TASKS:
      return updateObject(state, {
        currentTasks: [],
        currentTasksLoading: true,
        currentTasksLoadingError: null,
        currentTasksFilter: updateObject(
          initialState.currentTasksFilter,
          updateObject({ loadMoreCurrentTasks: true }, action.payload)
        )
      })
    case corporateConstants.FETCH_CURRENT_TASKS_SUCCESS:
      return updateObject(state, {
        currentTasks: nullToArray(action.payload).slice(
          0,
          state.currentTasksFilter.take
        ),
        currentTasksLoading: false,
        currentTasksFilter: updateObject(state.currentTasksFilter, {
          loadMoreCurrentTasks:
            action.payload.length > state.currentTasksFilter.take
        })
      })
    case corporateConstants.FETCH_CURRENT_TASKS_ERROR:
      return updateObject(state, {
        currentTasksLoading: false,
        currentTasksLoadingError: action.error
      })
    case corporateConstants.FETCH_MORE_CURRENT_TASKS:
      return updateObject(state, {
        loadMoreCurrentTasksLoading: true,
        currentTasksFilter: updateObject(state.currentTasksFilter, {
          skip: state.currentTasks.length
        })
      })
    case corporateConstants.FETCH_MORE_CURRENT_TASKS_SUCCESS:
      return updateObject(state, {
        currentTasks: [
          ...state.currentTasks,
          ...action.payload.slice(0, state.currentTasksFilter.take)
        ],
        loadMoreCurrentTasksLoading: false,
        currentTasksFilter: updateObject(state.currentTasksFilter, {
          loadMoreCurrentTasks:
            action.payload.length > state.currentTasksFilter.take
        })
      })
    case corporateConstants.FETCH_MORE_CURRENT_TASKS_ERROR:
      return updateObject(state, {
        loadMoreCurrentTasksLoading: false,
        currentTasksLoadingError: action.error,
        currentTasksFilter: updateObject(state.currentTasksFilter, {
          loadMoreCurrentTasks: false
        })
      })

    case corporateConstants.CLEAR_CURRENT_TASKS_LIST:
      return updateObject(state, {
        currentTasks: [],
        currentTasksFilter: { ...initialState.currentTasksFilter }
      })

    case corporateConstants.FETCH_CURRENT_TASK_DETAIL:
      return updateObject(state, {
        currentTaskDetailLoading: true,
        currentTaskDetailLoadingError: null
      })
    case corporateConstants.SET_CURRENT_TASK_DETAIL_MODAL_ID:
      return updateObject(state, {
        currentTaskModalId: action.payload
      })

    case corporateConstants.FETCH_CURRENT_TASK_DETAIL_SUCCESS:
      return updateObject(state, {
        currentTaskDetail: action.payload,
        currentTaskDetailLoading: false,
        currentTaskDetailLoadingError: null
      })

    case corporateConstants.FETCH_CURRENT_TASK_DETAIL_ERROR:
      return updateObject(state, {
        currentTaskDetailLoading: false,
        currentTaskDetailLoadingError: action.error
      })
    default:
      return state
  }
}
