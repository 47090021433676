import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

export const fetchPhoneMetaDataAjax = () => {
  return jsonRequest(`${baseUrl}/identity/v1/helper/phone-meta-data`, false)
}

export const fetchCheckUsernameAjax = username => {
  return jsonRequest(
    `${baseUrl}/identity/v1/manage/check-username`,
    true,
    { username: username },
    'POST',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}
