import React, { useState } from 'react'
import classNames from 'classnames/bind'
import styles from '../../../assets/scss/component/input.scss'
import ReactHtmlParser from 'react-html-parser'

import { LbLoader } from './LBLoader'
import Icon from './Icon'
import ReactTooltip from 'react-tooltip'

let style = classNames.bind(styles)

// use if input must change its background

const Input = props => {
  const [touched, setTouched] = useState(false)
  let {
    id,
    value,
    name,
    type,
    disabled,
    maxLength,
    placeholder,
    onFocus,
    hint,
    loading,
    checkUserAvatar,
    userAvatarHandler,
    resetDefaultUserAvatar,
    enableEditingIfBadRegex,
    regex,
    converter,
    upperCase,
    errorMsg,
    onClick,
    detectTouch,
    min,
    max,
    info
  } = props
  const error = detectTouch ? props.error && touched : props.error
  const onBlur = e => {
    setTouched(true)
    // setIsFilled(!!e.target.value.length)
    if (checkUserAvatar && e.target.value.length > 5) {
      userAvatarHandler(e)
    } else {
      if (resetDefaultUserAvatar) resetDefaultUserAvatar()
    }
    if (props.onBlur) {
      props.onBlur(e)
    }
  }
  const handleOnFocus = e => {
    if (onFocus) onFocus(e)
  }
  const isSubSequence = (oldS, newS) => {
    if (!newS) return true
    if (!oldS || oldS.length !== newS.length + 1) return false
    for (let i = 0; i < oldS.length; i++) {
      if (newS.slice(0, i) + oldS[i] + newS.slice(i) === oldS) return true
    }
    return false
  }

  const toUpperCase = val => {
    let res = ''
    for (let c of val) {
      if (c >= 'ა' && c <= 'ჰ') res += c
      else res += c.toUpperCase()
    }
    return res
  }
  const handleOnClick = () => {
    if (onClick) onClick()
  }
  const onChange = e => {
    setTouched(true)
    if (!enableEditingIfBadRegex) {
      if (
        regex &&
        !(
          isSubSequence(value, e.target.value) ||
          new RegExp(regex).test(e.target.value)
        )
      )
        return
    }
    //if supplied max props for threshold
    if (max && e.target.value > max) return
    // cursor position
    let cursorStart = e.target.selectionStart
    let cursorEnd = e.target.selectionEnd
    // if text transformed
    let changed = false
    // has supplied converter function in property
    if (converter) {
      e.target.value = converter(e.target.value)
      changed = true
    }
    // has supplied uppercase property
    if (upperCase) {
      e.target.value = toUpperCase(e.target.value) // .toUpperCase();
      changed = true
    }
    // props onChange
    if (props.onChange) props.onChange(e)
    if (changed) {
      e.target.setSelectionRange(cursorStart, cursorEnd)
    }
  }

  let symbol
  if (props.symbol === 'USD') {
    symbol = '$'
  } else if (props.symbol === 'EUR') {
    symbol = '€'
  } else if (props.symbol === 'GEL') {
    symbol = '₾'
  } else {
    symbol = props.symbol
  }

  let wrapErrorClass = error && errorMsg ? ' error' : ''
  let inputErrorClass = error && (errorMsg || hint) ? ' has-error ' : ' '

  let val = value === null ? '' : value
  let className = style({
    filled: !!val
  })
  return (
    <div
      className={`${
        props.wrapClassName ? props.wrapClassName : ''
      } ${wrapErrorClass}`}
    >
      <div className="lb-input-out">
        <input
          style={upperCase ? { textTransform: 'uppercase' } : {}}
          id={`input-${id}`}
          className={props.className + inputErrorClass + (className || '')}
          name={name}
          value={val}
          onChange={onChange}
          type={type}
          disabled={disabled}
          onBlur={onBlur}
          maxLength={maxLength}
          placeholder={placeholder}
          onFocus={e => handleOnFocus(e)}
          autoComplete={'new-password'}
          onClick={handleOnClick}
        />
        {props.symbol ? <i className="amount-symbol">{symbol}</i> : ''}
        {info ? (
          <>
            <i
              className="icn icon-info"
              id={`tooltip-${id}`}
              data-tip={true}
              data-for={`div-tip-info-${id}`}
            >
              <Icon name="information" viewBox="0 0 16 16" />
            </i>
            <ReactTooltip
              id={`div-tip-info-${id}`}
              className="lb-tooltip type-darker"
              place="right"
              type="dark"
              effect="solid"
            >
              <span className="lb-tooltip-text">{ReactHtmlParser(info)}</span>
            </ReactTooltip>
          </>
        ) : (
          ''
        )}
      </div>
      {error ? <div className="lb-input-info is-red">{errorMsg}</div> : ''}
      {hint && !errorMsg ? (
        <div className={error ? 'lb-input-info is-red' : 'lb-input-info'}>
          {hint}
        </div>
      ) : (
        ''
      )}

      {loading && <LbLoader isLoading size="xsmall" />}
    </div>
  )
}

export default Input
