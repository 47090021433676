export const config = {
  firstName: {
    transKey: 'FirstName',
    inTooltip: true,
    visible: true
  },
  lastName: {
    transKey: 'LastName',
    inTooltip: true,
    visible: true
  },
  iban: {
    transKey: 'iban',
    iban: true,
    inTooltip: true,
    visible: true
  },

  // netAmount: {
  //   transKey: 'NetAmount',
  //   inTooltip: true,
  //   visible: true
  // },
  position: {
    transKey: 'position',
    inTooltip: true,
    visible: true
  },
  // status: {
  //   transKey: 'status',
  //   inTooltip: true,
  //   visible: true
  // },
  personalNumber: {
    transKey: 'personalNumber',
    inTooltip: true,
    visible: false
  },
  regDate: {
    transKey: 'RegDate',
    date: true,
    inTooltip: true,
    visible: false
  },
  pensionTurnedOn: {
    transKey: 'pensionTurnedOn',
    inTooltip: true,
    visible: true
  },
  phoneNumber: {
    transKey: 'phoneNumber',
    inTooltip: true,
    visible: false
  }
}

export default [
  {
    cellKey: 'firstName',
    visible: true
  },
  {
    cellKey: 'lastName',
    visible: true
  },
  {
    cellKey: 'iban',
    visible: true
  },
  {
    cellKey: 'position',
    visible: false
  },

  {
    cellKey: 'personalNumber',
    visible: false
  },
  {
    cellKey: 'regDate',
    visible: false
  },
  {
    cellKey: 'pensionTurnedOn',
    visible: false
  },
  {
    cellKey: 'phoneNumber',
    visible: false
  }
]
