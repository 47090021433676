import { createSelector } from 'reselect'

const userSelect = state => state.oidc

export const reselectMyCreditUserProfile = createSelector(
  userSelect,
  ({ user: { profile } }) => {
    let newProfile = {
      first_name_ka: profile['first_name_ka'],
      last_name_ka: profile['last_name_ka'],
      first_name_en: profile['first_name_en'],
      last_name_en: profile['last_name_en'],
      personal_number: profile.personal_number,
      phone_number: profile.phone_number,
      birthdate: profile.birthdate,
      address: profile.address,
      email: profile.email
    }

    return {
      profile: newProfile
    }
  }
)

export const reselectPhoneVerified = createSelector(
  userSelect,
  ({ user: { profile } }) => ({
    phoneNumberVerified: profile.phone_number_verified
  })
)

export const reselectUserProfile = createSelector(
  userSelect,
  ({ user: { profile } }) => {
    let newProfile = {
      first_name_ka: profile['first_name_ka'],
      last_name_ka: profile['last_name_ka'],
      first_name_en: profile['first_name_en'],
      last_name_en: profile['last_name_en'],
      personal_number: profile.personal_number,
      phone_number: profile.phone_number,
      address: profile.address,
      email: profile.email,
      sub: profile.sub
    }

    return {
      profile: newProfile
    }
  }
)

export const reselectUser = createSelector(userSelect, ({ user }) => user)

export const reselectIsSocialUser = createSelector(
  userSelect,
  ({ user: { profile } }) => profile && profile.is_social === 'True'
)
