import React from 'react'
import { toast } from 'react-toastify'
import Icon from './Icon'

export const LbToast = (type, content, options) => {
  if (type === 'dismiss') return toast.dismiss()
  let icon
  switch (type) {
    case 'error':
      icon = (
        <i className="icn icn-status">
          <Icon name="icn-circle-close" view="0 0 20 20" />
        </i>
      )
      break
    case 'warning':
      icon = (
        <i className="icn icn-status">
          <Icon name="icn-warr-notif" viewBox="0 0 37.238 34.421" />
        </i>
      )
      break
    default:
      icon = (
        <i className="icn icn-status">
          <Icon name="icn-circle-tick" view="0 0 17.5 17.5" />
        </i>
      )
  }
  let component = (
    <div id="global-notification" className="global-notification">
      <div className="flex-aligned">
        {icon}
        <div className="notif-text flex-1">{content}</div>
      </div>
    </div>
  )
  return toast(component, {
    type: type,
    closeButton: ['error', 'success'].includes(type),
    position: 'bottom-right',
    draggable: false,
    pauseOnHover: false,
    hideProgressBar: type !== 'warning',
    ...options
  })
}
