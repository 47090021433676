import {
  USER_EXPIRED,
  USER_EXPIRING,
  USER_FOUND,
  USER_LOADED,
  USER_SIGNED_OUT
} from 'redux-oidc'
import { userConstants } from '../../constants/constants'
import { updateObject } from '../../helpers/util'

export default function reduce(state, action) {
  switch (action.type) {
    case USER_EXPIRED:
    case USER_SIGNED_OUT:
      return updateObject(state, {
        expireStatus: userConstants.USER_HAS_EXPIRED
      })
    case USER_LOADED:
    case USER_FOUND:
      action.payload.profile.isCorporate =
        action.payload.profile.type === 'Corporate'
      return updateObject(state, { expireStatus: userConstants.USER_IS_ACTIVE })
    case USER_EXPIRING:
      return updateObject(state, {
        expireStatus: userConstants.USER_IS_EXPIRING
      })
    case userConstants.UPDATE_USER_PROFILE:
      state.user.profile = updateObject(
        state.user.profile,
        updateObject(action.payload, {
          isCorporate: action.payload.type === 'Corporate'
        })
      )
      return state
    default:
      return state
  }
}
