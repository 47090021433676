import React from 'react'

export default function SwitchRenderer({ children, renderKey }) {
  return children.map(child => {
    if (child.props.renderValue === renderKey) {
      return child
    }
    if (
      child.props.renderValues &&
      child.props.renderValues.includes(renderKey)
    ) {
      return child
    }
    return null
  })
}
