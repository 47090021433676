import React from 'react'

const SvgIconsRetail = () => (
  <svg
    style={{ width: 0, height: 0, position: 'absolute', zIndex: -1 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <symbol id="icn-avatar" viewBox="0 0 17.722 22.327" fill="currentColor">
      <g transform="translate(-21.445 -19.345)">
        <path
          className="a"
          d="M23.133,53H19.589A7.088,7.088,0,0,0,12.5,60.088v2.126c0,.391.027.695.419.695s.34-.3.34-.695V60.088c-.085-3.242,3.166-6.233,6.329-6.329h3.544a6.617,6.617,0,0,1,6.329,6.329v2.127c0,.391-.041.731.351.731s.409-.339.409-.731V60.088A7.088,7.088,0,0,0,23.133,53Z"
          transform="translate(8.945 -21.274)"
          fill="currentColor"
        />
        <g
          className="b"
          transform="translate(24.507 19.345)"
          stroke="#db0011"
          fill="none"
        >
          <ellipse
            className="c"
            cx="5.268"
            cy="5.268"
            rx="5.268"
            ry="5.268"
            stroke="none"
          />
          <ellipse
            className="d"
            cx="5.268"
            cy="5.268"
            rx="4.768"
            ry="4.768"
            fill="none"
          />
        </g>
      </g>
    </symbol>
    <symbol id="icn-phone-new" viewBox="0 0 19.904 35.385">
      <g transform="translate(.001)">
        <path
          d="M17.139 0H2.764A2.764 2.764 0 0 0 0 2.764V32.62a2.764 2.764 0 0 0 2.764 2.764h14.375A2.764 2.764 0 0 0 19.9 32.62V2.764A2.764 2.764 0 0 0 17.139 0zM18.8 32.62a1.659 1.659 0 0 1-1.659 1.659H2.764a1.659 1.659 0 0 1-1.659-1.659v-1.659H18.8zm0-2.764H1.106V5.529H18.8zm0-25.433H1.106V2.764a1.659 1.659 0 0 1 1.659-1.659h14.374A1.659 1.659 0 0 1 18.8 2.764z"
          fill="currentColor"
        />
        <path
          className="b"
          fill="none"
          stroke="#db0011"
          strokeLinecap="round"
          strokeWidth="1.5px"
          d="M4.051 26.329l6.955-6.955M9.952 13.819l5.477-5.5M6.635 17.133l1.648-1.655M12.717 17.686l1.648-1.655"
        />
        <path
          d="M7.95 2.49l3.228-.006"
          fill="none"
          stroke="#db0011"
          strokeLinecap="round"
        />
        <circle
          cx="1.106"
          cy="1.106"
          r="1.106"
          transform="translate(8.846 31.514)"
          fill="#db0011"
        />
      </g>
    </symbol>
    <symbol id="icn-phone-new" viewBox="0 0 21.144 21.144">
      <g className="a" transform="translate(0 -5.999)">
        <g transform="translate(0 5.999)">
          <path
            className="b"
            fill="#DB0011"
            d="M17.441,3.881c5.173,0,7.489,3.761,7.489,7.489a.441.441,0,0,0,.881,0C25.81,7.2,23.223,3,17.441,3a.441.441,0,0,0,0,.881Z"
            transform="translate(-4.666 -3)"
          />
          <path
            className="b"
            fill="#DB0011"
            d="M17.441,8.881a2.764,2.764,0,0,1,3.084,3.084.441.441,0,0,0,.881,0A3.609,3.609,0,0,0,17.441,8a.441.441,0,0,0,0,.881Z"
            transform="translate(-4.666 -3.595)"
          />
          <path
            className="c"
            fill="currentColor"
            d="M22.6,17.1a15.441,15.441,0,0,1-4.239-.8,1.5,1.5,0,0,0-1.522.322l-2.424,1.724a13.394,13.394,0,0,1-6.035-6.014l2.09-2.167a1.553,1.553,0,0,0,.382-1.573,13.54,13.54,0,0,1-.806-4.046A1.543,1.543,0,0,0,8.506,3H4.542A1.543,1.543,0,0,0,3,4.542a19.865,19.865,0,0,0,19.6,19.6A1.543,1.543,0,0,0,24.144,22.6V18.638A1.543,1.543,0,0,0,22.6,17.1Zm.661,5.506a.662.662,0,0,1-.661.661A18.972,18.972,0,0,1,3.881,4.542a.662.662,0,0,1,.661-.661H8.505a.662.662,0,0,1,.661.661,14.353,14.353,0,0,0,.849,4.317.666.666,0,0,1-.173.685l-2.315,2.4a.44.44,0,0,0-.075.506,14.337,14.337,0,0,0,6.8,6.8.439.439,0,0,0,.456-.033l2.7-1.928a.659.659,0,0,1,.674-.158,16.09,16.09,0,0,0,4.521.846.662.662,0,0,1,.661.661S23.263,22.6,23.263,22.6Z"
            transform="translate(-3 -3)"
          />
        </g>
      </g>
    </symbol>

    <symbol id="icn-home-new" viewBox="0 0 39.205 35.419" fill="currentColor">
      <path d="M39.177 14.278a2.265 2.265 0 0 0-.967-1.52L20.984.458a2.482 2.482 0 0 0-1.382-.461 2.174 2.174 0 0 0-1.382.461l-3.362 2.4v-.691A1.848 1.848 0 0 0 13.016.325H9.838a1.848 1.848 0 0 0-1.842 1.842V7.74l-7 5.02a2.374 2.374 0 0 0-.967 1.52 2.336 2.336 0 0 0 .415 1.75 2.465 2.465 0 0 0 1.934 1.013 2.174 2.174 0 0 0 1.382-.461l.138-.092v18.1a.97.97 0 0 0 .23.6.876.876 0 0 0 .6.23h29.8a.97.97 0 0 0 .6-.23.876.876 0 0 0 .23-.6v-18.1l.138.092a2.482 2.482 0 0 0 1.382.461 2.34 2.34 0 0 0 1.934-1.013 2.591 2.591 0 0 0 .365-1.752zM9.654 2.165a.218.218 0 0 1 .23-.23h3.176a.218.218 0 0 1 .23.23v1.842L9.7 6.586V2.165zM23.06 33.807h-6.912V23.029a.218.218 0 0 1 .23-.23h6.448a.218.218 0 0 1 .23.23zm10.639 0h-9.03V23.029a1.848 1.848 0 0 0-1.842-1.842h-6.449a1.848 1.848 0 0 0-1.842 1.842v10.778H5.509v-18.47L19.602 5.3l14.094 10.037zm3.731-18.7a.787.787 0 0 1-.6.322.743.743 0 0 1-.415-.138L19.602 3.316 2.791 15.291a.743.743 0 0 1-.415.138.671.671 0 0 1-.6-.322.736.736 0 0 1 .184-1.013L19.188 1.842a.691.691 0 0 1 .829 0l17.226 12.206a.793.793 0 0 1 .184 1.059z" />
      <g
        stroke="#db0011"
        strokeWidth="2px"
        fill="none"
        transform="translate(16.237 10.733)"
      >
        <circle fill="none" stroke="none" cx="3.179" cy="3.179" r="3.179" />
        <circle fill="none" cx="3.179" cy="3.179" r="2.179" />
      </g>
      <g fill="none" transform="translate(17.11 27.642)">
        <circle stroke="none" fill="#db0011" cx="1.194" cy="1.194" r="1.194" />
        <circle
          stroke="#db0011"
          fill="#db0011"
          cx="1.194"
          cy="1.194"
          r=".194"
        />
      </g>
    </symbol>

    <symbol
      id="icn-arrows-new"
      viewBox="0 0 34.227 29.049"
      fill="none"
      strokeLinecap="round"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path stroke="#db0011" d="M16.42 7.395l15.573-.356" />
      <path d="M17.806 21.654l-15.573.356" />
      <path stroke="#db0011" d="M26.732 1l6.074 6.169-6.074 5.88" />
      <path d="M7.494 28.049L1.42 21.88 7.494 16" />
      <path strokeWidth="1px" d="M12.033 14.317h5.383M20.033 14.317h2.863" />
    </symbol>
    <symbol id="quick-add-new" viewBox="0 0 18.775 18.775" fill="currentColor">
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M8.214,0a.587.587,0,0,0-.587.587V2.668a6.994,6.994,0,0,0-1.744.727L4.41,1.921a.587.587,0,0,0-.83,0L1.921,3.58a.587.587,0,0,0,0,.83L3.39,5.879a6.992,6.992,0,0,0-.722,1.749H.587A.587.587,0,0,0,0,8.214v2.347a.587.587,0,0,0,.587.587H2.666a7,7,0,0,0,.727,1.747L1.921,14.366a.587.587,0,0,0,0,.83L3.58,16.855a.587.587,0,0,0,.83,0l1.467-1.467a7,7,0,0,0,1.751.722v2.079a.587.587,0,0,0,.587.587h2.347a.587.587,0,0,0,.587-.587V16.11c.171-.046.6-.209.6-.209l0-1.144a10.91,10.91,0,0,1-1.288.4.587.587,0,0,0-.479.575V17.6H8.8V15.73a.587.587,0,0,0-.481-.575,5.867,5.867,0,0,1-2.258-.933.586.586,0,0,0-.747.069l-1.32,1.32-.83-.83L4.49,13.456a.587.587,0,0,0,.069-.747,5.87,5.87,0,0,1-.937-2.255.587.587,0,0,0-.578-.479H1.173V8.8H3.046a.587.587,0,0,0,.578-.479,5.872,5.872,0,0,1,.931-2.26.587.587,0,0,0-.069-.747l-1.32-1.32.83-.83L5.32,4.49a.587.587,0,0,0,.747.069,5.87,5.87,0,0,1,2.255-.937A.587.587,0,0,0,8.8,3.044V1.173H9.975V3.046a.587.587,0,0,0,.481.578,5.866,5.866,0,0,1,2.258.933.587.587,0,0,0,.747-.069l1.32-1.32.83.83L14.286,5.322a.587.587,0,0,0-.069.749,5.862,5.862,0,0,1,.938,2.253.587.587,0,0,0,.578.479H17.6V9.977H15.73a.587.587,0,0,0-.578.481c-.035.19-.193.667-.193.667l1.057.319s.068-.193.094-.293h2.079a.587.587,0,0,0,.587-.587V8.217a.587.587,0,0,0-.587-.587H16.11a7,7,0,0,0-.727-1.746l1.471-1.471a.587.587,0,0,0,0-.83L15.2,1.923a.587.587,0,0,0-.83,0L12.9,3.392a7,7,0,0,0-1.749-.722V.589A.587.587,0,0,0,10.561,0ZM9.388,4.694a4.694,4.694,0,1,0,4.694,4.694A4.7,4.7,0,0,0,9.388,4.694Zm0,1.173a3.52,3.52,0,1,1-3.52,3.52A3.512,3.512,0,0,1,9.388,5.867Z"
        />
        <path
          className="b"
          d="M16.479,13.334v4.673"
          transform="translate(-1.211 -0.98)"
          stroke="#db0011"
          fill="none"
        />
        <path
          className="b"
          d="M13.957,15.856h4.69"
          transform="translate(-1.026 -1.166)"
          stroke="#db0011"
          fill="none"
        />
      </g>
    </symbol>
    <symbol id="icn-warning-red" viewBox="0 0 2 15.726" fill="currentColor">
      <g transform="translate(-1704.5 -128.5)">
        <path
          d="M1705.566,128.849v8.92"
          transform="translate(-0.066 0.651)"
          fill="none"
          stroke="#242424"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          d="M1705.566,142.5v.726"
          transform="translate(-0.066 0.002)"
          fill="none"
          stroke="#db0011"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </symbol>
  </svg>
)

export default SvgIconsRetail
