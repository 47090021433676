import React, { useEffect, useRef, useState } from 'react'
import userManager, { userManagerConfig } from '../../helpers/userManager'
import LbModal from '../../common/Modal/LbModal'
import { withRouter } from 'react-router-dom'
import i18n from '../../../i18n'

const InactivityTimer = ({ history }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalCountdown, setModalCountdown] = useState(
    userManagerConfig.accessTokenExpiringNotificationTime
  ) // Default to 1 minute countdown
  const lastActivityTime = useRef(Date.now())
  const checkInactivityInterval = useRef(null)

  const handleUserActivity = () => {
    lastActivityTime.current = Date.now()
    if (showModal) {
      setShowModal(false)
      setModalCountdown(userManagerConfig.accessTokenExpiringNotificationTime) // Reset modal countdown to 1 minute
    }
  }

  const calculateTimeLeft = () => {
    const currentTime = Date.now()
    const elapsedTime = (currentTime - lastActivityTime.current) / 1000
    const timeLeft =
      userManagerConfig.accessTokenExpiringNotificationTime +
      userManagerConfig.inactiveTimeSeconds -
      elapsedTime // Total of 5 minutes (300 seconds)

    if (
      timeLeft <= userManagerConfig.accessTokenExpiringNotificationTime &&
      timeLeft > 0
    ) {
      setModalCountdown(Math.ceil(timeLeft))
    } else if (timeLeft <= 0) {
      clearInterval(checkInactivityInterval.current)
      userManager.object.removeUser()
      history.replace('/inactive_signout')
    }
  }

  useEffect(() => {
    checkInactivityInterval.current = setInterval(() => {
      const currentTime = Date.now()
      const elapsedTime = (currentTime - lastActivityTime.current) / 1000

      if (
        elapsedTime >= userManagerConfig.inactiveTimeSeconds &&
        elapsedTime <
          userManagerConfig.inactiveTimeSeconds +
            userManagerConfig.accessTokenExpiringNotificationTime
      ) {
        setShowModal(true)
        calculateTimeLeft()
      } else if (
        elapsedTime >=
        userManagerConfig.inactiveTimeSeconds +
          userManagerConfig.accessTokenExpiringNotificationTime
      ) {
        clearInterval(checkInactivityInterval.current)
        userManager.object.removeUser()
        history.replace('/inactive_signout')
      }
    }, 1000)

    const activityEvents = ['click', 'mousemove', 'keypress']
    activityEvents.forEach(event =>
      document.addEventListener(event, handleUserActivity)
    )

    return () => {
      clearInterval(checkInactivityInterval.current)
      activityEvents.forEach(event =>
        document.removeEventListener(event, handleUserActivity)
      )
    }
  }, [showModal])

  useEffect(() => {
    if (showModal) {
      const modalCountdownInterval = setInterval(() => {
        calculateTimeLeft()
      }, 1000)

      return () => clearInterval(modalCountdownInterval)
    }
  }, [showModal])

  return (
    <>
      {showModal && (
        <LbModal
          shouldCloseOnEsc={false}
          isOpen={true}
          header={i18n.t('Common.Message')}
          className="mw-600"
          blockBodyClass="lb-block-body"
        >
          <div className="lb-confirm-text fnt-low-m pb-50">
            <p>{i18n.t('Common.SessionExpiringStay')}</p>
            <p>
              {i18n.t('Common.RemainingTimeIs')} : <b>{modalCountdown}</b>{' '}
              {i18n.t('Common.Seconds', { count: modalCountdown })}
            </p>
          </div>
        </LbModal>
      )}
    </>
  )
}

export default withRouter(InactivityTimer)
