import React, { useState, useEffect, useCallback } from 'react'
import LbModal from './LbModal'
import i18n from '../../../i18n'
import { Trans } from 'react-i18next'
import {
  fetchShowSkippingOfferAjax,
  updateLoanSkippingResponseAjax
} from '../../api/loan.api'

const loansDelayMessageDialog = ({ pathName }) => {
  const [showMessageDialog, setShowMessageDialog] = useState(null)

  const fetchData = useCallback(async () => {
    let showMessage = await fetchShowSkippingOfferAjax()
    setShowMessageDialog(showMessage)
  }, [])

  useEffect(() => {
    if (pathName === '/' && showMessageDialog === null) {
      fetchData()
    }
  }, [pathName])

  const buttonClickHandler = async type => {
    setShowMessageDialog(false)
    await updateLoanSkippingResponseAjax(type)
  }

  return showMessageDialog ? (
    <LbModal
      isOpen={true}
      shouldCloseOnOverlayClick={false}
      className="mw-750 important-message"
      header={i18n.t('Common.ImportantMessage')}
      hideCloseTopButton={true}
      shouldCloseOnEsc={false}
    >
      <div className="lb-confirm-text">
        <div className="notification-date-block pb-10">
          <div className="notification-date pb-10">
            {i18n.t('LoansDelayMessage.Title')}
          </div>
          <div className="bb" />
        </div>
        <div
          className="fnt-14 fnt-low ovf-wrap pr-20 pt-10"
          dangerouslySetInnerHTML={{ __html: i18n.t('LoansDelayMessage.Text') }}
        />
      </div>
      <div className="lb-popup-footer small-btn-group flex-end">
        <button
          onClick={() => buttonClickHandler(2)}
          className="lb-btn btn-border  btn-white "
        >
          <Trans i18nKey="LoansDelayMessage.NotDecided" />
        </button>
        <button
          onClick={() => buttonClickHandler(0)}
          className="lb-btn btn-border btn-white "
        >
          <Trans i18nKey="LoansDelayMessage.DontWant" />
        </button>
        <button
          onClick={() => buttonClickHandler(1)}
          className="lb-btn btn-border btn-red "
        >
          <Trans i18nKey="LoansDelayMessage.Want" />
        </button>
      </div>
    </LbModal>
  ) : null
}

export default loansDelayMessageDialog
