import moment from 'moment'

export let diffFromServerTime = 0

export const setDiffFromServerTime = serverTime => {
  diffFromServerTime = serverTime
    ? moment(serverTime)
        .parseZone()
        .utcOffset() * 60
    : 0
}

export const unixEndDateWithTimestamp = endDate => {
  let date = (endDate && moment(endDate.format('Y-MM-DD 23:59:59'))) || moment()
  return date.unix() + diffFromServerTime
  // unix returns time without
  //time zone (+4) utcOffset returns minutes and multiply it 60 because of we need seconds
}
