import { baseUrl, staticContentBaseUrl } from './baseUrl'

function getFormattedIBAN(maskedPan) {
  if (!maskedPan) return ''
  //let result = maskedPan.slice(0, 4) + ' ' + maskedPan.slice(4, 6) + '** **** ' + maskedPan.substr(maskedPan.length - 4);
  let result = '**** **** **** ' + maskedPan.substr(maskedPan.length - 4)
  return result
}

function getFormattedIBANInTransfers(maskedPan) {
  if (!maskedPan) return ''
  //let result = maskedPan.slice(0, 4) + ' ' + maskedPan.slice(4, 6) + '** **** ' + maskedPan.substr(maskedPan.length - 4);
  let result =
    maskedPan.substr(0, 4) +
    ' **** **** ' +
    maskedPan.substr(maskedPan.length - 4)
  return result
}

function getFormattedIBANInTransactionList(maskedPan) {
  if (!maskedPan) return ''
  let result = `${maskedPan.substr(0, 4)}....${maskedPan.substr(
    maskedPan.length - 4
  )}`
  return result
}

export function getImageUrl(imageId, type = 'cards') {
  if (!imageId) {
    return
  }
  return type === 'template'
    ? `${baseUrl}/storage/v1/images?key=${imageId}`
    : `${staticContentBaseUrl}/cards/${imageId}`
}

function getProviderImageUrl(providerImageUrl) {
  return providerImageUrl
  // if (!providerId) return null;
  // if (String(providerId).includes('http')) {
  //   return providerId;
  // } else {
  //   return `http://minio-test.lb.ge/utilitybill/providersIb/${providerId}.png`;
  // }
}

function getCategoryProviderImageUrl(providerId) {
  if (!providerId) return null
  return providerId
}

export function getCurrencySign(currency) {
  currency = currency ? currency.toUpperCase() : currency
  const currencySigns = {
    GEL: '₾',
    EUR: '€',
    USD: '$',
    GBP: '£',
    RUB: '₽',
    JPY: 'JP¥',
    AMD: '֏',
    AZM: '₼',
    UAH: '₴',
    TRL: '₺',
    CHF: '₣',
    CAD: 'C$',
    AUD: 'A$',
    DKK: 'Kr.',
    HKD: 'HK$',
    KZT: '₸',
    SEK: 'Kr',
    CNY: 'CN¥',
    AED: 'د.إ'
  }
  return currencySigns[currency] ? currencySigns[currency] : currency
}

export function getCurrencyName(currency) {
  return `CurrencyName.${currency}`
}
export function getFormattedCardPan(maskedPan) {
  if (!maskedPan) return ''
  let result = `**** **** **** ${maskedPan.substr(-4)}`
  return result
}

export function getFormattedCardPanShort(maskedPan) {
  if (!maskedPan) return ''
  let result = `****${maskedPan.substr(-4)}`
  return result
}

// format georgian phone numbers with spaces
export const getFormattedPhoneNumber = phoneNumber => {
  return phoneNumber.replace(
    /(\d{3})(\d{3})(\d{2})(\d{2})(\d{2})/,
    '$1 $2 $3 $4 $5'
  )
}

export default {
  getCurrencySign,
  getProviderImageUrl,
  getImageUrl,
  getCategoryProviderImageUrl,
  getFormattedIBANInTransactionList,
  getFormattedIBANInTransfers,
  getFormattedIBAN,
  getFormattedCardPan
}
