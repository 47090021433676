import React from 'react'
import { Trans } from 'react-i18next'
import logApplicationError from '../../api/error-logger.api'
import Icon from '../SimpleComponents/Icon'
import ReactGA from 'react-ga'
import config from '../../constants/config'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.fireTracking = this.fireTracking.bind(this)
  }

  fireTracking(location) {
    if (config.serverConfig.server === 'production')
      ReactGA.pageview(location.pathname + location.search)
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.fireTracking(location)
      if (this.state.hasError) this.setState(() => ({ hasError: false }))
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  componentDidCatch(error, info) {
    this.setState(() => ({ hasError: true }))
    const {
      history: {
        location: { pathname: path }
      }
    } = this.props

    logApplicationError(
      JSON.stringify({
        path: path,
        error: error.toString(),
        info: JSON.stringify(info)
      })
    ).then(x => {})
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container">
          <div className="lb-block finalize-block correct-message  mt-50">
            <div className="">
              <i className="icn cash-error">
                <Icon name="cash-error" view="0 0 53.843 53.844" />
              </i>
              <div className="fnt-16 fnt-upper-b pt-10">
                <Trans i18nKey="Common.ErrorOccurred"></Trans>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
