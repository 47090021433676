import { ToastContainer } from 'react-toastify'
import React, { Component } from 'react'

export default class ToastNotificationGlobal extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <ToastContainer
        position="bottom-right"
        hideProgressBar={false}
        autoClose={5000}
        newestOnTop
        pauseOnFocusLoss={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
        closeButton={false}
      />
    )
  }
}
