import { transactionConstants } from '../../constants/constants'
import { nullToArray, updateObject } from '../../helpers/util'

const initialState = {
  // statements
  statements: {},
  statementsLoading: false,
  statementsLoadingError: null,
  loadMoreStatementsLoading: false,
  statementsFilter: {
    take: 30,
    loadMoreStatements: false
  },

  //blockedAmounts
  blockedAmounts: [],
  blockedAmountsLoading: false,
  blockedAmountsLoadingError: null,

  //other blockedAmounts
  otherBlockedAmounts: [],
  otherBlockedAmountsLoading: false,
  otherBlockedAmountsLoadingError: null,

  // transactions history
  transactions: [],
  transactionsLoading: false,
  transactionsLoadingError: null,
  loadMoreTransactionsLoading: false,
  transactionsFilter: {
    skip: 0,
    take: 30,
    iban: null,
    keyword: null,
    loadMoreTransactions: false,
    startDate: null,
    endDate: null,
    currency: '',
    type: '',
    categoryId: null,
    subcategoryId: null
  },
  //transaction categoies
  transactionCategories: [],
  transactionCategoriesLoading: false,
  transactionCategoriesLoadingError: null,
  //current tasks
  currentTasks: [],
  currentTasksLoading: false,
  currentTasksLoadingError: null,
  loadMoreCurrentTasksLoading: false,
  currentTasksFilter: {
    skip: 0,
    take: 100,
    loadMoreCurrentTasks: false
  },

  // transactions Details
  openedTransactionDetail: {},
  openedTransactionDetailLoading: false,
  openedTransactionDetailLoadingError: null,

  // commissions
  commissions: [],
  commissionsdebtloading: false,
  commissionsdebtloadingError: null
}

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case transactionConstants.FETCH_TRANSACTIONS:
      return updateObject(state, {
        transactions: [],
        transactionsLoading: true,
        transactionsLoadingError: null,
        transactionsFilter: updateObject(
          initialState.transactionsFilter,
          updateObject({ loadMoreTransactions: true }, action.payload)
        )
      })
    case transactionConstants.FETCH_TRANSACTIONS_SUCCESS:
      return updateObject(state, {
        transactions: nullToArray(action.payload).slice(
          0,
          state.transactionsFilter.take
        ),
        transactionsLoading: false,
        transactionsFilter: updateObject(state.transactionsFilter, {
          loadMoreTransactions:
            nullToArray(action.payload).length >= state.transactionsFilter.take
        })
      })
    case transactionConstants.FETCH_TRANSACTIONS_ERROR:
      return updateObject(state, {
        transactionsLoading: false,
        transactionsLoadingError: action.error
      })
    case transactionConstants.FETCH_MORE_TRANSACTIONS:
      return updateObject(state, {
        loadMoreTransactionsLoading: true,
        transactionsFilter: updateObject(state.transactionsFilter, {
          skip: state.transactions.length
        })
      })
    case transactionConstants.FETCH_MORE_TRANSACTIONS_SUCCESS:
      return updateObject(state, {
        transactions: [
          ...state.transactions,
          ...action.payload.slice(0, state.transactionsFilter.take)
        ],
        loadMoreTransactionsLoading: false,
        transactionsFilter: updateObject(state.transactionsFilter, {
          loadMoreTransactions:
            action.payload.length >= state.transactionsFilter.take
        })
      })
    case transactionConstants.FETCH_MORE_TRANSACTIONS_ERROR:
      return updateObject(state, {
        loadMoreTransactionsLoading: false,
        transactionsLoadingError: action.error,
        transactionsFilter: updateObject(state.transactionsFilter, {
          loadMoreTransactions: false
        })
      })

    case transactionConstants.FETCH_STATEMENTS:
      return updateObject(state, {
        statements: {},
        statementsLoading: true,
        statementsLoadingError: null,
        statementsFilter: updateObject(state.statementsFilter, {
          loadMoreStatements: true
        })
      })
    case transactionConstants.FETCH_STATEMENTS_SUCCESS:
      return updateObject(state, {
        statements: {
          ...action.payload,
          statements: action.payload.isPaginated
            ? nullToArray(action.payload.statements)
            : // .slice(
              //     0,
              //     state.statementsFilter.take
              //   )
              action.payload.statements
        },
        statementsLoading: false,
        statementsFilter: updateObject(state.statementsFilter, {
          loadMoreStatements:
            action.payload.isPaginated &&
            nullToArray(action.payload.statements).length >=
              state.statementsFilter.take
        })
      })
    case transactionConstants.FETCH_TRANSACTION_CATEGORIES:
      return updateObject(state, {
        transactionCategories: [],
        transactionCategoriesLoading: true,
        transactionCategoriesLoadingError: null
      })
    case transactionConstants.FETCH_TRANSACTION_CATEGORIES_SUCCESS:
      return updateObject(state, {
        transactionCategories: action.payload,
        transactionCategoriesLoading: false,
        transactionCategoriesLoadingError: null
      })
    case transactionConstants.FETCH_TRANSACTION_CATEGORIES_ERROR:
      return updateObject(state, {
        transactionCategoriesLoading: false,
        transactionCategoriesLoadingError: action.error
      })

    case transactionConstants.FETCH_STATEMENTS_ERROR:
      return updateObject(state, {
        statementsLoading: false,
        statementsLoadingError: action.error
      })
    case transactionConstants.FETCH_MORE_STATEMENTS:
      return updateObject(state, {
        loadMoreStatementsLoading: true
      })
    case transactionConstants.FETCH_MORE_STATEMENTS_SUCCESS:
      return updateObject(state, {
        statements: {
          ...state.statements,
          statements: [
            ...state.statements.statements,
            ...nullToArray(action.payload) //.slice(0, state.statementsFilter.take)
          ]
        },
        loadMoreStatementsLoading: false,
        statementsFilter: updateObject(state.statementsFilter, {
          loadMoreStatements:
            action.payload.length >= state.statementsFilter.take
        })
      })
    case transactionConstants.FETCH_MORE_STATEMENTS_ERROR:
      return updateObject(state, {
        loadMoreStatementsLoading: false,
        statementsLoadingError: action.error,
        statementsFilter: updateObject(state.statementsFilter, {
          loadMoreStatements: false
        })
      })
    case transactionConstants.CLEAR_STATEMENTS_LIST:
      return updateObject(state, {
        statements: {},
        statementsLoading: false,
        statementsLoadingError: null
      })

    case transactionConstants.FETCH_BLOCKED_AMOUNTS:
      return Object.assign({}, state, {
        blockedAmounts: [],
        blockedAmountsLoading: true,
        blockedAmountsLoadingError: null
      })
    case transactionConstants.FETCH_BLOCKED_AMOUNTS_SUCCESS:
      return Object.assign({}, state, {
        blockedAmounts: nullToArray(action.payload),
        blockedAmountsLoading: false,
        blockedAmountsLoadingError: null
      })
    case transactionConstants.FETCH_BLOCKED_AMOUNTS_ERROR:
      return Object.assign({}, state, {
        blockedAmountsLoading: false,
        blockedAmountsLoadingError: action.error
      })

    case transactionConstants.FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION:
      return Object.assign({}, state, {
        commissions: [],
        commissionsdebtloading: true,
        commissionsdebtloadingError: null
      })
    case transactionConstants.FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION_SUCCESS:
      return Object.assign({}, state, {
        commissions: action.payload,
        commissionsdebtloading: false,
        commissionsdebtloadingError: null
      })
    case transactionConstants.FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION_ERROR:
      return Object.assign({}, state, {
        commissionsdebtloading: false,
        commissionsdebtloadingError: action.error
      })

    case transactionConstants.FETCH_OTHER_BLOCKED_AMOUNTS:
      return Object.assign({}, state, {
        otherBlockedAmounts: [],
        otherBlockedAmountsLoading: true,
        otherBlockedAmountsLoadingError: null
      })
    case transactionConstants.FETCH_OTHER_BLOCKED_AMOUNTS_SUCCESS:
      return Object.assign({}, state, {
        otherBlockedAmounts: nullToArray(action.payload),
        otherBlockedAmountsLoading: false,
        otherBlockedAmountsLoadingError: null
      })
    case transactionConstants.FETCH_OTHER_BLOCKED_AMOUNTS_ERROR:
      return Object.assign({}, state, {
        otherBlockedAmountsLoading: false,
        otherBlockedAmountsLoadingError: action.error
      })

    case transactionConstants.FETCH_TRANSACTION_DETAILS:
      return Object.assign({}, state, {
        openedTransactionDetail: {},
        openedTransactionDetailLoading: true,
        openedTransactionDetailLoadingError: null
      })
    case transactionConstants.FETCH_TRANSACTION_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        openedTransactionDetail: action.payload,
        openedTransactionDetailLoading: false,
        openedTransactionDetailLoadingError: null
      })
    case transactionConstants.FETCH_TRANSACTION_DETAILS_ERROR:
      return Object.assign({}, state, {
        openedTransactionDetailLoading: false,
        openedTransactionDetailLoadingError: action.error
      })

    case transactionConstants.CLEAR_TRANSACTIONS_LIST:
      return updateObject(state, {
        transactions: [],
        transactionsFilter: { ...initialState.transactionsFilter }
      })

    case transactionConstants.FETCH_CURRENT_TASKS:
      return updateObject(state, {
        currentTasks: [],
        currentTasksLoading: true,
        currentTasksLoadingError: null,
        currentTasksFilter: updateObject(
          initialState.currentTasksFilter,
          updateObject({ loadMoreCurrentTasks: true }, action.payload)
        )
      })
    case transactionConstants.FETCH_CURRENT_TASKS_SUCCESS:
      return updateObject(state, {
        currentTasks: nullToArray(action.payload).slice(
          0,
          state.currentTasksFilter.take
        ),
        currentTasksLoading: false,
        currentTasksFilter: updateObject(state.currentTasksFilter, {
          loadMoreCurrentTasks:
            nullToArray(action.payload).length > state.currentTasksFilter.take
        })
      })
    case transactionConstants.FETCH_CURRENT_TASKS_ERROR:
      return updateObject(state, {
        currentTasksLoading: false,
        currentTasksLoadingError: action.error
      })
    case transactionConstants.FETCH_MORE_CURRENT_TASKS:
      return updateObject(state, {
        loadMoreCurrentTasksLoading: true,
        currentTasksFilter: updateObject(state.currentTasksFilter, {
          skip: state.currentTasks.length
        })
      })
    case transactionConstants.FETCH_MORE_CURRENT_TASKS_SUCCESS:
      return updateObject(state, {
        currentTasks: [
          ...state.currentTasks,
          ...action.payload.slice(0, state.currentTasks.take)
        ],
        loadMoreCurrentTasksLoading: false,
        currentTasksFilter: updateObject(state.currentTasksFilter, {
          loadMoreCurrentTasks:
            action.payload.length > state.currentTasksFilter.take
        })
      })
    case transactionConstants.FETCH_MORE_CURRENT_TASKS_ERROR:
      return updateObject(state, {
        loadMoreCurrentTasksLoading: false,
        currentTasksLoadingError: action.error,
        currentTasksFilter: updateObject(state.currentTasksFilter, {
          loadMoreCurrentTasks: false
        })
      })

    case transactionConstants.CLEAR_CURRENT_TASKS_LIST:
      return updateObject(state, {
        currentTasks: [],
        currentTasksFilter: { ...initialState.currentTasksFilter }
      })

    default:
      return state
  }
}
