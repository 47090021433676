import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

export function fetchThirdPartyItems() {
  return jsonRequest(`${baseUrl}/thirdparty/v1.0/TPInfo`, true)
}

export function fetchThirdPartyPdf(productId) {
  return jsonRequest(
    `${baseUrl}/thirdparty/v1.0/Registration/agreement/${productId}`,
    true
  ).then(res => res.blob())
}

export function fetchThirdPartyToggleHide(productId) {
  return jsonRequest(
    `${baseUrl}/thirdparty/v1.0/TPInfo/toggle-hide`,
    true,
    { productId },
    'POST'
  )
}

export function fetchThirdPartyRegistartionStart(productId) {
  return jsonRequest(
    `${baseUrl}/thirdparty/v1.0/Registration/initiate`,
    true,
    { productId },
    'POST'
  )
}

export function fetchThirdPartyResendSms(productId, sessionId) {
  return jsonRequest(
    `${baseUrl}/thirdparty/v1.0/Registration/sendTwoFactor`,
    true,
    { productId, sessionId },
    'POST'
  )
}

export function fetchThirdPartyRegistration({
  productId,
  sessionId,
  otp,
  validationId
}) {
  return jsonRequest(
    `${baseUrl}/thirdparty/v1.0/Registration/complete`,
    true,
    { productId, sessionId, otp, validationId },
    'POST'
  )
}
