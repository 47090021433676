import { loanConstants } from '../../constants/constants'
import { updateObject } from '../../helpers/util'

const initialState = {
  history: [],
  paymentshistoryLoading: false,
  paymentshistoryLoadingError: null,

  schedule: [],
  scheduleLoading: false,
  scheduleLoadingError: null,

  calculatedResult: {},
  calculatedResultLoading: false,
  calculatedResultLoadingError: null,

  prepaymentCalculatedResult: undefined,
  prepaymentCalculatedResultLoading: false,
  prepaymentCalculatedResultLoadingError: null,

  prepayment: undefined,
  prepaymentLoading: false,
  prepaymentError: null
}

export default function loanReducer(state = initialState, action) {
  switch (action.type) {
    case loanConstants.RESET_LOAN_SCHEDULE_AND_LOAN_HISTORY:
      return Object.assign({}, state, { history: {}, schedule: {} })

    case loanConstants.RESET_PREPAYMENT:
      return Object.assign({}, state, { prepayment: undefined })

    case loanConstants.FETCH_PREPAYMENT_CALCULATED_RESULT:
      return Object.assign({}, state, {
        prepaymentCalculatedResultLoading: true,
        prepaymentCalculatedResultLoadingError: null,
        prepaymentCalculatedResult: undefined
      })
    case loanConstants.FETCH_PREPAYMENT_CALCULATED_RESULT_SUCCESS:
      return Object.assign({}, state, {
        prepaymentCalculatedResultLoading: false,
        prepaymentCalculatedResultLoadingError: null,
        prepaymentCalculatedResult: action.payload
      })
    case loanConstants.FETCH_PREPAYMENT_CALCULATED_RESULT_ERROR:
      return Object.assign({}, state, {
        prepaymentCalculatedResultLoading: false,
        prepaymentCalculatedResultLoadingError: action.error,
        prepaymentCalculatedResult: undefined
      })

    case loanConstants.PREPAYMENT_RESET:
      return Object.assign({}, state, {
        prepayment: undefined,
        prepaymentLoading: false,
        prepaymentError: null
      })
    case loanConstants.FETCH_PREPAYMENT_RESULT:
      return Object.assign({}, state, {
        prepayment: undefined,
        prepaymentLoading: true,
        prepaymentError: null
      })
    case loanConstants.FETCH_PREPAYMENT_RESULT_SUCCESS:
      return Object.assign({}, state, {
        prepayment: true,
        prepaymentLoading: false,
        prepaymentError: null
      })
    case loanConstants.FETCH_PREPAYMENT_RESULT_ERROR:
      return Object.assign({}, state, {
        prepayment: undefined,
        prepaymentLoading: false,
        prepaymentError: action.error
      })

    case loanConstants.FETCH_HISTORY:
      return Object.assign({}, state, {
        history: [],
        paymentshistoryLoadingError: null,
        paymentshistoryLoading: true
      })
    case loanConstants.FETCH_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        history: action.payload,
        paymentshistoryLoadingError: null,
        paymentshistoryLoading: false
      })
    case loanConstants.FETCH_HISTORY_ERROR:
      return Object.assign({}, state, {
        paymentshistoryLoadingError: action.error,
        paymentshistoryLoading: false
      })

    // case loanConstants.FETCH_SCHEDULE_SUCCESS:
    //   return Object.assign({}, state, { schedule: action.payload });

    case loanConstants.FETCH_SCHEDULE:
      return Object.assign({}, state, {
        schedule: [],
        scheduleLoadingError: null,
        scheduleLoading: true
      })
    case loanConstants.FETCH_SCHEDULE_SUCCESS:
      return Object.assign({}, state, {
        schedule: action.payload,
        scheduleLoadingError: null,
        scheduleLoading: false
      })
    case loanConstants.FETCH_SCHEDULE_ERROR:
      return Object.assign({}, state, {
        scheduleLoadingError: action.error,
        scheduleLoading: false
      })

    // case loanConstants.FETCH_CALCULATED_RESULT_SUCCESS:
    //   return Object.assign({}, state, { calculatedResult: action.payload });

    case loanConstants.FETCH_CALCULATED_RESULT:
      return Object.assign({}, state, {
        calculatedResult: [],
        calculatedResultLoading: true,
        calculatedResultLoadingError: null
      })
    case loanConstants.FETCH_CALCULATED_RESULT_SUCCESS:
      return Object.assign({}, state, {
        calculatedResult: action.payload,
        calculatedResultLoading: false,
        calculatedResultLoadingError: null
      })
    case loanConstants.FETCH_CALCULATED_RESULT_ERROR:
      return Object.assign({}, state, {
        calculatedResultLoadingError: action.error,
        calculatedResultLoading: false
      })

    default:
      return state
  }
}
