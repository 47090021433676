let config = {
  numAccountsToShow: 4,
  dateFormat: 'DD/MM/YYYY',
  serverConfig: { server: 'development' },
  updateBanners: false
}

export function setServerConfig(serverConfig) {
  config.serverConfig = serverConfig
}

export function getConfigValue(parameter) {
  if (config.hasOwnProperty(parameter)) return config[parameter]
  return config.serverConfig[parameter]
}

export default config
