import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'
import { getUrlWithQueryString } from '../helpers/util'

export function getRequestedMoneys(params) {
  let url = getUrlWithQueryString(
    // `${baseUrl}/transfer/v2/request-money/pending-confirmation`,
    `${baseUrl}/transfer/v2/request-money`,
    params
  )
  return jsonRequest(url, true)
}

export function getReceivedMoneys(params) {
  let url = getUrlWithQueryString(
    // `${baseUrl}/transfer/v2/request-money`,
    `${baseUrl}/transfer/v2/request-money/pending-confirmation`,
    params
  )
  return jsonRequest(url, true)
}

export function createRequestedMoney(body) {
  return jsonRequest(`${baseUrl}/transfer/v2/request-money`, true, body, 'POST')
}

export function updateRequestedMoney(body) {
  return jsonRequest(`${baseUrl}/transfer/v2/request-money`, true, body, 'PUT')
}

export function completeRequestedMoney(body) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/completion`,
    true,
    body,
    'PUT'
  )
}

export function addMemberToRequestedMoney({ requestMoneyId, recepientInfo }) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/recipient/add
`,
    true,
    {
      requestMoneyId,
      recepientInfo
    },
    'PUT'
  )
}

export function removeMemberFromRequestedMoney(id) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/recipient/delete/${id}
`,
    true,
    null,
    'DELETE'
  )
}
export function cancelReceivedRequest({ id, addBlackList = false }) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/pending-confirmation/cancel
`,
    true,
    {
      id,
      addBlackList
    },
    'DELETE'
  )
}
export function getSentRequestHeaderDetails(id) {
  return jsonRequest(`${baseUrl}/transfer/v2/request-money/${id}`, true)
}

export function getUnWatchedRequests() {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/pending-confirmation/unwatched`,
    true
  )
}
export function updateUnWatchedRequests(params) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/pending-confirmation/viewed`,
    true,
    params,
    'PUT'
  )
}

export function getSentRequestDetails(params) {
  let url = getUrlWithQueryString(
    `${baseUrl}/transfer/v2/request-money/recipients`,
    params
  )
  return jsonRequest(url, true)
}

export function confirmRequestedMoney(body) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/confirm`,
    true,
    body,
    'PUT'
  )
}
export function updateMemberOfRequestedMoney(body) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/recipient/update`,
    true,
    body,
    'PUT'
  )
}

export function getBlockedList(params) {
  let url = getUrlWithQueryString(
    // `${baseUrl}/transfer/v2/request-money`,
    `${baseUrl}/transfer/v2/request-money/black-list`,
    params
  )
  return jsonRequest(url, true)
}
export function removeFromBlockedList(id) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/black-list/delete/${id}
`,
    true,
    null,
    'DELETE'
  )
}

export function repeatRequestedMoney(body) {
  return jsonRequest(
    `${baseUrl}/transfer/v2/request-money/Repeat`,
    true,
    body,
    'POST'
  )
}
