import { thirdParty } from '../../constants/constants'
import { nullToArray } from '../../helpers/util'
import i18n from '../../../i18n'
import { fetchThirdPartyItems } from '../../api/thridparty.api'

export const setThirdPartyLoadr = payload => ({
  type: thirdParty.SET_LOADING,
  payload
})

export const setThirdParty = payload => ({
  type: thirdParty.SET_THIRDPARTY_ITEMS,
  payload
})

export const setThirdPartyError = payload => ({
  type: thirdParty.SET_THIRDPARTY_ERROR,
  payload
})

export const setThirdPartyItemHidden = ({ name, hidden }) => ({
  type: thirdParty.SET_THIRDPARTY_ITEM_HIDDEN,
  payload: { name, hidden }
})

export const setThirdPartyItemActivate = ({ productId, activated }) => ({
  type: thirdParty.SET_THIRDPARTY_ITEM_ACTIVATE,
  payload: { productId, activated }
})

export function startFetchThirdPartys() {
  return async dispatch => {
    dispatch(setThirdPartyLoadr(true))
    try {
      const res = await fetchThirdPartyItems()
      dispatch(setThirdParty(nullToArray(res)))
    } catch (error) {
      let payload =
        error && error.errors
          ? error.errors[0]
          : i18n.t('P2p.ErrorCardsLoading')

      dispatch(setThirdPartyError(payload))
    }
  }
}
