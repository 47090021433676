import { createSelector } from 'reselect'

const userSettingsSelect = state => state.userSettings

export const reselectSettingsPhoneVerification = createSelector(
  userSettingsSelect,
  ({ configuration: { phoneVerification } }) => {
    return {
      phoneVerification
    }
  }
  //   ({ configuration: { phoneVerification } }) => ({
  //     lastSeen: phoneVerification.lastSeen
  //   })
)

export const reselectCorporateUserOrganizationNameEng = createSelector(
  userSettingsSelect,
  ({ corporateSettings: { organizationNameEng } }) => {
    return organizationNameEng
  }
)
export const reselectWasAddsIntroduced = createSelector(
  userSettingsSelect,
  ({ configuration: { wasAddsIntroduced }, configurationLoading }) => {
    return {
      wasAddsIntroduced,
      isConfigurationLoading: configurationLoading
    }
  }
)
