import { corporateConstants } from '../../../constants/constants'
import { store } from '../../state'
import { batchCurrentTask as client } from '../../../api/corporate'

// export function clearCurrentTasks() {
//   return { type: corporateConstants.CLEAR_CURRENT_TASKS_LIST };
// }

export function fetchCurrentTasks(params) {
  return dispatch => {
    dispatch({
      type: corporateConstants.FETCH_BATCH_CURRENT_TASKS,
      payload: params
    })

    return client
      .fetchBatchCurrentTasksAjax(
        store.getState().batchTransfers.batchCurrentTasksFilter
      )
      .then(
        result => {
          dispatch({
            type: corporateConstants.FETCH_BATCH_CURRENT_TASKS_SUCCESS,
            payload: result
          })
        },
        error => {
          dispatch({
            type: corporateConstants.FETCH_BATCH_CURRENT_TASKS_ERROR,
            error
          })
        }
      )
  }
}

export function fetchMoreCurrentTasks() {
  return dispatch => {
    dispatch({ type: corporateConstants.FETCH_MORE_BATCH_CURRENT_TASKS })
    client
      .fetchBatchCurrentTasksAjax(
        store.getState().batchTransfers.batchCurrentTasksFilter
      )
      .then(
        result => {
          dispatch({
            type: corporateConstants.FETCH_MORE_BATCH_CURRENT_TASKS_SUCCESS,
            payload: result
          })
        },
        error => {
          dispatch({
            type: corporateConstants.FETCH_MORE_BATCH_CURRENT_TASKS_ERROR,
            error
          })
        }
      )
  }
}

export function fetchBatchDetail(id) {
  return async dispatch => {
    try {
      dispatch({ type: corporateConstants.FETCH_BATCH_CURRENT_TASK_DETAIL })
      let result = await client.fetchBatchDetailAjax(id)
      dispatch({
        type: corporateConstants.FETCH_BATCH_CURRENT_TASK_DETAIL_SUCCESS,
        payload: result
      })
    } catch (error) {
      dispatch({
        type: corporateConstants.FETCH_BATCH_CURRENT_TASKS_ERROR,
        error
      })
    }
  }
}

export function updateFilterState(filter) {
  return { type: corporateConstants.UPDATE_BATCH_FILTER_STATE, payload: filter }
}

export function clearFilterState() {
  return { type: corporateConstants.CLEAR_BATCH_FILTER_STATE }
}

// export function fetchCurrentTaskDetail(id) {
//   return dispatch => {
//     dispatch({ type: corporateConstants.FETCH_CURRENT_TASK_DETAIL });

//     client.fetchCurrentTaskDetailsAjax(id).then(
//       result => {
//         dispatch({ type: corporateConstants.FETCH_CURRENT_TASK_DETAIL_SUCCESS, payload: result });
//       },
//       error => {
//         dispatch({ type: corporateConstants.FETCH_CURRENT_TASK_DETAIL_ERROR, error });
//       }
//     );
//   };
// }
