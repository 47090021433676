const userSettingsConstants = {
  USER_FIRST_LOADING: 'USER_FIRST_LOADING',
  FETCH_USER_SETTINGS_SUCCESS: 'FETCH_USER_SETTINGS_SUCCESS',
  FETCH_USER_SETTINGS_ERROR: 'FETCH_USER_SETTINGS_ERROR',
  SAVE_USER_SETTINGS_SUCCESS: 'FETCH_USER_SETTINGS_ERROR',
  SAVE_USER_SETTINGS_ERROR: 'FETCH_USER_SETTINGS_ERROR',
  FETCH_USER_SETTINGS: 'FETCH_USER_SETTINGS',
  UPDATE_CORPORATE_USER_SETTINGS: 'UPDATE_CORPORATE_USER_SETTINGS'
}

export const globalMessageTypes = {
  MESSAGE: 'MESSAGE',
  MESSAGE_WITHOUT_KEY: 'MESSAGE_WITHOUT_KEY',
  RESET_PASSWORD: 'RESET_PASSWORD'
}

const accountConstants = {
  FETCH_ACCOUNT: 'FETCH_ACCOUNT',
  FETCH_ACCOUNTS_SUCCESS: 'FETCH_ACCOUNTS_SUCCESS',
  FETCH_ACCOUNT_ERROR: 'FETCH_ACCOUNT_ERROR',

  FETCH_ALL_ACCOUNT: 'FETCH_ALL_ACCOUNT',
  FETCH_ALL_ACCOUNTS_SUCCESS: 'FETCH_ALL_ACCOUNTS_SUCCESS',
  FETCH_ALL_ACCOUNT_ERROR: 'FETCH_ALL_ACCOUNT_ERROR',

  FETCH_CREDIT_CARDS: 'FETCH_CREDIT_CARDS',
  FETCH_CREDIT_CARDS_SUCCESS: 'FETCH_CREDIT_CARDS_SUCCESS',
  FETCH_CREDIT_CARDS_ERROR: 'FETCH_CREDIT_CARDS_ERROR',

  FETCH_DEPOSITS: 'FETCH_DEPOSITS',
  FETCH_DEPOSITS_SUCCESS: 'FETCH_DEPOSITS_SUCCESS',
  FETCH_DEPOSIT_SERROR: 'FETCH_DEPOSIT_SERROR',

  FETCH_LOANS: 'FETCH_LOANS',
  FETCH_LOANS_SUCCESS: 'FETCH_LOANS_SUCCESS',
  FETCH_LOANS_ERROR: 'FETCH_LOANS_ERROR',

  FETCH_DEBITS_LIST: 'FETCH_DEBITS_LIST',
  FETCH_DEBITS_LIST_SUCCESS: 'FETCH_DEBITS_LIST_SUCCESS',
  FETCH_DEBITS_LIST_ERROR: 'FETCH_DEBITS_LIST_ERROR',

  FETCH_SAVING_LIST: 'FETCH_SAVING_LIST',
  FETCH_SAVING_LIST_SUCCESS: 'FETCH_SAVING_LIST_SUCCESS',
  FETCH_SAVING_LIST_ERROR: 'FETCH_SAVING_LIST_SUCCESS',

  FETCH_CURRENT_LIST: 'FETCH_CURRENT_LIST',
  FETCH_CURRENT_LIST_SUCCESS: 'FETCH_CURRENT_LIST_SUCCESS',
  FETCH_CURRENT_LIST_ERROR: 'FETCH_CURRENT_LIST_ERROR',

  FETCH_DEBIT_CARDS: 'FETCH_DEBIT_CARDS',
  FETCH_DEBIT_CARDS_SUCCESS: 'FETCH_DEBIT_CARDS_SUCCESS',
  FETCH_DEBIT_CARDS_ERROR: 'FETCH_DEBIT_CARDS_ERROR',

  FETCH_CREDITS: 'FETCH_CREDITS',
  FETCH_CREDITS_SUCCESS: 'FETCH_CREDITS_SUCCESS',
  FETCH_CREDITS_ERROR: 'FETCH_CREDITS_ERROR',

  FRIENDLY_NAME_SAVE_SUCCESS: 'FRIENDLY_NAME_SAVE_SUCCESS',

  SET_PRIMARY_SUCCESS: 'SET_PRIMARY_SUCCESS',

  FETCH_CARD: 'FETCH_CARD',
  FETCH_CARD_SUCCESS: 'FETCH_CARD_SUCCESS',
  FETCH_CARD_ERROR: 'FETCH_CARD_ERROR',

  UPDATE_DEBIT_CARD_STATUS: 'UPDATE_DEBIT_CARD_STATUS',
  UPDATE_CREDIT_CARD_STATUS: 'UPDATE_CREDIT_CARD_STATUS',

  RESET_PREV_ACCOUNTS: 'RESET_PREV_ACCOUNTS',

  UPDATE_CURRENCY_PRIORITY: 'UPDATE_CURRENCY_PRIORITY',

  FETCH_TRANSFER_MATRIX: 'FETCH_TRANSFER_MATRIX',
  FETCH_TRANSFER_MATRIX_SUCCESS: 'FETCH_TRANSFER_MATRIX_SUCCESS',
  FETCH_TRANSFER_MATRIX_ERROR: 'FETCH_TRANSFER_MATRIX_ERROR'
}

const corporateConstants = {
  FETCH_CURRENT_TASKS: 'CORPORATE/FETCH_CURRENT_TASKS',
  FETCH_CURRENT_TASKS_SUCCESS: 'CORPORATE/FETCH_CURRENT_TASKS_SUCCESS',
  FETCH_CURRENT_TASKS_ERROR: 'CORPORATE/FETCH_CURRENT_TASKS_ERROR',

  FETCH_CURRENT_TASK_DETAIL: 'CORPORATE/FETCH_CURRENT_TASK_DETAIL',
  FETCH_CURRENT_TASK_DETAIL_SUCCESS:
    'CORPORATE/FETCH_CURRENT_TASK_DETAIL_SUCCESS',
  FETCH_CURRENT_TASK_DETAIL_ERROR: 'CORPORATE/FETCH_CURRENT_TASK_DETAIL_ERROR',

  FETCH_MORE_CURRENT_TASKS: 'CORPORATE/FETCH_MORE_CURRENT_TASKS',
  FETCH_MORE_CURRENT_TASKS_SUCCESS:
    'CORPORATE/FETCH_MORE_CURRENT_TASKS_SUCCESS',
  FETCH_MORE_CURRENT_TASKS_ERROR: 'CORPORATE/FETCH_MORE_CURRENT_TASKS_ERROR',

  CLEAR_CURRENT_TASKS_LIST: 'CORPORATE/CLEAR_CURRENT_TASKS_LIST',

  // batch transfers
  FETCH_BATCH_CURRENT_TASKS: 'CORPORATE/FETCH_BATCH_CURRENT_TASKS',
  FETCH_BATCH_CURRENT_TASKS_SUCCESS:
    'CORPORATE/FETCH_BATCH_CURRENT_TASKS_SUCCESS',
  FETCH_BATCH_CURRENT_TASKS_ERROR: 'CORPORATE/FETCH_BATCH_CURRENT_TASKS_ERROR',

  FETCH_BATCH_CURRENT_TASK_DETAIL: 'CORPORATE/FETCH_BATCH_CURRENT_TASK_DETAIL',
  FETCH_BATCH_CURRENT_TASK_DETAIL_SUCCESS:
    'CORPORATE/FETCH_BATCH_CURRENT_TASK_DETAIL_SUCCESS',
  FETCH_BATCH_CURRENT_TASK_DETAIL_ERROR:
    'CORPORATE/FETCH_BATCH_CURRENT_TASK_DETAIL_ERROR',

  FETCH_BATCH_DETAIL_TRANSACTIONS: 'CORPORATE/FETCH_BATCH_DETAIL_TRANSACTIONS',
  FETCH_BATCH_DETAIL_TRANSACTIONS_SUCCESS:
    'CORPORATE/FETCH_BATCH_DETAIL_TRANSACTIONS_SUCCESS',
  FETCH_BATCH_DETAIL_TRANSACTIONS_ERROR:
    'CORPORATE/FETCH_BATCH_DETAIL_TRANSACTIONS_ERROR',

  FETCH_MORE_BATCH_DETAIL_TRANSACTIONS:
    'CORPORATE/FETCH_MORE_BATCH_DETAIL_TRANSACTIONS',
  FETCH_MORE_BATCH_DETAIL_TRANSACTIONS_SUCCESS:
    'CORPORATE/FETCH_MORE_BATCH_DETAIL_TRANSACTIONS_SUCCESS',
  FETCH_MORE_BATCH_DETAIL_TRANSACTIONS_ERROR:
    'CORPORATE/FETCH_MORE_BATCH_DETAIL_TRANSACTIONS_ERROR',
  SET_CURRENT_TASK_DETAIL_MODAL_ID:
    'CORPORATE/SET_CURRENT_TASK_DETAIL_MODAL_ID',
  CLEAR_BATCH_CURRENT_TASKS_LIST: 'CORPORATE/CLEAR_BATCH_CURRENT_TASKS_LIST',

  UPDATE_BATCH_FILTER_STATE: 'CORPORATE/UPDATE_BATCH_FILTER_STATE',
  CLEAR_BATCH_FILTER_STATE: 'CORPORATE/CLEAR_BATCH_FILTER_STATE'
}

const transactionConstants = {
  FETCH_TRANSACTIONS: 'FETCH_TRANSACTIONS',
  FETCH_TRANSACTIONS_SUCCESS: 'FETCH_TRANSACTIONS_SUCCESS',
  FETCH_TRANSACTIONS_ERROR: 'FETCH_TRANSACTIONS_ERROR',

  FETCH_MORE_TRANSACTIONS: 'FETCH_MORE_TRANSACTIONS',
  FETCH_MORE_TRANSACTIONS_SUCCESS: 'FETCH_MORE_TRANSACTIONS_SUCCESS',
  FETCH_MORE_TRANSACTIONS_ERROR: 'FETCH_MORE_TRANSACTIONS_ERROR',

  FETCH_BLOCKED_AMOUNTS: 'FETCH_BLOCKED_AMOUNTS',
  FETCH_BLOCKED_AMOUNTS_SUCCESS: 'FETCH_BLOCKED_AMOUNTS_SUCCESS',
  FETCH_BLOCKED_AMOUNTS_ERROR: 'FETCH_BLOCKED_AMOUNTS_ERROR',

  FETCH_OTHER_BLOCKED_AMOUNTS: 'FETCH_OTHER_BLOCKED_AMOUNTS',
  FETCH_OTHER_BLOCKED_AMOUNTS_SUCCESS: 'FETCH_OTHER_BLOCKED_AMOUNTS_SUCCESS',
  FETCH_OTHER_BLOCKED_AMOUNTS_ERROR: 'FETCH_OTHER_BLOCKED_AMOUNTS_ERROR',

  FETCH_STATEMENTS: 'FETCH_STATEMENTS',
  FETCH_STATEMENTS_SUCCESS: 'FETCH_STATEMENTS_SUCCESS',
  FETCH_STATEMENTS_ERROR: 'FETCH_STATEMENTS_ERROR',

  FETCH_MORE_STATEMENTS: 'FETCH_MORE_STATEMENTS',
  FETCH_MORE_STATEMENTS_SUCCESS: 'FETCH_MORE_STATEMENTS_SUCCESS',
  FETCH_MORE_STATEMENTS_ERROR: 'FETCH_MORE_STATEMENTS_ERROR',

  CLEAR_STATEMENTS_LIST: 'CLEAR_STATEMENTS_LIST',

  FETCH_TRANSACTION_CATEGORIES: 'FETCH_TRANSACTION_CATEGORIES',
  FETCH_TRANSACTION_CATEGORIES_SUCCESS: 'FETCH_TRANSACTION_CATEGORIES_SUCCESS',
  FETCH_TRANSACTION_CATEGORIES_ERROR: 'FETCH_TRANSACTION_CATEGORIES_ERROR',

  FETCH_TRANSACTION_DETAILS: 'FETCH_TRANSACTION_DETAILS',
  FETCH_TRANSACTION_DETAILS_SUCCESS: 'FETCH_TRANSACTION_DETAILS_SUCCESS',
  FETCH_TRANSACTION_DETAILS_ERROR: 'FETCH_TRANSACTION_DETAILS_ERROR',

  FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION:
    'FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION',
  FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION_SUCCESS:
    'FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION_SUCCESS',
  FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION_ERROR:
    'FETCH_BLOCKED_AMOUNTS_PRODUCTS_COMMISSION_ERROR',

  CLEAR_TRANSACTIONS_LIST: 'CLEAR_TRANSACTIONS_LIST',

  FETCH_CURRENT_TASKS: 'FETCH_CURRENT_TASKS',
  FETCH_CURRENT_TASKS_SUCCESS: 'FETCH_CURRENT_TASKS_SUCCESS',
  FETCH_CURRENT_TASKS_ERROR: 'FETCH_CURRENT_TASKS_ERROR',

  FETCH_MORE_CURRENT_TASKS: 'FETCH_MORE_CURRENT_TASKS',
  FETCH_MORE_CURRENT_TASKS_SUCCESS: 'FETCH_MORE_CURRENT_TASKS_SUCCESS',
  FETCH_MORE_CURRENT_TASKS_ERROR: 'FETCH_MORE_CURRENT_TASKS_ERROR',

  CLEAR_CURRENT_TASKS_LIST: 'CLEAR_CURRENT_TASKS_LIST'
}

const loanConstants = {
  FETCH_HISTORY: 'FETCH_HISTORY',
  FETCH_HISTORY_SUCCESS: 'FETCH_HISTORY_SUCCESS',
  FETCH_HISTORY_ERROR: 'FETCH_HISTORY_ERROR',

  FETCH_CALCULATED_RESULT: 'FETCH_CALCULATED_RESULT',
  FETCH_CALCULATED_RESULT_SUCCESS: 'FETCH_CALCULATED_RESULT_SUCCESS',
  FETCH_CALCULATED_RESULT_ERROR: 'FETCH_CALCULATED_RESULT_ERROR',

  FETCH_SCHEDULE: 'FETCH_SCHEDULE',
  FETCH_SCHEDULE_SUCCESS: 'FETCH_SCHEDULE_SUCCESS',
  FETCH_SCHEDULE_ERROR: 'FETCH_SCHEDULE_ERROR',

  FETCH_PREPAYMENT_CALCULATED_RESULT: 'FETCH_PREPAYMENT_CALCULATED_RESULT',
  FETCH_PREPAYMENT_CALCULATED_RESULT_SUCCESS:
    'FETCH_PREPAYMENT_CALCULATED_RESULT_SUCCESS',
  FETCH_PREPAYMENT_CALCULATED_RESULT_ERROR:
    'FETCH_PREPAYMENT_CALCULATED_RESULT_ERROR',

  PREPAYMENT_RESET: 'PREPAYMENT_RESET',
  FETCH_PREPAYMENT_RESULT: 'FETCH_PREPAYMENT_RESULT',
  FETCH_PREPAYMENT_RESULT_SUCCESS: 'FETCH_PREPAYMENT_RESULT_SUCCESS',
  FETCH_PREPAYMENT_RESULT_ERROR: 'FETCH_PREPAYMENT_RESULT_ERROR',

  RESET_LOAN_SCHEDULE_AND_LOAN_HISTORY: 'RESET_LOAN_SCHEDULE_AND_LOAN_HISTORY',
  RESET_PREPAYMENT: 'RESET_PREPAYMENT',

  FRIENDLY_NAME_SAVE_SUCCESS: 'LOAN.FRIENDLY_NAME_SAVE_SUCCESS',
  FRIENDLY_NAME_SAVE_ERROR: 'LOAN.FRIENDLY_NAME_SAVE_ERROR'
}

const depositConstants = {
  FETCH_DEPOSIT_CALCULATE_CANCELLATION: 'FETCH_DEPOSIT_CALCULATE_CANCELLATION',
  FETCH_DEPOSIT_CALCULATE_CANCELLATION_SUCCESS:
    'FETCH_DEPOSIT_CALCULATE_CANCELLATION_SUCCESS',
  FETCH_DEPOSIT_CALCULATE_CANCELLATION_ERROR:
    'FETCH_DEPOSIT_CALCULATE_CANCELLATION_ERROR',

  FETCH_DEPOSIT_FILED_AMOUNT_HISTORY: 'FETCH_DEPOSIT_FILED_AMOUNT_HISTORY',
  FETCH_DEPOSIT_FILED_AMOUNT_HISTORY_ERROR:
    'FETCH_DEPOSIT_FILED_AMOUNT_HISTORY_ERROR',
  FETCH_DEPOSIT_FILED_AMOUNT_HISTORY_SUCCESS:
    'FETCH_DEPOSIT_FILED_AMOUNT_HISTORY_SUCCESS',

  FETCH_PARTLY_CANCELLATION_DATA: 'FETCH_PARTLY_CANCELLATION_DATA',
  FETCH_PARTLY_CANCELLATION_DATA_SUCCESS:
    'FETCH_PARTLY_CANCELLATION_DATA_SUCCESS',
  FETCH_PARTLY_CANCELLATION_DATA_ERROR: 'FETCH_PARTLY_CANCELLATION_DATA_ERROR',

  FETCH_DEPOSITSTATEMENTS: 'FETCH_DEPOSITSTATEMENTS',
  FETCH_DEPOSITSTATEMENTS_SUCCESS: 'FETCH_DEPOSITSTATEMENTS_SUCCESS',
  FETCH_DEPOSIT_STATEMENTS_ERROR: 'FETCH_DEPOSIT_STATEMENTS_ERROR',

  FETCH_DEPOSIT_INTEREST_CALCULATOR: 'FETCH_DEPOSIT_INTEREST_CALCULATOR',
  FETCH_DEPOSIT_INTEREST_CALCULATOR_SUCCESS:
    'FETCH_DEPOSIT_INTEREST_CALCULATOR_SUCCESS',
  FETCH_DEPOSIT_INTEREST_CALCULATOR_ERROR:
    'FETCH_DEPOSIT_INTEREST_CALCULATOR_ERROR',

  RESET_DEPOSIT_DATA: 'RESET_DEPOSIT_DATA',

  FRIENDLY_NAME_SAVE_SUCCESS: 'DEPOSIT.FRIENDLY_NAME_SAVE_SUCCESS',
  //Open deposit Product codes
  TERM_DEPOSIT_DEPOSIT: 3007,
  ACCOMULATIVE_DEPOSIT: 3011
}

const templateConstants = {
  FETCH_TRANSFER_TEMPLATES: 'FETCH_TRANSFER_TEMPLATES',
  FETCH_TRANSFER_TEMPLATES_SUCCESS: 'FETCH_TRANSFER_TEMPLATES_SUCCESS',
  FETCH_TRANSFER_TEMPLATES_ERROR: 'FETCH_TRANSFER_TEMPLATES_ERROR',

  FETCH_PI_TEMPLATES: 'FETCH_PI_TEMPLATES',
  FETCH_PI_TEMPLATES_SUCCESS: 'FETCH_PI_TEMPLATES_SUCCESS',
  FETCH_PI_TEMPLATES_ERROR: 'FETCH_PI_TEMPLATES_ERROR',

  FETCH_REMITTANCE_TEMPLATES: 'FETCH_REMITTANCE_TEMPLATES',
  FETCH_REMITTANCE_TEMPLATES_SUCCESS: 'FETCH_REMITTANCE_TEMPLATES_SUCCESS',
  FETCH_REMITTANCE_TEMPLATES_ERROR: 'FETCH_REMITTANCE_TEMPLATES_ERROR',

  FETCH_MOBILE_TEMPLATES: 'FETCH_MOBILE_TEMPLATES',
  FETCH_MOBILE_TEMPLATES_SUCCESS: 'FETCH_MOBILE_TEMPLATES_SUCCESS',
  FETCH_MOBILE_TEMPLATES_ERROR: 'FETCH_MOBILE_TEMPLATES_ERROR',

  ADD_TRANSFER_TEMPLATES: 'ADD_TRANSFER_TEMPLATES',
  ADD_TRANSFER_TEMPLATES_SUCCESS: 'ADD_TRANSFER_TEMPLATES_SUCCESS',
  FETCH_ADD_TRANSFER_TEMPLATES_ERROR: 'FETCH_ADD_TRANSFER_TEMPLATES_ERROR',

  ADD_PI_TEMPLATES: 'ADD_PI_TEMPLATES',
  ADD_PI_TEMPLATES_SUCCESS: 'ADD_PI_TEMPLATES_SUCCESS',
  ADD_PI_TEMPLATES_ERROR: 'ADD_PI_TEMPLATES_ERROR',

  EDIT_TRANSFER_TEMPLATES_SUCCESS: 'EDIT_TRANSFER_TEMPLATES_SUCCESS',
  FETCH_EDIT_TRANSFER_TEMPLATES_ERROR: 'FETCH_EDIT_TRANSFER_TEMPLATES_ERROR',

  EDIT_PI_TEMPLATES_SUCCESS: 'EDIT_PI_TEMPLATES_SUCCESS',
  EDIT_PI_TEMPLATES_ERROR: 'EDIT_PI_TEMPLATES_ERROR',

  DELETE_TRANSFER_TEMPLATES_SUCCESS: 'DELETE_TRANSFER_TEMPLATES_SUCCESS',
  FETCH_DELETE_TRANSFER_TEMPLATES_ERROR:
    'FETCH_DELETE_TRANSFER_TEMPLATES_ERROR',

  DELETE_MOBILE_TEMPLATES_SUCCESS: 'DELETE_MOBILE_TEMPLATES_SUCCESS',
  DELETE_MOBILE_TEMPLATES_ERROR: 'DELETE_MOBILE_TEMPLATES_ERROR',

  DELETE_PI_TEMPLATES_SUCCESS: 'DELETE_PI_TEMPLATES_SUCCESS',
  DELETE_PI_TEMPLATES_ERROR: 'DELETE_PI_TEMPLATES_ERROR',

  DELETE_REMITTANCE_TEMPLATES_SUCCESS: 'DELETE_REMITTANCE_TEMPLATES_SUCCESS',
  DELETE_REMITTANCE_TEMPLATES_ERROR: 'DELETE_REMITTANCE_TEMPLATES_ERROR',

  FETCH_P2P_TEMPLATES: 'FETCH_P2P_TEMPLATES',
  FETCH_P2P_TEMPLATES_SUCCESS: 'FETCH_P2P_TEMPLATES_SUCCESS',
  FETCH_P2P_TEMPLATES_ERROR: 'FETCH_P2P_TEMPLATES_ERROR',

  DELETE_P2P_TEMPLATES_SUCCESS: 'DELETE_P2P_TEMPLATES_SUCCESS',
  DELETE_P2P_TEMPLATES_ERROR: 'DELETE_P2P_TEMPLATES_ERROR',
  ADD_ACRD_TEMPLATE: 'ADD_ACRD_TEMPLATE'
}

const remittanceProvidersConstants = {
  FETCH_REMITTANCE_RECEIVE_PROVIDERS: 'FETCH_REMITTANCE_RECEIVE_PROVIDERS',
  FETCH_REMITTANCE_RECEIVE_PROVIDERS_SUCCESS:
    'FETCH_REMITTANCE_RECEIVE_PROVIDERS_SUCCESS',
  FETCH_REMITTANCE_RECEIVE_PROVIDERS_ERROR:
    'FETCH_REMITTANCE_RECEIVE_PROVIDERS_ERROR'
}
const paymentProviderConstants = {
  FETCH_PENALTY_PAYMENT_PROVIDER_SUCCESS:
    'FETCH_PENALTY_PAYMENT_PROVIDER_SUCCESS',
  FETCH_PENALTY_PAYMENT_PROVIDER_ERROR: 'FETCH_PENALTY_PAYMENT_PROVIDER_ERROR',

  FETCH_MOBILE_PROVIDER_SUCCESS: 'FETCH_MOBILE_PROVIDER_SUCCESS',
  FETCH_MOBILE_PROVIDER_ERROR: 'FETCH_MOBILE_PROVIDER_ERROR'
}
const exchangeRateConstants = {
  FETCH_DASHBOARD_EXCHANGE_RATES: 'FETCH_DASHBOARD_EXCHANGE_RATES',
  FETCH_DASHBOARD_EXCHANGE_RATES_SUCCESS:
    'FETCH_DASHBOARD_EXCHANGE_RATES_SUCCESS',
  FETCH_DASHBOARD_EXCHANGE_RATES_ERROR: 'FETCH_DASHBOARD_EXCHANGE_RATES_ERROR',

  FETCH_ALL_EXCHANGE_RATES: 'FETCH_ALL_EXCHANGE_RATES',
  FETCH_ALL_EXCHANGE_RATES_ERROR: 'FETCH_ALL_EXCHANGE_RATES_ERROR',
  FETCH_ALL_EXCHANGE_RATES_SUCCESS: 'FETCH_ALL_EXCHANGE_RATES_SUCCESS'
}

const httpErrorConstants = {
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INTERNAL_SERVER_ERROR_OFF: 'INTERNAL_SERVER_ERROR_OFF',
  BAD_REQUEST: 'BAD_REQUEST',
  BAD_REQUEST_OFF: 'BAD_REQUEST_OFF'
}

const bannerConstants = {
  FETCH_BANNER: 'FETCH_BANNER',
  FETCH_BANNER_SUCCESS: 'FETCH_BANNER_SUCCESS',
  FETCH_BANNER_ERROR: 'FETCH_BANNER_ERROR',
  DASHBOARD_MAIN: 'dashboard_main',
  DASHBOARD_RIGHTBAR: 'dashboard_rightbar',
  FETCH_LOYALTYCASHBACK: 'FETCH_LOYALTYCASHBACK',
  FETCH_LOYALTYCASHBACK_SUCCESS: 'FETCH_LOYALTYCASHBACK_SUCCESS',
  FETCH_LOYALTYCASHBACK_ERROR: 'FETCH_LOYALTYCASHBACK_ERROR',
  FETCH_SOCIAL_PENSION: 'FETCH_SOCIAL_PENSION',
  FETCH_SOCIAL_PENSION_SUCCESS: 'FETCH_SOCIAL_PENSION_SUCCESS',
  FETCH_SOCIAL_PENSION_ERROR: 'FETCH_SOCIAL_PENSION_ERROR'
}

const uiConstants = {
  SET_HEADER_SHADE: 'SET_HEADER_SHADE',
  REMOVE_HEADER_SHADE: 'REMOVE_HEADER_SHADE',
  FIX_UTILITY_BASKET: 'FIX_UTILITY_BASKET',
  UNFIX_UTILITY_BASKET: 'UNFIX_UTILITY_BASKET',
  ADD_SCROLL_EVENT_HANDLER: 'ADD_SCROLL_EVENT_HANDLER',
  REMOVE_SCROLL_EVENT_HANDLER: 'REMOVE_SCROLL_EVENT_HANDLER',

  FETCH_PHONE_METADATA: 'FETCH_PHONE_METADATA',
  FETCH_PHONE_METADATA_SUCCESS: 'FETCH_PHONE_METADATA_SUCCESS',
  FETCH_PHONE_METADATA_ERROR: 'FETCH_PHONE_METADATA_ERROR',

  SHOW_GLOBAL_MESSAGE: 'SHOW_GLOBAL_MESSAGE'
}

const administrationConstants = {
  FETCH_USERS: 'CORPORATE/FETCH_USERS',
  FETCH_USERS_SUCCESS: 'CORPORATE/FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR: 'CORPORATE/FETCH_USERS_ERROR',

  FETCH_USER_ACTIONS: 'CORPORATE/FETCH_USER_ACTIONS',
  FETCH_USER_ACTIONS_SUCCESS: 'CORPORATE/FETCH_USER_ACTIONS_SUCCESS',
  FETCH_USER_ACTIONS_ERROR: 'CORPORATE/FETCH_USER_ACTIONS_ERROR',

  FETCH_USER_ROLES: 'CORPORATE/FETCH_USER_ROLES',
  FETCH_USER_ROLES_SUCCESS: 'CORPORATE/FETCH_USER_ROLES_SUCCESS',
  FETCH_USER_ROLES_ERROR: 'CORPORATE/FETCH_USER_ROLES_ERROR',

  SELECT_USER_ROLE: 'CORPORATE/SELECT_USER_ROLE',

  FETCH_USER_ROLE_ACTIONS: 'CORPORATE/FETCH_USER_ROLE_ACTIONS',
  FETCH_USER_ROLE_ACTIONS_SUCCESS: 'CORPORATE/FETCH_USER_ROLE_ACTIONS_SUCCESS',
  FETCH_USER_ROLE_ACTIONS_ERROR: 'CORPORATE/FETCH_USER_ROLE_ACTIONS_ERROR',

  ADD_USER_ROLE: 'CORPORATE/ADD_USER_ROLE',
  UPDATE_USER_ROLE: 'CORPORATE/UPDATE_USER_ROLE',
  CANCEL_ADD_USER_ROLE: 'CORPORATE/CANCEL_ADD_USER_ROLE',
  DELETE_USER_ROLE: 'DELETE_USER_ROLE'
}

const userConstants = {
  USER_IS_ACTIVE: 'USER_IS_ACTIVE',
  USER_IS_EXPIRING: 'USER_IS_EXPIRING',
  USER_HAS_EXPIRED: 'USER_HAS_EXPIRED',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE'
}

const mailBox = {
  SINGLE_MESSAGE_PER_PAGE: 20,
  ALLOWED_FILE_TYPES: [
    'zip',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'xml',
    'csv',
    'png',
    'jpg',
    'tif'
  ],
  PER_PAGE: 20,
  SET_MESSAGES_LOADING: 'SET_MESSAGES_LOADING',
  STEP_INBOX: 'STEP_INBOX',
  STEP_SENT: 'STEP_SENT',
  SET_NEW_MESSAGES: 'SET_NEW_MESSAGES',
  SET_INBOX_MESSAGES: 'SET_INBOX_MESSAGES',
  SET_SENT_MESSAGES: 'SET_SENT_MESSAGES',
  CHECKED_ALL_MESSAGES: 'CHECKED_ALL_MESSAGES',
  SET_SEARCH: 'SET_SEARCH',
  SET_CHECK_STATUS_SINGLE_MESSAGE: 'SET_CHECK_STATUS_SINGLE_MESSAGE',
  DELETE_MESSAGES: 'DELETE_MESSAGES',
  CLEAR_MESSAGES: 'CLEAR_MESSAGES',
  SET_TOTAL_MESSAGES: 'SET_TOTAL_MESSAGES',
  SET_SINGEL_MESSAGE_LOADING: 'SET_SINGEL_MESSAGE_LOADING',
  CLEAR_SINGEL_MESSAGE: 'CLEAR_SINGEL_MESSAGE',
  SET_SINGEL_MESSAGE: 'SET_SINGEL_MESSAGE',
  DELETE_SINGEL_MESSAGE: 'DELETE_SINGEL_MESSAGE',
  SEND_NEW_MESSAGE: 'SEND_NEW_MESSAGE',
  SET_SEARCHED_MESSAGES: 'SET_SEARCHED_MESSAGES',
  SET_MESSAGE_TYPES: 'SET_MESSAGE_TYPES',
  SET_MESSAGE_MARK_AS_READ: 'SET_MESSAGE_MARK_AS_READ',
  SET_LAST_UNREAD_MESSAGES: 'SET_LAST_UNREAD_MESSAGES'
}

const parkingConstants = {
  FETCH_CARS: 'FETCH_CARS',
  FETCH_CARS_SUCCESS: 'FETCH_CARS_SUCCESS',
  FETCH_CARS_ERROR: 'FETCH_CARS_ERROR',

  FETCH_CARS_ORDER: 'FETCH_CARS_ORDER',
  FETCH_CARS_ORDER_SUCCESS: 'FETCH_CARS_ORDER_SUCCESS',
  FETCH_CARS_ORDER_ERROR: 'FETCH_CARS_ORDER_ERROR',

  DISPLAY_SETTINGS: 'DISPLAY_SETTINGS',
  DISPLAY_SETTINGS_OFF: 'DISPLAY_SETTINGS_OFF',

  TOGGLE_CHOOSE_FINE: 'TOGGLE_CHOOSE_FINE',

  CHANGE_CARS_ORDER: 'CHANGE_CARS_ORDER',

  SAVE_CAR: 'SAVE_CAR',
  SAVE_CAR_SUCCESS: 'SAVE_CAR_SUCCESS',
  SAVE_CAR_ERROR: 'SAVE_CAR_ERROR',

  DELETE_CAR: 'DELETE_CAR',
  DELETE_CAR_SUCCESS: 'DELETE_CAR_SUCCESS',
  DELETE_CAR_ERROR: 'DELETE_CAR_ERROR',

  FETCH_CAR_PROVIDERS: 'FETCH_CAR_PROVIDERS',
  FETCH_CAR_PROVIDERS_SUCCESS: 'FETCH_CAR_PROVIDERS_SUCCESS',
  FETCH_CAR_PROVIDERS_ERROR: 'FETCH_CAR_PROVIDERS_ERROR',

  FETCH_CAR_FINE: 'FETCH_CAR_FINE',
  FETCH_CAR_FINE_SUCCESS: 'FETCH_CAR_FINE_SUCCESS',
  FETCH_CAR_FINE_ERROR: 'FETCH_CAR_FINE_ERROR',

  FETCH_CAR_PASS: 'FETCH_CAR_PASS',
  FETCH_CAR_PASS_SUCCESS: 'FETCH_CAR_PASS_SUCCESS',
  FETCH_CAR_PASS_ERROR: 'FETCH_CAR_PASS_ERROR',

  CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
  CONFIRM_PAYMENT_STOP_LOADING: 'CONFIRM_PAYMENT_STOP_LOADING',

  CLEAN_CARS_AND_FINES_STATE: 'CLEAN_CARS_AND_FINES_STATE',
  //------------------------ ერთჯერადი გადახდა ----------------
  FETCH_FINE_SERVICE_PROVIDERS: 'FETCH_FINE_SERVICE_PROVIDERS',
  FETCH_FINE_SERVICE_PROVIDERS_SUCCESS: 'FETCH_FINE_SERVICE_PROVIDERS_SUCCESS',
  FETCH_FINE_SERVICE_PROVIDERS_ERROR: 'FETCH_FINE_SERVICE_PROVIDERS_ERROR',

  CHECK_ONE_TIME_PAY_FINE: 'CHECK_ONE_TIME_PAY_FINE',
  CHECK_ONE_TIME_PAY_FINE_SUCCESS: 'CHECK_ONE_TIME_PAY_FINE_SUCCESS',
  CHECK_ONE_TIME_PAY_FINE_ERROR: 'CHECK_ONE_TIME_PAY_FINE_ERROR',

  //-------------------------- საშვის ყიდვა -------------------------
  FETCH_PASS_SERVICES: 'FETCH_PASS_SERVICES',
  FETCH_PASS_SERVICES_SUCCESS: 'FETCH_PASS_SERVICES_SUCCESS',
  FETCH_PASS_SERVICES_ERROR: 'FETCH_PASS_SERVICES_ERROR',
  //---------------------------- სთეითის გასუფთავება ----------------
  CLEAR_ONE_TIME_PAY_STATE: 'CLEAR_ONE_TIME_PAY_STATE',
  CLEAR_PARKING_FINES_STATE: 'CLEAR_PARKING_FINES_STATE',
  CLEAR_BUY_PASS_STATE: 'CLEAR_BUY_PASS_STATE'
}

const tags = {
  TAG_CHUNK_SIZE: 5,
  TAG_CONTEXT_MOBILE: 'MOBILE',
  TAG_CONTEXT_UTILITY: 'UTILITY'
}

const investmentConstants = {
  FETCH_INVESTMENTS: 'FETCH_INVESTMENTS',
  FETCH_INVESTMENTS_SUCCESS: 'FETCH_INVESTMENTS_SUCCESS',
  FETCH_INVESTMENTS_ERROR: 'FETCH_INVESTMENT_ERROR',

  FETCH_INVESTMENT_STATEMENTS: 'FETCH_INVESTMENT_STATEMENTS',
  FETCH_INVESTMENT_STATEMENTS_SUCCESS: 'FETCH_INVESTMENT_STATEMENTS_SUCCESS',
  FETCH_INVESTMENT_STATEMENTS_ERROR: 'FETCH_INVESTMENT_STATEMENTS_ERROR',

  FETCH_INVESTMENT_CALCULATION: 'FETCH_INVESTMENT_CALCULATION',
  FETCH_INVESTMENT_CALCULATION_SUCCESS: 'FETCH_INVESTMENT_CALCULATION_SUCCESS',
  FETCH_INVESTMENT_CALCULATION_ERROR: 'FETCH_INVESTMENT_CALCULATION_ERROR',

  DOWNLOAD_INVESTMENT_PDF: 'DOWNLOAD_INVESTMENT_PDF',
  DOWNLOAD_INVESTMENT_PDF_STOP_LOADING: 'DOWNLOAD_INVESTMENT_PDF_STOP_LOADING',

  DOWNLOAD_INVESTMENT_EXCEL: 'DOWNLOAD_INVESTMENT_EXCEL',
  DOWNLOAD_INVESTMENT_EXCEL_STOP_LOADING:
    'DOWNLOAD_INVESTMENT_EXCEL_STOP_LOADING',

  RESET_INVESTMENT_DATA: 'RESET_INVESTMENT_DATA',

  INVESTMENT_FRIENDLY_NAME_SAVE_SUCCESS:
    'INVESTMENT_FRIENDLY_NAME_SAVE_SUCCESS',
  INVESTMENT_FRIENDLY_NAME_SAVE_ERROR: 'INVESTMENT_FRIENDLY_NAME_SAVE_ERROR',

  REGISTER_PASS_PAYMENT: 'REGISTER_PASS_PAYMENT',
  REGISTER_PASS_PAYMENT_SUCCESS: 'REGISTER_PASS_PAYMENT_SUCCESS',
  REGISTER_PASS_PAYMENT_ERROR: 'REGISTER_PASS_PAYMENT_ERROR'
}
const p2p = {
  //P2P
  SET_P2P_CARDS_LOADER: 'SET_P2P_CARDS_LOADER',
  SET_P2P_CARDS: 'SET_P2P_CARDS',
  SET_P2P_CARDS_ERROR: 'SET_P2P_CARDS_ERROR',

  ADD_NEW_CARD: 'ADD_NEW_CARD',

  SET_FAVORITE: 'SET_FAVORITE',

  DELETE_CARD: 'DELETE_CARD',

  SET_P2PCARD_BLOCK_STATUS: 'SET_P2PCARD_BLOCK_STATUS',
  UPDATE_CARD_BANKNAME: 'UPDATE_CARD_BANKNAME',
  SET_FRIENDLY_NAME: 'SET_FRIENDLY_NAME',
  SET_P2P_TRANSACTION_LIMITS: 'SET_P2P_TRANSACTION_LIMITS',

  HIDE_SHOW_CARD_LENGTH: 7
}

const thirdParty = {
  SET_LOADING: 'SET_LOADING',
  SET_THIRDPARTY_ITEMS: 'SET_THIRDPARTY_ITEMS',
  SET_THIRDPARTY_ERROR: 'SET_THIRDPARTY_ERROR',
  SET_THIRDPARTY_ITEM_HIDDEN: 'SET_THIRDPARTY_ITEM_HIDDEN',
  SET_THIRDPARTY_ITEM_ACTIVATE: 'SET_THIRDPARTY_ITEM_ACTIVATE'
}

const branchSelector = {
  SET_ACTIVE: 'SET_ACTIVE',
  SET_BRANCH: 'SET_BRANCH'
}

const requestMoney = {
  SET_LOADER: 'SET_LOADER',
  SET_ERROR: 'SET_ERROR',
  SET_QUANTITY: 'SET_QUANTITY'
}

export {
  userSettingsConstants,
  accountConstants,
  transactionConstants,
  loanConstants,
  templateConstants,
  depositConstants,
  paymentProviderConstants,
  exchangeRateConstants,
  httpErrorConstants,
  bannerConstants,
  uiConstants,
  corporateConstants,
  administrationConstants,
  userConstants,
  remittanceProvidersConstants,
  mailBox,
  tags,
  investmentConstants,
  parkingConstants,
  p2p,
  thirdParty,
  branchSelector,
  requestMoney
}
