import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

function uploadBase64(data) {
  return jsonRequest(
    `${baseUrl}/storage/v1/images/base64`,
    true,
    { imageSource: data },
    'POST'
  )
}

export default {
  uploadBase64: uploadBase64
}
