/* eslint-disable no-debugger */
import { investmentConstants } from '../../constants/constants'

const initialState = {
  investmentList: [],
  investmentsLoading: false,
  investmentsLoadingError: null,

  investmentStatements: [],
  investmentStatementsLoading: false,
  investmentStatementsLoadingError: null,

  investmentCalculation: undefined,
  investmentCalculationLoading: false,
  investmentCalculationError: null,

  statementsLoadingPdf: false,
  statementsLoadingExcel: false

  // downloadInvestmentExcel
}

export default function investmentReducer(state = initialState, action) {
  switch (action.type) {
    //-------------------FETCH INVESTMENTS---------------------------------
    case investmentConstants.FETCH_INVESTMENTS: {
      // debugger;
      return {
        ...state,
        investmentsLoading: true,
        investmentsLoadingError: false,
        investmentList: []
      }
    }
    case investmentConstants.FETCH_INVESTMENTS_SUCCESS: {
      // debugger;
      let newInvestmentList = action.payload === null ? [] : action.payload
      return {
        ...state,
        investmentsLoading: false,
        investmentsLoadingError: null,
        investmentList: newInvestmentList
      }
    }
    case investmentConstants.FETCH_INVESTMENTS_ERROR: {
      // debugger;
      return {
        ...state,
        investmentsLoading: false,
        investmentsLoadingError: action.error
      }
    }
    //-----------------------------------------------------------------------------

    //---------------------------------------FETCH STATEMENTS---------------------------------------
    case investmentConstants.FETCH_INVESTMENT_STATEMENTS: {
      return {
        ...state,
        investmentStatementsLoading: true,
        investmentStatementsLoadingError: null,
        investmentStatements: []
      }
    }

    case investmentConstants.FETCH_INVESTMENT_STATEMENTS_SUCCESS: {
      return {
        ...state,
        investmentStatementsLoading: false,
        investmentStatementsLoadingError: null,
        investmentStatements: action.payload
      }
    }
    case investmentConstants.FETCH_INVESTMENT_STATEMENTS_ERROR: {
      return {
        ...state,
        investmentStatementsLoading: false,
        investmentStatementsLoadingError: true,
        investmentStatements: []
      }
    }
    //----------------------------------------------------------------------------------------------

    //-------------------------------------------FETCH CALCULATION-------------------------------
    case investmentConstants.FETCH_INVESTMENT_CALCULATION: {
      return {
        ...state,
        investmentCalculationLoading: true,
        investmentCalculationLoadingError: null,
        investmentCalculation: {}
      }
    }

    case investmentConstants.FETCH_INVESTMENT_CALCULATION_SUCCESS: {
      return {
        ...state,
        investmentCalculationLoading: false,
        investmentCalculationLoadingError: null,
        investmentCalculation: action.payload
      }
    }
    case investmentConstants.FETCH_INVESTMENT_CALCULATION_ERROR: {
      return {
        ...state,
        investmentCalculationLoading: false,
        investmentCalculationLoadingError: true,
        investmentCalculation: {}
      }
    }
    //------------------------------------------------------------------------------

    case investmentConstants.INVESTMENT_FRIENDLY_NAME_SAVE_SUCCESS: {
      //todo create a new investment and push to state
      let newInvestmentList = state.investmentList.slice()
      let investment = newInvestmentList.find(i => i.id == action.payload.id)
      if (investment) {
        investment.name = action.payload.name
      }
      return { ...state, investmentList: [...newInvestmentList] }
    }

    case investmentConstants.INVESTMENT_FRIENDLY_NAME_SAVE_ERROR: {
      let newInvestmentList = state.investmentList.slice()
      return {
        ...state,
        investmentList: [...newInvestmentList]
      }
    }
    case investmentConstants.RESET_INVESTMENT_DATA: {
      return {
        ...state,
        investmentCalculation: undefined,
        investmentStatements: []
      }
    }

    case investmentConstants.DOWNLOAD_INVESTMENT_PDF: {
      return {
        ...state,
        statementsLoadingPdf: true
      }
    }
    case investmentConstants.DOWNLOAD_INVESTMENT_PDF_STOP_LOADING: {
      return {
        ...state,
        statementsLoadingPdf: false
      }
    }
    case investmentConstants.DOWNLOAD_INVESTMENT_EXCEL: {
      // debugger;
      return {
        ...state,
        statementsLoadingExcel: true
      }
    }
    case investmentConstants.DOWNLOAD_INVESTMENT_EXCEL_STOP_LOADING: {
      // debugger;
      return {
        ...state,
        statementsLoadingExcel: false
      }
    }

    default:
      return state
  }
}
