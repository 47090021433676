/* eslint-disable no-case-declarations */
import { globalMessageTypes, uiConstants } from '../../constants/constants'
import { updateObject } from '../../helpers/util'
import { uuidv4 } from '../../helpers/uuidv4'

const initialState = {
  showHeaderShade: false,
  fixUtilityBasket: false,
  scrollEventHandlers: {},
  phoneMetaData: [
    {
      label: '+995',
      value: '+995',
      exampleNumber: '555123456',
      format: '(?:5(?:[14]4|5[0157-9]|68|7[0147-9]|9[1-35-9])|790)\\d{6}'
    }
  ],
  phoneMetaDataLoading: false,
  globalMessageProperties: {
    header: 'Common.TextInfo',
    type: globalMessageTypes.MESSAGE
  }
}
export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case uiConstants.SET_HEADER_SHADE:
      if (state.showHeaderShade) return state
      return { ...state, showHeaderShade: true }
    case uiConstants.REMOVE_HEADER_SHADE:
      if (!state.showHeaderShade) return state
      return { ...state, showHeaderShade: false }
    case uiConstants.FIX_UTILITY_BASKET:
      if (state.fixUtilityBasket) return state
      return { ...state, fixUtilityBasket: true }
    case uiConstants.UNFIX_UTILITY_BASKET:
      if (!state.fixUtilityBasket) return state
      return { ...state, fixUtilityBasket: false }
    case uiConstants.ADD_SCROLL_EVENT_HANDLER:
      return {
        ...state,
        scrollEventHandlers: updateObject(
          state.scrollEventHandlers,
          action.payload
        )
      }
    case uiConstants.REMOVE_SCROLL_EVENT_HANDLER:
      let newState = { ...state.scrollEventHandlers }
      delete newState[action.payload]
      return { ...state, scrollEventHandlers: newState }

    case uiConstants.FETCH_PHONE_METADATA:
      return { ...state, phoneMetaDataLoading: true }
    case uiConstants.FETCH_PHONE_METADATA_SUCCESS:
      return {
        ...state,
        phoneMetaDataLoading: false,
        phoneMetaData: action.payload
      }
    case uiConstants.FETCH_PHONE_METADATA_ERROR:
      return { ...state, phoneMetaDataLoading: false }

    case uiConstants.SHOW_GLOBAL_MESSAGE:
      return {
        ...state,
        globalMessageProperties: {
          ...initialState.globalMessageProperties,
          ...action.payload,
          messageId: uuidv4()
        }
      }
    default:
      return state
  }
}
