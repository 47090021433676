import { paymentProviderConstants } from '../../constants/constants'

const initialState = {
  penaltyProviders: []
}
export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case paymentProviderConstants.FETCH_PENALTY_PAYMENT_PROVIDER_SUCCESS:
      return Object.assign({}, state, { penaltyProviders: action.payload })
    default:
      return state
  }
}
