import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import LbModal from './LbModal'
import { httpErrorConstants } from '../../constants/constants'
import i18n from '../../../i18n'

class HttpErrorModal extends React.Component {
  constructor(props) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.showModal = this.showModal.bind(this)

    this.state = {
      title: 'Common.Error',
      message: 'Common.UnknownError'
    }
  }

  componentDidMount() {
    this.showModal()
  }

  componentDidUpdate() {
    this.showModal()
  }

  showModal() {
    const { internalServerError, badRequest } = this.props
    if (this.modal) {
      if (internalServerError || badRequest.active) this.modal.handleOpenModal()
      else this.modal.handleCloseModal()
    }
    // for testing
    // this.modal.handleOpenModal();
  }

  handleOk() {
    const { internalServerError, badRequest } = this.props
    if (internalServerError)
      this.props.dispatch({
        type: httpErrorConstants.INTERNAL_SERVER_ERROR_OFF
      })
    if (badRequest.active) {
      this.props.dispatch({ type: httpErrorConstants.BAD_REQUEST_OFF })
    }
  }

  render() {
    const { title, message } = this.state
    const {
      internalServerErrorUrl,
      badRequest: { active: activeBadReqest }
    } = this.props
    let badRequestErrors = []
    if (activeBadReqest) badRequestErrors = this.props.badRequest.error.errors
    return (
      <React.Fragment>
        <LbModal
          ref={modal => {
            this.modal = modal
          }}
          header={i18n.t(title)}
          overlayClassName="httperror-modal"
          className="mw-600"
          style={{ overlay: { zIndex: 11 } }}
        >
          <div>
            <div className="rows-wrap fnt-14">
              {activeBadReqest ? (
                badRequestErrors &&
                badRequestErrors.map(error => (
                  <p key={error}>
                    <Trans i18nKey={error} />
                  </p>
                ))
              ) : (
                <React.Fragment>
                  <Trans i18nKey={message} /> {internalServerErrorUrl}
                </React.Fragment>
              )}
            </div>
            <div className="lb-popup-footer">
              <input
                id="input-buttonHttpErrorModal"
                type="button"
                className="lb-btn btn-red btn-confirm"
                value={i18n.t('Common.Yes')}
                onClick={this.handleOk}
              />
            </div>
          </div>
        </LbModal>
      </React.Fragment>
    )
  }
}

export default connect(state => {
  const {
    httpErrors: { internalServerError, internalServerErrorUrl, badRequest }
  } = state
  return { internalServerError, badRequest, internalServerErrorUrl }
})(HttpErrorModal)
