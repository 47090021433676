import * as signalR from '@microsoft/signalr'
import { baseUrl } from './baseUrl'
import { store } from '../redux/state'

let connection = null

/**
 *  tray to connect if fails reconnects again
 * */

const start = async () => {
  if (!connection) return
  try {
    await connection.start()
    console.assert(connection.state === signalR.HubConnectionState.Connected)
    console.log('SignalR Connected.')
  } catch (err) {
    console.assert(connection.state === signalR.HubConnectionState.Disconnected)
    console.log(err)
    setTimeout(() => start(), 5000)
  }
}

/**
 *  it's default url for current task
 *
 */

export const buildWssConnection = () => {
  // check if connection already exists , exit
  if (connection) return

  // Checking existing of token

  let {
    oidc: { user }
  } = store.getState()
  if (!user || !user.access_token) return

  connection = new signalR.HubConnectionBuilder()
    .withUrl(`${baseUrl}/currenttask/currenttaskhub`, {
      accessTokenFactory: () => user.access_token
    })
    .withAutomaticReconnect()
    .build()

  // start to connect
  start()

  return connection
}

/**
 *  it's default url for current task
 *
 */

export const setNewWssConnection = async url => {
  // url='/currenttask/currenttaskhub'

  // check if connection already exists , close it and  continue
  if (connection) {
    connection.stop()
  }

  // Checking existing of token
  let {
    oidc: { user }
  } = store.getState()
  if (!user || !user.access_token) return

  connection = new signalR.HubConnectionBuilder()
    .withUrl(`${baseUrl}${url}`, {
      accessTokenFactory: () => user.access_token
    })
    .withAutomaticReconnect()
    .build()

  // start to connect
  start()
}

/**
 * close connection
 * @returns
 */

export const closeWssConnection = () => {
  if (connection) {
    connection.stop()
  }
}

// returns wss instance

export const getConnectionInstance = () => connection
