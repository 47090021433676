import React from 'react'
import Icon from '../../common/SimpleComponents/Icon'
import Background from 'assets/images/404line.png'
import UnauthorizedLayout from '../../common/Layout/UnauthorizedLayout'

const MaintenancePage = ({ maintenance }) => {
  return (
    <div className="lb-container">
      <div className="container">
        <div
          className="lb-block text-center finalize-block pv-60 ph-90 maintenace-page"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right bottom'
          }}
        >
          <i className="icn icon-404 mb-40">
            <Icon name="icon-404" view="0 0 63.904 53.291" />
          </i>

          <div className="text-center">
            <div className="text-line text-geo">
              <div className="mb-35">
                {maintenance.maintenanceMessageKa}
                <br />
                {maintenance.maintenanceSorryKa}
              </div>
            </div>

            <div className="text-line mt-35">
              {maintenance.maintenanceMessageEn}
              <br />
              {maintenance.maintenanceSorryEn}
            </div>

            <div className="additional-info">
              <p>
                {maintenance.maintenanceInfoKa}
                <span> {maintenance.maintenancePhoneNumber}</span>.
              </p>
              <p>
                {maintenance.maintenanceInfoEn}{' '}
                <span> {maintenance.maintenancePhoneNumber}</span>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnauthorizedLayout(MaintenancePage)
