import React, { Component } from 'react'
import { setI18next } from '../../../i18n'
import { I18n } from 'react-i18next'

export default class I18Global extends Component {
  constructor(props) {
    super(props)
    setI18next()
  }

  render() {
    return <I18n>{this.props.children}</I18n>
  }
}
