import { mailBox } from '../../constants/constants'
import { updateObject } from '../../helpers/util'

const initialState = {
  messagePage: 0,
  messageTotal: 0,
  page: 0,
  total: 0,
  isLoading: false,
  isMessageLoading: false,
  search: '',
  checkedAll: false,
  lastUnreadMessages: [],
  inboxes: [],
  sents: [],
  items: [],
  message: null,
  messageID: null,
  types: []
}
export default function mailBoxReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case mailBox.SET_LAST_UNREAD_MESSAGES: {
      state = updateObject(state, {
        lastUnreadMessages: payload
      })
      break
    }
    case mailBox.SET_NEW_MESSAGES: {
      state = updateObject(state, {
        inboxes: [...payload, ...state.inboxes],
        items: [...payload, ...state.items]
      })
      break
    }
    case mailBox.SET_MESSAGE_TYPES: {
      let types = payload.map(item => ({ label: item.name, value: item.id }))
      state = updateObject(state, {
        types
      })
      break
    }
    case mailBox.DELETE_SINGEL_MESSAGE: {
      let { items } = state
      items = items.filter(i => i.id != payload)
      state = updateObject(state, {
        items: items,
        message: null
      })

      break
    }
    case mailBox.SET_MESSAGES_LOADING: {
      state = updateObject(state, {
        isLoading: payload
      })
      break
    }
    case mailBox.SET_SINGEL_MESSAGE_LOADING: {
      state = updateObject(state, {
        isMessageLoading: payload
      })
      break
    }
    case mailBox.CLEAR_SINGEL_MESSAGE: {
      state = updateObject(state, {
        message: null,
        messageID: null,
        messagePage: 0,
        messageTotal: 0
      })
      break
    }
    case mailBox.SET_SINGEL_MESSAGE: {
      let { message, id, page, total } = payload

      let newMessage = null
      if (state.message) {
        newMessage = [...state.message, ...message]
      } else {
        newMessage = [...message]
      }

      state = updateObject(state, {
        message: newMessage,
        messageID: id,
        messagePage: page,
        messageTotal: total
      })
      break
    }
    case mailBox.SET_TOTAL_MESSAGES: {
      state = updateObject(state, {
        total: payload
      })
      break
    }
    case mailBox.CLEAR_MESSAGES: {
      state[payload] = []
      state = updateObject(state, {
        ...state,
        items: [],
        page: 0,
        total: null
      })
      break
    }
    case mailBox.DELETE_MESSAGES: {
      let { items } = state
      items = items.length ? items.filter(i => !i.checked) : []
      state = updateObject(state, {
        items,
        message: null
      })
      break
    }

    case mailBox.SET_SEARCH: {
      state = updateObject(state, {
        search: payload
      })
      break
    }
    case mailBox.CHECKED_ALL_MESSAGES: {
      let { items } = state
      items = items.length
        ? items.map(i => {
            return {
              ...i,
              checked: payload
            }
          })
        : []
      state = updateObject(state, {
        items
      })
      break
    }
    case mailBox.SET_CHECK_STATUS_SINGLE_MESSAGE: {
      let { items } = state
      items = items.length
        ? items.map(i => {
            if (i.id == payload) {
              return {
                ...i,
                checked: i.checked ? false : true
              }
            } else {
              return { ...i }
            }
          })
        : []
      state = updateObject(state, {
        items
      })
      break
    }
    case mailBox.SET_INBOX_MESSAGES: {
      const { messages, page } = payload

      state = updateObject(state, {
        inboxes: [...state.inboxes, ...messages],
        items: [...state.inboxes, ...messages],
        page
      })

      break
    }
    case mailBox.SET_SENT_MESSAGES: {
      const { messages, page } = payload

      state = updateObject(state, {
        sents: [...state.sents, ...messages],
        items: [...state.sents, ...messages],
        page
      })
      break
    }

    case mailBox.SET_SEARCHED_MESSAGES: {
      const { messages, page, total, search } = payload
      state = updateObject(state, {
        items: messages,
        page,
        total,
        search
      })
      break
    }

    case mailBox.SET_MESSAGE_MARK_AS_READ: {
      let { items, inboxes, lastUnreadMessages } = state

      items = items.map(i => {
        if (i.id == payload) {
          i.isRead = true
        }
        return i
      })

      inboxes = inboxes.map(i => {
        if (i.id == payload) {
          i.isRead = true
        }
        return i
      })

      lastUnreadMessages = lastUnreadMessages.filter(i => i.id != payload)

      state = updateObject(state, {
        items,
        inboxes,
        lastUnreadMessages
      })

      break
    }

    default:
      state = { ...state }
  }

  return state
}
