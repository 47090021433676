import { exchangeRateConstants } from '../../constants/constants'

const initialState = {
  dashboardExchangeRates: [],
  dashboardExchangeRatesLoading: false,
  dashboardExchangeRatesLoadingError: null,

  allExchangeRates: [],
  allExchangeRatesLoading: false,
  allExchangeRatesLoadingError: null
}
export default function exchangeRateReducer(state = initialState, action) {
  switch (action.type) {
    case exchangeRateConstants.FETCH_DASHBOARD_EXCHANGE_RATES:
      return Object.assign({}, state, {
        dashboardExchangeRates: [],
        dashboardExchangeRatesLoading: true,
        dashboardExchangeRatesLoadingError: null
      })
    case exchangeRateConstants.FETCH_DASHBOARD_EXCHANGE_RATES_SUCCESS:
      return Object.assign({}, state, {
        dashboardExchangeRates: action.payload,
        dashboardExchangeRatesLoading: false,
        dashboardExchangeRatesLoadingError: null
      })
    case exchangeRateConstants.FETCH_DASHBOARD_EXCHANGE_RATES_ERROR:
      return Object.assign({}, state, {
        dashboardExchangeRatesLoading: false,
        dashboardExchangeRatesLoadingError: action.error
      })

    case exchangeRateConstants.FETCH_ALL_EXCHANGE_RATES:
      return Object.assign({}, state, {
        allExchangeRates: [],
        allExchangeRatesLoading: true,
        allExchangeRatesLoadingError: null
      })
    case exchangeRateConstants.FETCH_ALL_EXCHANGE_RATES_SUCCESS:
      return Object.assign({}, state, {
        allExchangeRates: action.payload,
        allExchangeRatesLoading: false,
        allExchangeRatesLoadingError: null
      })
    case exchangeRateConstants.FETCH_ALL_EXCHANGE_RATES_ERROR:
      return Object.assign({}, state, {
        allExchangeRatesLoading: false,
        allExchangeRatesLoadingError: action.error
      })

    default:
      return state
  }
}
