import React, { Component } from 'react'
// import { I18n } from 'react-i18next';
import { connect } from 'react-redux'
// import logo from '../../../assets/images/logos/logo.svg';
import logo from '../../../assets/images/logos/logo-default.svg'
import logoEn from '../../../assets/images/logos/logo-eng.svg'
import logoCorp from '../../../assets/images/logos/Libertybusiness-geo.svg'
import logoCorpEn from '../../../assets/images/logos/Libertybusiness-eng.svg'
import userManager from '../../helpers/userManager'
import { store } from '../../redux/state'
import { history } from '../../helpers/history'
import Icon from '../SimpleComponents/Icon'
import ReactTooltip from 'react-tooltip'
import DebtNotification from '../DebtNotification'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Trans } from 'react-i18next'
import { LbLoader } from '../SimpleComponents/LBLoader'
import config from '../../constants/config'
import hasUserPermission, {
  corporateUserPermissions
} from '../../helpers/hasUserPermission'
import '../../../assets/scss/component/notification.scss'
import {
  getLastUnreadInboxMessages,
  getSingleMessage
} from '../../redux/actions/mailbox.actions'

//compoenents
import NavBarNotificalitonList from '../../components/Notifications/block/NavBarNotificalitonList'
import { PhoneVerification } from '../../components/Dashboard/PhoneVerification'
import {
  buildWssConnection,
  closeWssConnection
} from '../../helpers/wssConnection'
import { LbToast } from '../SimpleComponents/LBToasts'
import i18n from '../../../i18n'
import accountsActions from '../../redux/actions/account.actions'
import { startFetchRequestMoneyQuantity } from '../../redux/actions/requestMoney.actions'
import { MarketingConsents } from '../../components/Dashboard/MarketingConsents/MarketingConsents'

const getUserFullName = lang => {
  const user = store.getState().oidc.user
  if (user && user.profile) {
    if (lang === 'ka') {
      return `${user.profile.first_name_ka} ${user.profile.last_name_ka}`
    } else {
      return `${user.profile.first_name_en} ${user.profile.last_name_en}`
    }
  }
  return ''
}

const hasPermissionExtended = navElement => {
  let { corporate, onlyCorporate } = navElement
  return hasUserPermission(corporate, !onlyCorporate)
}

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scrollClass: false,
      quantity: 0
    }

    this.setScrollClass = this.setScrollClass.bind(this)

    this.logos = {
      ka: logo,
      'en-US': logoEn
    }

    this.logosCorp = {
      ka: logoCorp,
      'en-US': logoCorpEn
    }

    this.navigationStructure = [
      {
        i18nKey: 'Header.MyProducts',
        navElements: [
          [
            {
              link: '/products/accounts',
              i18nKey: 'Header.AcountsAndCards',
              corporate: corporateUserPermissions.ACCOUNTS_VIEW
            },
            {
              link: '/products/credits',
              i18nKey: 'Header.CreditCards',
              corporate: false
            },
            {
              link: '/products/deposits',
              i18nKey: 'Header.Deposits',
              corporate: corporateUserPermissions.DEPOSITS_VIEW
            },
            {
              link: '/products/loans',
              i18nKey: 'Header.Loans',
              corporate: corporateUserPermissions.CREDIT_PRODUCTS_LOANS_VIEW
            },
            {
              link: '/products/investments',
              i18nKey: 'Header.Investments'
            }
          ]
        ]
      },
      {
        i18nKey: 'Header.Transfers',
        navElements: [
          [
            {
              link: '/transfer/toAccount',
              i18nKey: 'Header.TransferToAccount',
              corporate: corporateUserPermissions.TRANSFERS_OWN_ACCOUNT
            },
            {
              link: '/transfer/toSomeone',
              i18nKey: 'Header.TransferToSomeone',
              corporate: [
                corporateUserPermissions.TRANSFERS_OTHER_BANK_NATIONAL_CURRENCY,
                corporateUserPermissions.TRANSFERS_INSIDE,
                corporateUserPermissions.TRANSFERS_OTHER_BANK_FOREIGN_CURRENCY
              ]
            },
            {
              link: '/transfer/toSomeoneByPersonalInfo',
              i18nKey: 'Header.ToSomeoneByPersonalInfo',
              corporate: false,
              isNew: true
            },
            {
              link: '/p2p/cards',
              i18nKey: 'Header.CardToCard',
              corporate: false,
              isNew: false,
              fulltime: true
            },

            {
              link: '/transfer/trex',
              i18nKey: 'Header.ToBudget',
              corporate: [
                corporateUserPermissions.TRANSFERS_BUDGET,
                corporateUserPermissions.TRANSFERS_BUDGET_IMPOSTOR
              ]
            },
            {
              link: '/request/money',
              i18nKey: 'Header.RequestForMoney',
              hasQuantity: true,
              quantity: 0
            },
            {
              link: '/transactions/batch-transfers/new',
              i18nKey: 'Header.BatchTransfersUpload',
              corporate: `${corporateUserPermissions.BATCH_TRANSFERS_UPLOAD}&${corporateUserPermissions.BATCH_TRANSFERS_VIEW}`,
              onlyCorporate: true
            },
            {
              link: '/payroll-transfer',
              i18nKey: 'Header.PayrollTransfers',
              corporate: [
                corporateUserPermissions.SALARY_TRANSFER,
                corporateUserPermissions.VIEW_SALARY_TRANFERS,
                corporateUserPermissions.VIEW_EMPLOYEE_LIST,
                corporateUserPermissions.VIEW_SALARY_TRANSFER_TRANSACTIONS,
                corporateUserPermissions.VIEW_EMPLOYEE_LIST,
                corporateUserPermissions.VIEW_OWN_SALARY_PROJECT
              ],
              onlyCorporate: true
            }
          ],
          [
            {
              link: '/payments/mobile',
              i18nKey: 'Header.TopupMobile',
              corporate: corporateUserPermissions.TEMPLATES_MOBILE_VIEW
            },
            {
              link: '/payments/utility',
              i18nKey: 'Header.UtilityPayments',
              corporate:
                corporateUserPermissions.TRANSFERS_TEMPLATE_VIEW_UTILITY
            },
            {
              link: '/payments/parking',
              i18nKey: 'Header.ParkingFines',
              corporate: corporateUserPermissions.TRANSFERS_PENALTY_VIEW
            }
          ],
          [
            {
              link: '/templates',
              i18nKey: 'Header.TemplateManagement',
              corporate: [
                corporateUserPermissions.TRANSFERS_OTHER_BANK_FOREIGN_CURRENCY,
                corporateUserPermissions.TRANSFERS_OTHER_BANK_NATIONAL_CURRENCY,
                corporateUserPermissions.TRANSFERS_INSIDE,
                corporateUserPermissions.TRANSFERS_BUDGET,
                corporateUserPermissions.TRANSFERS_BUDGET_IMPOSTOR
              ]
            }
          ],
          [
            {
              link: '/standing-orders',
              i18nKey: 'Header.StandingOrders',
              corporate: false
            }
          ],
          [
            {
              link: '/remittance/receive',
              i18nKey: 'Header.MoneyTransfersReceive',
              corporate: false
            },
            {
              link: '/remittance/send',
              i18nKey: 'Header.MoneyTransfersSend',
              corporate: false
            }
          ]
        ]
      },
      {
        i18nKey: 'Header.Transactions',
        navElements: [
          [
            {
              link: '/transactions/history',
              i18nKey: 'Header.TransactionsSearch',
              corporate: corporateUserPermissions.TRANSACTIONS
            },
            {
              link: '/transactions/statements',
              i18nKey: 'Header.Statements',
              corporate: corporateUserPermissions.TRANSACTIONS_STATEMENT
            },
            {
              link: '/transactions/blocked',
              i18nKey: 'Header.BlockedAmounts',
              corporate: corporateUserPermissions.TRANSACTIONS_BLOCKED_AMOUNTS
            },
            {
              link: '/transactions/current',
              i18nKey: 'Header.CurrentTransactions',
              corporate: corporateUserPermissions.TRANSACTIONS_CURRENT_TASKS
            },
            {
              link: '/transactions/batch-transfers',
              i18nKey: 'Header.GroupTransactions',
              corporate: corporateUserPermissions.BATCH_TRANSFERS_VIEW,
              onlyCorporate: true
            }
          ]
        ]
      },

      {
        i18nKey: 'Header.MyFinances',
        navElements: [
          [
            {
              link: '/finances/income',
              i18nKey: 'Header.MyFinancesIncome'
            },
            {
              link: '/finances/outcome',
              i18nKey: 'Header.MyFinancesOutCome'
            },
            {
              link: '/finances/circulation',
              i18nKey: 'Header.MyFinancesCirculation'
            }
          ]
        ]
      },
      {
        i18nKey: 'Header.Offers',
        navElements: [
          [
            //TODO: this must be uncommented
            {
              link: '/offers/accounts',
              i18nKey: 'Common.Accounts',
              onlyCorporate: true,
              corporate: true,
              isNew: true
            },
            {
              link: '/offers/deposits',
              i18nKey: 'Header.Deposits',
              corporate: false
            },
            {
              link: '/offers/loans',
              i18nKey: 'Header.Loans',
              corporate: false
            },
            {
              link: '/offers/cards',
              i18nKey: 'Header.Digital.Cards',
              corporate: true
            },
            {
              link: '/offers/credit',
              i18nKey: 'Header.CreditCards',
              corporate: false
            },
            {
              link: '/offers/myOffers',
              i18nKey: 'Offers.MyOffers',
              corporate: false
            },
            {
              link: '/offers/insurance',
              i18nKey: 'Offers.Insurance',
              corporate: false,
              isNew: false
            }
          ]
        ]
      }
    ]
    //TODO: this must be deleted
    if (
      hasUserPermission(false, true) &&
      this.props.isResident &&
      this.navigationStructure.find(i => i.i18nKey === 'Header.Offers')
    ) {
      const index = this.navigationStructure.findIndex(
        i => i.i18nKey === 'Header.Offers'
      )
      this.navigationStructure[index].navElements &&
        this.navigationStructure[index].navElements[0].push({
          link: '/offers/referrals',
          i18nKey: 'Offers.Referrals',
          corporate: false,
          isNew: true
        })
    }

    if (hasUserPermission(true, false)) {
      this.navigationStructure = this.navigationStructure.filter(
        i => i.i18nKey !== 'Header.MyFinances'
      )
    }
  }

  setScrollClass(value) {
    this.setState({ scrollClass: value })
  }

  componentDidUpdate(prevProps) {
    // this chunk is for scrollTop when path is changed
    if (
      prevProps.location &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      let oldPathFirstArg = prevProps.location.pathname.split('/')
      let pathFirstArg = this.props.location.pathname.split('/')

      if (oldPathFirstArg[1] !== pathFirstArg[1]) {
        this.props.windowTop()
      }
    }
    this.getUnwatchedRequestMoneys()
  }

  componentDidMount() {
    // const { checkForNewMessage } = this.props;
    // if (
    //   this.props.history &&
    //   this.props.history.location.pathname != '/notifications'
    // ) {
    //   this.props.getLastUnreadInboxMessages();
    // }
    // Start and try to connect Socket
    buildWssConnection()
    this.props.getTransferMatrix()
    this.notifyTrustedDevices()
    this.props.getRequestedMoneyQuantity()
  }

  componentWillUnmount() {
    // close ws connection
    closeWssConnection()
  }

  // trusted device notification
  notifyTrustedDevices = () => {
    if (sessionStorage.getItem('TEMP_TRUST_ENABLED')) {
      LbToast('success', i18n.t('Common.DEVICE_SUCCESSFULLY_TRUSTED'))
      sessionStorage.removeItem('TEMP_TRUST_ENABLED')
    }
  }
  getUnwatchedRequestMoneys = async () => {
    if (hasUserPermission(true, false)) return
    try {
      this.navigationStructure = this.navigationStructure.map(nav => {
        if (nav.i18nKey === 'Header.Transfers') {
          // For first 4 sub menus
          if (nav.navElements && nav.navElements[0])
            nav.navElements[0] = nav.navElements[0].map(j => {
              if (j.i18nKey == 'Header.RequestForMoney') {
                j.quantity = this.props.requestMoneyQuantity || 0
              }
              return j
            })
        }
        return nav
      })
    } catch (error) {
      // LbToast('error', error && error.errors[0])
    }
  }

  render() {
    const logoImage = this.logos[this.props.i18n.language]
    const logoImageCorp = this.logosCorp[this.props.i18n.language]

    const { commissions, commissionsdebtloading, messages } = this.props

    return (
      <header
        id="header-Header"
        className={`lb-header lb-header-in${
          this.props.showHeaderShade ? ' scroll' : ''
        }`}
      >
        <div className="lb-top-header">
          <div className="container">
            <div className="lb-header-user po-r">
              {this.props.picture ? (
                <img className="lb-head-avatar" src={this.props.picture} />
              ) : (
                <i className="lb-head-avatar head-avatar-circle">
                  <Icon name="icn-avatar-retail" view="0 0 17.722 22.327" />
                </i>
              )}
              {/* TODO: when you have notification */}
              {!this.props.phoneNumberVerified &&
              hasUserPermission(false, true) ? (
                <div className="icn-notify" />
              ) : null}
              <span>{getUserFullName(this.props.i18n.language)}</span>
              <i className="icn icn-arrow-down ml-10">
                <Icon name="icn-arrow-down" view="0 0 51.546 51.493" />
              </i>

              <div className="lb-sub-navbar">
                {/* TODO: when you have notification add class is-notify*/}
                <a
                  id="a-Header-1"
                  className={`lb-subnav-item po-r ${
                    !this.props.phoneNumberVerified &&
                    hasUserPermission(false, true)
                      ? 'is-notify'
                      : ''
                  } `}
                  href={`${config.serverConfig.authorizationUrl}/Manage?returnUrl=${window.location.href}&clientId=${config.serverConfig.clientId}`}
                >
                  <Trans i18nKey="Header.MyProfile" />
                  {/* TODO: when you have notification */}
                  {!this.props.phoneNumberVerified &&
                  hasUserPermission(false, true) ? (
                    <div className="icn-notify" />
                  ) : null}
                </a>
                <div
                  id="div-Header-1"
                  className="lb-subnav-item"
                  onClick={() => history.push('/settings/accounts')}
                >
                  <Trans i18nKey="Header.Options" />
                </div>
              </div>
            </div>

            <div id="div-Header-2" className="lb-mail lb-header-user">
              <i
                className="icn icn-mail po-r"
                onClick={() => {
                  history.push('/notifications')
                }}
              >
                <Icon name="icn-mail" view="0 0 20 20" />
                {/* TODO: when you have notification */}
                {messages && messages.length ? (
                  <div className="icn-mailbox" />
                ) : null}
              </i>

              {/* TODO: when you have not notification */}
              {messages && !messages.length ? (
                <div className="lb-sub-navbar">
                  <div id="div-Header-2" className="lb-subnav-item">
                    <div className="fnt-low-m fnt-11 text-center pb-10">
                      <i className="icn icn-notnotifi mb-5 mt-15">
                        <Icon name="icn-notnotifi" viewBox="0 0 32.77 13.498" />
                      </i>
                      <p>
                        <Trans i18nKey="Notifications.Empty" />
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {/* TODO: when you have  notification */}

              <NavBarNotificalitonList
                messages={messages}
                onChooseMessage={this.props.getSingleMessage}
                getLastUnreadInboxMessages={
                  this.props.getLastUnreadInboxMessages
                }
              />
            </div>

            <div
              id="div-Header-3"
              className="lb-faq"
              onClick={() => {
                history.push('/help')
              }}
            >
              <i className="icn icn-question">
                <span
                  className="tooltip-parent"
                  data-tip
                  data-for="div-tip-faq"
                >
                  <Icon name="icn-question" view="0 0 20 20" />
                </span>
              </i>

              <ReactTooltip
                id="div-tip-faq"
                className="lb-tooltip type-darker"
                place="bottom"
                type="dark"
                effect="solid"
              >
                <span className="fnt-low-m">
                  <Trans i18nKey="Faq.Faq" />
                </span>
              </ReactTooltip>
            </div>

            <div
              id="div-Header-4"
              className="lb-logout"
              onClick={() => {
                userManager.object.signoutRedirect()
              }}
            >
              <i className="icn icn-exit">
                <Icon name="logout" view="0 0 23.07 20.58" />
              </i>
              <Trans i18nKey="Header.Logout" />
            </div>
          </div>
        </div>
        <div className="lb-main-header">
          <div className="container">
            {hasUserPermission(true, false) ? (
              <>
                <img
                  id="img-Header-1"
                  className="lb-logo-main"
                  onClick={() => history.push('/')}
                  src={logoImageCorp}
                  alt="LibertyBank"
                />
              </>
            ) : (
              <>
                <img
                  id="img-Header-1"
                  className="lb-logo-main"
                  onClick={() => history.push('/')}
                  src={logoImage}
                  alt="LibertyBank"
                />
              </>
            )}

            <nav
              className="lb-navbar"
              // key={`for-rerender-${this.state.quantity}`}
            >
              {this.navigationStructure.map((nav, i) => {
                return (
                  <NavItem
                    key={i}
                    keyId={i}
                    i18nKey={nav.i18nKey}
                    navElements={nav.navElements}
                    location={
                      this.props.location ? this.props.location.pathname : ''
                    }
                    isCorporate={!!this.props.corporateSettings}
                  />
                )
              })}
            </nav>

            <div className="lb-exchange flex-aligned">
              {commissions.length > 0 && (
                <DebtNotification
                  id="Header"
                  className="warn-lg is-red mr-10"
                  position="bottom"
                  debtModal
                  message={`${this.props.t(
                    'Header.CommissionDebt'
                  )} - ${commissions &&
                    commissions
                      .map(x => +x.amountInGel)
                      .reduce((a, b) => a + b, 0)} ₾`}
                  commissions={commissions}
                  place="header-deposit-debt"
                />
              )}

              <i
                id="i-Header-2"
                className="icn icn-exchange"
                onClick={() => {
                  history.push('/exchange/calculator')
                }}
              >
                <span
                  className="tooltip-parent"
                  data-tip
                  data-for="div-tip-exchange"
                >
                  <Icon name="icn-exchange" view="0 0 46 26" />
                  <Icon name="icn-exchange-hover" view="0 0 46 26" />
                </span>
              </i>
              {commissionsdebtloading && <LbLoader isLoading size="xs" />}
            </div>
            <ReactTooltip
              id="div-tip-exchange"
              className="lb-tooltip type-darker"
              place="left"
              type="dark"
              effect="solid"
            >
              <span>
                <Trans i18nKey="Common.ExchangeRate" />
              </span>
            </ReactTooltip>
          </div>
        </div>
        <PhoneVerification />
        <MarketingConsents />
      </header>
    )
  }
}

class NavItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showChildren: false
    }
    this.showChildren = this.showChildren.bind(this)
    this.hideChildren = this.hideChildren.bind(this)
    this.handleNavClick = this.handleNavClick.bind(this)
  }

  showChildren() {
    this.setState({ showChildren: true })
  }

  hideChildren() {
    this.setState({ showChildren: false })
  }

  handleNavClick(link) {
    history.push(link)
    setTimeout(this.hideChildren, 100)
  }

  render() {
    // if (
    //   !this.props.navElements.some(nav =>
    //     nav.some(sub => hasPermissionExtended(sub))
    //   )
    // )
    //   return null;
    let isActive = this.props.navElements.find(k =>
      k.find(x => this.props.location.includes(x.link))
    )

    return (
      <div
        id={`div-lbnavbaritem-Header-${this.props.keyId}`}
        className="lb-navbar-item"
        onMouseOver={this.showChildren}
        onMouseLeave={this.hideChildren}
      >
        <div
          id={`div-navitem-Header-${this.props.keyId}`}
          className={'nav-item' + (isActive ? ' active' : '')}
        >
          <span>
            <Trans i18nKey={this.props.i18nKey} />
          </span>
          <i className="icn icn-dropdown">
            <Icon name="triangle" view="0 0 9.523 4.987" />
          </i>
        </div>
        {/* {this.props.keyId == 3 && (
          <div className="newnotif-block">
            <div className="add-new"> NEW </div>
            <i className="icn">
              <Icon name="icn-notifarrow" viewBox="0 0 5.317 3.908" />
            </i>
          </div>
        )} */}

        <div
          id={`div-navbar-Header-${this.props.keyId}`}
          className={
            'lb-sub-navbar ' + (this.state.showChildren ? 'navbar-show' : '')
          }
        >
          {this.props.navElements
            .filter(nav =>
              nav.some(
                sub =>
                  (this.props.isCorporate && sub.corporate) ||
                  (!this.props.isCorporate && !sub.onlyCorporate)
              )
            )
            .map((nav, i) => {
              return (
                <div key={i} className="lb-subnav-wrap">
                  {nav
                    .filter(
                      subNav =>
                        (this.props.isCorporate && subNav.corporate) ||
                        (!this.props.isCorporate && !subNav.onlyCorporate)
                    )
                    .map((subNav, j) => {
                      return (
                        <div
                          key={j}
                          id={`div-NavItem-Header-${i}${this.props.keyId}-${j}`}
                          className={`lb-subnav-item${
                            !hasPermissionExtended(subNav) ? ' disabled' : ''
                          }`}
                          onClick={() => {
                            if (hasPermissionExtended(subNav))
                              this.handleNavClick(subNav.link)
                          }}
                        >
                          {/** TODO: new icon inline style delete in future */}
                          <div
                            className="new-wrapper"
                            style={{
                              display: 'inline-block',
                              position: 'relative'
                            }}
                          >
                            <Trans i18nKey={subNav.i18nKey} />
                            {subNav.isNew && (
                              <div
                                className="newnotif-block"
                                style={{ top: -16, right: -26 }}
                              >
                                <div className="add-new"> NEW</div>
                                <i className="icn">
                                  <Icon
                                    name="icn-notifarrow"
                                    viewBox="0 0 5.317 3.908"
                                  />
                                </i>
                              </div>
                            )}
                            {/* TODO:badge receive request */}
                            {subNav.hasQuantity && subNav.quantity ? (
                              <div className="lb-badge">
                                {subNav.quantity > 99 ? '99+' : subNav.quantity}
                              </div>
                            ) : null}

                            {/* 24/7 */}
                            {subNav.fulltime && (
                              <div className="lb-badge lb-badge-new ">24/7</div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  commissionsdebtloading: PropTypes.bool,
  commissions: PropTypes.array
}

NavItem.propTypes = {
  i18nKey: PropTypes.string,
  navElements: PropTypes.array
}

const mapDispatchToProps = dispatch => ({
  getRequestedMoneyQuantity: () => dispatch(startFetchRequestMoneyQuantity()),
  getLastUnreadInboxMessages: () => dispatch(getLastUnreadInboxMessages()),
  getTransferMatrix: () => dispatch(accountsActions.fetchTransferMatrix()),
  getSingleMessage: (id, step, markAsRead) =>
    dispatch(getSingleMessage(id, step, markAsRead))
})

export default withRouter(
  connect(
    state => {
      const {
        transaction: { commissions, commissionsdebtloading }
      } = state
      const picture =
        state.oidc.user && state.oidc.user.profile.picture
          ? state.oidc.user.profile.picture
          : null
      const showHeaderShade = state.ui.showHeaderShade
      const phoneNumberVerified =
        state.oidc.user &&
        state.oidc.user.profile.phone_number_verified &&
        state.oidc.user.profile.phone_number_verified !== 'False'

      const isResident =
        state.oidc.user &&
        state.oidc.user.profile.is_resident &&
        state.oidc.user.profile.is_resident === 'True'
      return {
        isResident,
        commissions,
        commissionsdebtloading,
        picture,
        showHeaderShade,
        corporateSettings: state.userSettings.corporateSettings,
        messages: state.mailBox.lastUnreadMessages,
        phoneNumberVerified,
        requestMoneyQuantity: state.requestMoney.quantity
      }
    },
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(Header)
)
