import { templateConstants } from '../../constants/constants'

const initialState = {
  transfer: [],
  templateLoading: false,
  templateLoadingError: null,

  piTransfer: [],
  piTransferTemplateLoading: false,
  piTransferTemplateLoadingError: null,

  mobile: [],
  mobiletemplateLoading: false,
  mobiletemplateLoadingError: null,

  addTransferTemplateLoading: false,
  addTransferTemplateLoadingError: null,

  addPiTransferTemplateLoading: false,
  addPiTransferTemplateLoadingError: null,

  remittance: [],
  remittancetemplateLoading: true,
  remittancetemplateLoadingError: null,

  p2p: [],
  p2ptemplateLoading: false,
  p2ptemplateLoadingError: null
}

export default function templateReducer(state = initialState, action) {
  const newState = Object.assign({}, state)

  switch (action.type) {
    // Transfer templates

    case templateConstants.FETCH_TRANSFER_TEMPLATES:
      return Object.assign({}, state, {
        transfer: [],
        templateLoading: true,
        templateLoadingError: null
      })
    case templateConstants.FETCH_TRANSFER_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        transfer: action.payload,
        templateLoading: false,
        templateLoadingError: null
      })
    case templateConstants.FETCH_TRANSFER_TEMPLATES_ERROR:
      return Object.assign({}, state, {
        templateLoading: false,
        templateLoadingError: action.error
      })

    case templateConstants.ADD_TRANSFER_TEMPLATES:
      return Object.assign({}, newState, {
        addTransferTemplateLoading: true,
        addTransferTemplateLoadingError: null
      })
    case templateConstants.ADD_TRANSFER_TEMPLATES_SUCCESS:
      newState.transfer.push(action.payload)
      return Object.assign({}, newState, {
        addTransferTemplateLoading: false,
        addTransferTemplateLoadingError: null
      })
    case templateConstants.FETCH_ADD_TRANSFER_TEMPLATES_ERROR:
      return Object.assign({}, state, {
        addTransferTemplateLoading: false,
        addTransferTemplateLoadingError: action.error
      })
    case templateConstants.FETCH_EDIT_TRANSFER_TEMPLATES_ERROR:
      return Object.assign({}, newState, {
        addTransferTemplateLoading: false,
        addTransferTemplateLoadingError: action.error
      })

    case templateConstants.DELETE_TRANSFER_TEMPLATES_SUCCESS:
      if (newState.transfer.length)
        newState.transfer = newState.transfer.filter(
          template => template.id != action.payload.id
        )
      return newState

    case templateConstants.EDIT_TRANSFER_TEMPLATES_SUCCESS:
      if (newState.transfer.length) {
        newState.transfer[
          newState.transfer.indexOf(
            newState.transfer.find(x => x.id === action.payload.id)
          )
        ] = action.payload
      }
      newState.addTransferTemplateLoading = false
      newState.addTransferTemplateLoadingError = null
      return newState

    //persona info transfer templates
    case templateConstants.FETCH_PI_TEMPLATES:
      return Object.assign({}, state, {
        piTransfer: [],
        piTransferTemplateLoading: true,
        piTransferTemplateLoadingError: null
      })
    case templateConstants.FETCH_PI_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        piTransfer: action.payload,
        piTransferTemplateLoading: false,
        piTransferTemplateLoadingError: null
      })
    case templateConstants.FETCH_PI_TEMPLATES_ERROR:
      return Object.assign({}, state, {
        piTransferTemplateLoading: false,
        piTransferTemplateLoadingError: action.error
      })

    case templateConstants.ADD_PI_TEMPLATES:
      return Object.assign({}, state, {
        addPiTransferTemplateLoading: true,
        addPiTransferTemplateLoadingError: null
      })
    case templateConstants.ADD_PI_TEMPLATES_SUCCESS:
      newState.piTransfer.push(action.payload)
      return Object.assign({}, newState, {
        addPiTransferTemplateLoading: false,
        addPiTransferTemplateLoadingError: null
      })
    case templateConstants.ADD_PI_TEMPLATES_ERROR:
      return Object.assign({}, state, {
        addPiTransferTemplateLoading: false,
        addPiTransferTemplateLoadingError: action.error
      })

    case templateConstants.DELETE_PI_TEMPLATES_SUCCESS:
      if (newState.piTransfer.length)
        newState.piTransfer = newState.piTransfer.filter(
          template => template.id != action.payload.id
        )
      return newState

    case templateConstants.EDIT_PI_TEMPLATES_SUCCESS:
      if (newState.piTransfer.length)
        newState.piTransfer[
          newState.piTransfer.indexOf(
            newState.piTransfer.find(x => x.id === action.payload.id)
          )
        ] = action.payload
      return newState

    // Mobile templates
    // case templateConstants.FETCH_MOBILE_TEMPLATES_SUCCESS:
    //   if (!Array.isArray(action.payload)) return state;
    //   return Object.assign({}, state, { mobile: action.payload });

    case templateConstants.FETCH_MOBILE_TEMPLATES:
      return Object.assign({}, state, {
        mobile: [],
        mobiletemplateLoading: true,
        mobiletemplateLoadingError: null
      })
    case templateConstants.FETCH_MOBILE_TEMPLATES_SUCCESS: {
      // if (!Array.isArray(action.payload))
      //   return Object.assign({}, state, {
      //     mobiletemplateLoading: false,
      //     mobiletemplateLoadingError: null
      //   });
      let mobile = action.payload === null ? [] : action.payload
      return Object.assign({}, state, {
        mobile: mobile,
        mobiletemplateLoading: false,
        mobiletemplateLoadingError: null
      })
    }
    case templateConstants.FETCH_MOBILE_TEMPLATES_ERROR:
      return Object.assign({}, state, {
        mobiletemplateLoading: false,
        mobiletemplateLoadingError: action.error
      })

    case templateConstants.DELETE_MOBILE_TEMPLATES_SUCCESS:
      if (newState.mobile.length)
        newState.mobile = newState.mobile.filter(
          template => template.id != action.payload.id
        )
      return newState

    case templateConstants.FETCH_REMITTANCE_TEMPLATES:
      return Object.assign({}, state, {
        remittance: [],
        remittancetemplateLoading: true,
        remittancetemplateLoadingError: null
      })
    case templateConstants.FETCH_REMITTANCE_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        remittance: action.payload,
        remittancetemplateLoading: false,
        remittancetemplateLoadingError: null
      })
    case templateConstants.FETCH_REMITTANCE_TEMPLATES_ERROR:
      return Object.assign({}, state, {
        remittancetemplateLoading: false,
        remittancetemplateLoadingError: action.error
      })

    case templateConstants.DELETE_REMITTANCE_TEMPLATES_SUCCESS:
      if (newState.remittance.length)
        newState.remittance = newState.remittance.filter(
          template => template.templateId != action.payload.id
        )
      return newState

    case templateConstants.FETCH_P2P_TEMPLATES_SUCCESS: {
      let p2p = action.payload === null ? [] : action.payload
      return Object.assign({}, state, {
        p2p: p2p,
        p2ptemplateLoading: false,
        p2ptemplateLoadingError: null
      })
    }
    case templateConstants.FETCH_P2P_TEMPLATES:
      return Object.assign({}, state, {
        p2p: [],
        p2ptemplateLoading: true,
        p2ptemplateLoadingError: null
      })

    case templateConstants.FETCH_P2P_TEMPLATES_ERROR:
      return Object.assign({}, state, {
        p2ptemplateLoading: false,
        p2ptemplateLoadingError: action.error
      })
    case templateConstants.DELETE_P2P_TEMPLATES_SUCCESS:
      if (newState.p2p.length)
        newState.p2p = newState.p2p.filter(
          template => template.id != action.payload
        )
      return newState
    case templateConstants.ADD_ACRD_TEMPLATE:
      return Object.assign({}, state, {
        p2p: [action.payload, ...state.p2p],
        p2ptemplateLoading: false,
        p2ptemplateLoadingError: null
      })

    default:
      return state
  }
}
