import React, { useRef, useCallback, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import LbModal from '../../common/Modal/LbModal'
import Icon from '../../common/SimpleComponents/Icon'
import { reselectPhoneVerified } from '../../redux/reselects/user.reselect'
import moment from 'moment'
import userApi from '../../api/user-setting.api'
import { reselectSettingsPhoneVerification } from '../../redux/reselects/user-settings.reselect'
import { getConfigValue } from '../../constants/config'
import i18n from '../../../i18n'

import config from '../../constants/config'
import hasUserPermission from '../../helpers/hasUserPermission'

export const PhoneVerification = () => {
  const modalRef = useRef(null)
  const { phoneNumberVerified } = useSelector(reselectPhoneVerified)
  const { phoneVerification } = useSelector(reselectSettingsPhoneVerification)

  const modalCloseCallback = useCallback(async () => {
    try {
      let currentTime = moment().valueOf()
      let setting = {
        keyword: 'phoneVerification',
        settingBody: JSON.stringify({
          lastSeen: currentTime
        })
        // settingBody: ''
      }
      await userApi.saveUserSettingsAjax(setting, true)
    } catch (error) {
      // console.log(error)
    }
  }, [])

  const handleCloseModal = useCallback(() => {
    if (modalRef.current) {
      modalRef.current.handleCloseModal()
    }
  }, [])

  const handleGotoProfile = useCallback(async () => {
    handleCloseModal()
    window.location.href = `${config.serverConfig.authorizationUrl}/Manage?returnUrl=${window.location.href}&clientId=${config.serverConfig.clientId}`
  }, [handleCloseModal])

  useEffect(() => {
    // modalCloseCallback()
    // modalRef.current.handleOpenModal()
    // TODo: remove server checking

    if (
      hasUserPermission(true, false) ||
      !modalRef.current ||
      phoneNumberVerified !== 'False' ||
      !getConfigValue('phone_verification_pop_up_interval') ||
      !getConfigValue('phone_verification_pop_up_interval_date_type')
    )
      return

    checkDate()
  }, [phoneNumberVerified, checkDate])

  const checkDate = useCallback(async () => {
    try {
      const data = await userApi.fetchUserSettingsAjax()

      if (data && !('phoneVerification' in data)) {
        modalRef.current.handleOpenModal()
      } else {
        const phoneVerification = JSON.parse(data.phoneVerification)
        const expiredAt = moment(phoneVerification.lastSeen)
          .add(
            getConfigValue('phone_verification_pop_up_interval'),
            getConfigValue('phone_verification_pop_up_interval_date_type')
          )
          .valueOf()
        if (expiredAt < moment().valueOf()) {
          modalRef.current.handleOpenModal()
        }
      }
    } catch (error) {}
  }, [])

  return (
    <>
      {/* TODO: მობილურის ნომრის გადამოწმების მოდალი*/}

      <LbModal
        ref={modalRef}
        header={i18n.t('Common.PhoneVerificationModalHeader')}
        className="mw-600"
        modalCloseCallback={modalCloseCallback}
      >
        <div className="lb-mobile-check flex-start">
          <div className="lb-check-left flex-center">
            <i className="icn icn-mobile-check">
              <Icon name="mobile-check" viewBox="0 0 40 40" />
            </i>
          </div>
          <div className="lb-check-right">
            <h3>
              <Trans i18nKey="Common.PhoneVerification.Title" />
            </h3>
            <ul>
              <li>
                <Trans i18nKey="Common.PhoneVerification.ConditionOne" />{' '}
              </li>
              <li>
                <Trans i18nKey="Common.PhoneVerification.ConditionTow" />{' '}
              </li>
              <li>
                <Trans i18nKey="Common.PhoneVerification.ConditionTree" />{' '}
              </li>
            </ul>
          </div>
        </div>
        <div className="lb-popup-footer">
          <button
            id="input-phone-verify-late"
            type="button"
            className="lb-btn btn-border btn-white"
            onClick={handleCloseModal}
          >
            <Trans i18nKey="Common.TryLate" />
          </button>
          <button
            id="input-phone-verify-now"
            type="button"
            className="lb-btn btn-red"
            onClick={handleGotoProfile}
          >
            <Trans i18nKey="Common.Start" />
          </button>
        </div>
      </LbModal>
    </>
  )
}
