import { store } from '../redux/state'

export const corporateUserPermissions = {
  DEPOSITS_VIEW: 'DEPOSITS_VIEW',
  DEPOSITS_STATEMENT_VIEW: 'DEPOSITS_STATEMENT_VIEW',
  DEPOSITS_DETAILS: 'DEPOSITS_DETAILS',
  DEPOSITS_PERCENT_CALCULATION: 'DEPOSITS_PERCENT_CALCULATION',
  DEPOSITS_VIOLATION_PERCENT: 'DEPOSITS_VIOLATION_PERCENT',
  DEPOSITS_NAME_CHANGE: 'DEPOSITS_NAME_CHANGE',
  BATCH_TRANSFERS_VIEW: 'BATCH_TRANSFERS_VIEW',
  BATCH_TRANSFERS_UPLOAD: 'BATCH_TRANSFERS_UPLOAD',
  BATCH_TRANSFERS_TRANSACTIONS_VIEW: 'BATCH_TRANSFERS_TRANSACTIONS_VIEW',
  BATCH_TRANSFERS_UPDATE: 'BATCH_TRANSFERS_UPDATE',
  BATCH_TRANSFERS_WORK_TERMINATION: 'BATCH_TRANSFERS_WORK_TERMINATION',

  CREDIT_PRODUCTS_SCHEDULE_VIEW: 'CREDIT_PRODUCTS_SCHEDULE_VIEW', // გრაფიკის ნახვა
  CREDIT_PRODUCTS_HISTORY_VIEW: 'CREDIT_PRODUCTS_HISTORY_VIEW', // გადახდების ისტორიის ნახვა
  CREDIT_PRODUCTS_LOANS_DETAILS: 'CREDIT_PRODUCTS_LOANS_DETAILS', // სესხების დეტალები
  CREDIT_PRODUCTS_LOANS_DEBT_CALCULATION:
    'CREDIT_PRODUCTS_LOANS_DEBT_CALCULATION', // სესხების დავალიანების გამოთვლა
  CREDIT_PRODUCTS_LOANS_NAME_CHANGE: 'CREDIT_PRODUCTS_LOANS_NAME_CHANGE', // სესხის სახელის ცვლილება
  CREDIT_PRODUCTS_LOANS_VIEW: 'CREDIT_PRODUCTS_LOANS_VIEW', // სესხების ნახვა

  TRANSFERS_UTILITY_OTHERS: 'TRANSFERS_UTILITY_OTHERS',
  TRANSFERS_TEMPLATE_CREATE_UTILITY: 'TRANSFERS_TEMPLATE_CREATE_UTILITY',
  TRANSFERS_TEMPLATE_VIEW_UTILITY: 'TRANSFERS_TEMPLATE_VIEW_UTILITY',
  TRANSFERS_TEMPLATE_DELETE_UTILITY: 'TRANSFERS_TEMPLATE_DELETE_UTILITY',
  TRANSFERS_OTHER_BANK_FOREIGN_CURRENCY:
    'TRANSFERS_OTHER_BANK_FOREIGN_CURRENCY',
  TRANSFERS_CREDIT_DEBT_PAY: 'TRANSFERS_CREDIT_DEBT_PAY',
  TRANSFERS_LOAN_PAY: 'TRANSFERS_LOAN_PAY',
  TRANSFERS_OTHER_BANK_NATIONAL_CURRENCY:
    'TRANSFERS_OTHER_BANK_NATIONAL_CURRENCY',
  TRANSFERS_INSIDE: 'TRANSFERS_INSIDE',
  TRANSFERS_OWN_ACCOUNT: 'TRANSFERS_OWN_ACCOUNT',
  TRANSFERS_MOBILE_TOP_UP: 'TRANSFERS_MOBILE_TOP_UP',
  TRANSFERS_BUDGET: 'TRANSFERS_BUDGET',
  TRANSFERS_BUDGET_IMPOSTOR: 'TRANSFERS_BUDGET_IMPOSTOR',
  TRANSFERS_FUTURE_DATE_CHANGE: 'TRANSFERS_FUTURE_DATE_CHANGE',

  ACCOUNTS_VIEW: 'ACCOUNTS_VIEW',
  ACCOUNTS_NAME_CHANGE: 'ACCOUNTS_NAME_CHANGE',
  ACCOUNTS_DETAILS: 'ACCOUNTS_DETAILS',
  ACCOUNTS_DEBT: 'ACCOUNTS_DEBT',
  ACCOUNTS_LOCK_UNLOCK: 'ACCOUNTS_LOCK_UNLOCK',
  ACCOUNTS_CARD_RESET_PIN: 'ACCOUNTS_CARD_RESET_PIN',
  TRANSACTIONS: 'TRANSACTIONS',
  TRANSACTIONS_STATEMENT: 'TRANSACTIONS_STATEMENT',
  TRANSACTIONS_BLOCKED_AMOUNTS: 'TRANSACTIONS_BLOCKED_AMOUNTS',
  TRANSACTIONS_CURRENT_TASKS: 'TRANSACTIONS_CURRENT_TASKS',
  TRANSACTIONS_TABLES_MANAGEMENTS: 'TRANSACTIONS_TABLES_MANAGEMENTS',
  TRANSACTIONS_TASK_TERMINATION: 'TRANSACTIONS_TASK_TERMINATION',
  TEMPLATES_MOBILE_CREATE: 'TEMPLATES_MOBILE_CREATE',
  TEMPLATES_MOBILE_VIEW: 'TEMPLATES_MOBILE_VIEW',
  TEMPLATES_MOBILE_DELETE: 'TEMPLATES_MOBILE_DELETE',

  TRANSFERS_FINES: 'TRANSFERS_FINES',
  TRANSFERS_PENALTY_VIEW: 'TRANSFERS_PENALTY_VIEW',
  TRANSFERS_CARS_CREATE_UPDATE: 'TRANSFERS_CARS_CREATE_UPDATE',
  TRANSFERS_CARS_DELETE: 'TRANSFERS_CARS_DELETE',

  // Payroll Transfers
  SALARY_TRANSFER: 'SALARY_TRANSFER', // საფელფასო გადარიცხვსი უფლებები
  VIEW_SALARY_TRANFERS: 'VIEW_SALARY_TRANFERS', // სახელფასო გადარიცხვსი ნახვა
  UPLOAD_SALARY_TRANSFERS: 'UPLOAD_SALARY_TRANSFERS', // სახელფასო გადარიცხვის ატვირთვა
  VIEW_SALARY_TRANSFER_TRANSACTIONS: 'VIEW_SALARY_TRANSFER_TRANSACTIONS', //ტრანზაქციის ნახვა სახელფასო გადრცხვებში
  CHANGE_SALARY_TRANSFER: 'CHANGE_SALARY_TRANSFER', // სახელფასო გადარიცხვსი ცვლილება
  CANEL_SALARY_TRANSFER: 'CANEL_SALARY_TRANSFER', // სახელფასო გადარიცხვის გაუქმება
  VIEW_EMPLOYEE_LIST: 'VIEW_EMPLOYEE_LIST', // ჩემი თანამშრომლების სიის ნახვა
  ADD_OR_EDIT_EMPLOYEE: 'ADD_OR_EDIT_EMPLOYEE', // თანამშრომლის დამატება რედაქტირება
  DELETE_EMPLOYEE: 'DELETE_EMPLOYEE', // თანამშრომლის წაშლა
  VIEW_OWN_SALARY_PROJECT: 'VIEW_OWN_SALARY_PROJECT', // ჩემი სახელფასო პროექტის ნახვა

  // trustworthy of utility template
  TRUST_UTILITY_TEMPLATE: 'TRUST_UTILITY_TEMPLATE', // Allows corporate user to make trust action
  TRUST_UTILITY_TEMPLATE_DISCONNECT: 'TRUST_UTILITY_TEMPLATE_DISCONNECT', // Allows corporate user to make untrust action,
  ACCOUNTS_CARD_ORDER: 'ACCOUNTS_CARD_ORDER' // business card creation
}

export default (corporateActions, retailAction = true) => {
  let state = store.getState()
  let { corporateSettings } = state.userSettings

  // for corporate users
  if (corporateSettings) {
    if (corporateActions === false) return false
    if (corporateActions === true) return true
    if (Array.isArray(corporateActions)) {
      let ccc = corporateSettings.allowedActions.some(i =>
        corporateActions.some(c => c === i.code)
      )
      let bcc = corporateSettings.allowedActions && ccc
      return (
        corporateSettings.allowedActions &&
        corporateSettings.allowedActions.some(i =>
          corporateActions.some(c => c === i.code)
        )
      )
    }
    if (corporateActions.includes('&')) {
      return (
        corporateSettings.allowedActions &&
        corporateActions
          .split('&')
          .every(c => corporateSettings.allowedActions.some(i => c === i.code))
        //corporateSettings.allowedActions.some(i => corporateActions.some(c => c === i.code))
      )
    }
    return (
      corporateSettings.allowedActions &&
      corporateSettings.allowedActions.some(i => i.code === corporateActions)
    )
  }
  // for retail users
  else return retailAction
}
