export const config = {
  regDate: {
    transKey: 'RegDate',
    name: 'შექმნის თარიღი',
    date: true,
    inTooltip: true,
    visible: true
  },
  name: {
    transKey: 'BatchName',
    name: 'გადარიცხვის სახელი',
    cellKey: 'name',
    inTooltip: true,
    visible: true,
    batchName: true
  },
  transactionCount: {
    transKey: 'TransactionCount',
    name: 'ტრანზაქციების რაოდენობა',
    cellKey: 'transactionCount',
    inTooltip: true,
    visible: true,
    wssCanChange: true
  },
  canceledBy: {
    transKey: 'CanceledBy',
    name: 'გაუქმებულია',
    cellKey: 'canceledBy',
    visible: false
  },
  awaitingSignRoles: {
    transKey: 'AwaitingSignRoles',
    name: 'ესაჭიროება',
    cellKey: 'awaitingSignRoles',
    inTooltip: true,
    visible: false,
    authLevel: true
  },
  iban: {
    transKey: 'FromIban',
    name: 'ანგარიშიდან',
    cellKey: 'iban',
    iban: true,
    inTooltip: true,
    visible: false
  },
  paymentDate: {
    transKey: 'PaymentDate',
    name: 'გადარიცხვის თარიღი',
    cellKey: 'paymentDate',
    date: true,
    inTooltip: true,
    visible: false
  },
  feeSum: {
    transKey: 'FeeSum',
    name: 'ჯამური საკომისიო',
    cellKey: 'feeSum',
    inTooltip: true,
    visible: false
  },
  createdBy: {
    transKey: 'CreatedBy',
    name: 'შემქმნელი',
    cellKey: 'createdBy',
    inTooltip: true,
    visible: false
  },
  signedBy: {
    transKey: 'SignedBy',
    name: 'მაავტორიზებელი',
    cellKey: 'signedBy',
    visible: false,
    inTooltip: true
  }
}

export default [
  {
    cellKey: 'regDate',
    visible: true
  },
  {
    cellKey: 'name',
    visible: true
  },
  {
    cellKey: 'transactionCount',
    visible: true
  },
  {
    cellKey: 'canceledBy',
    visible: false
  },
  {
    cellKey: 'awaitingSignRoles',
    visible: false
  },
  {
    cellKey: 'iban',
    visible: false
  },
  {
    cellKey: 'paymentDate',
    visible: false
  },
  {
    cellKey: 'feeSum',
    visible: false
  },
  {
    cellKey: 'createdBy',
    visible: false
  },
  {
    cellKey: 'signedBy',
    visible: false
  }
]
