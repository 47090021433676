import React, { Fragment, PureComponent } from 'react'
import { store } from '../../redux/state'
import userManager from '../../helpers/userManager'
import { loadUser } from 'redux-oidc'
import { LbLoader } from '../../common/SimpleComponents/LBLoader'
import { connect } from 'react-redux'
import userActions from '../../redux/actions/user.actions'
import { history } from '../../helpers/history'
import InactivityTimer from './InactivityTimer'

class AuthenticatedRoutes extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
    this.checkUser()
  }

  checkUser = () => {
    const { user } = this.props
    if (!user) {
      loadUser(store, userManager.object).then(async sUser => {
        if (!sUser) {
          sessionStorage.setItem(
            'TEMP_REDIRECT_URL',
            window.location.pathname + window.location.search
          )
          userManager.object.signinRedirect()
        } else if (sUser && sUser.expired) {
          userManager.object.signinRedirect()
          //userManager.object.signoutRedirect({ id_token_hint: sUser.id_token });
          //userManager.object.removeUser();
        } else if (sUser && !sUser.expired) {
          this.props.dispatch(userActions.fetchUserInfo())
          if (sUser.profile.isCorporate) {
            try {
              await this.props.dispatch(
                userActions.fetcbCorporateUserSettings()
              )
            } catch (exception) {
              history.push('/general-error')
            }
          }
        }
      })
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      (prevProps.user &&
        !prevProps.user.expired &&
        (!this.props.user || this.props.user.expired)) ||
      (prevProps.user && prevProps.user.expired && !this.props.user)
    ) {
      this.ir = setTimeout(() => {
        userManager.object.signinRedirect()
        userManager.object.removeUser()
      }, 2000)
    }
  }

  componentWillUnmount() {
    if (this.ir) clearTimeout(this.ir)
  }

  render() {
    const { children, user, corporateSettings } = this.props
    const childrenComponent =
      user &&
      !user.expired &&
      (!user.profile.isCorporate ||
        (user.profile.isCorporate && corporateSettings)) ? (
        <div className="scroll-section">{children}</div>
      ) : (
        <LbLoader isLoading inCenter />
      )
    return (
      <Fragment>
        {childrenComponent}
        <InactivityTimer />
      </Fragment>
    )
  }
}

//export default AuthenticatedRoutes;

export default connect(state => {
  return {
    user: state.oidc.user,
    corporateSettings: state.userSettings.corporateSettings
  }
})(AuthenticatedRoutes)
