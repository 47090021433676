import { createUserManager } from 'redux-oidc'
import { updateObject } from './util'
import hasUserPermission from './hasUserPermission'
import config from '../constants/config'

//let clientId = 'internet_bank';

export const userManagerConfig = {
  clockSkew: 60 * 60 * 24 * 365,
  //client_id: clientId,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/callback`,
  response_type: 'token id_token',
  scope:
    'account_api_global identity_server_api_global profile banner_api_global exchangerate_api_global currenttask_api_global logger_api_global configuration_api_global openid transfer_api_global transaction_api_global billing_api_global storage_api_global deposit_api_global loan_api_global corporate_api_global remittance_api_global notifications_api_global cashback_api_global libertycredit_api_global referrals_api_global',
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/`,
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 60,
  inactiveTimeSeconds: 4 * 60 //240
}

let userManager = { object: null }

export function setUserManagerAuthorizationUrl(authorityUrl, clientId) {
  userManager.object = createUserManager(
    updateObject(userManagerConfig, {
      authority: authorityUrl,
      client_id: clientId
    })
  )
  return userManager.object
}

export default userManager
// for checking user info
// is User Phone verified or not
export const isUserVerified = sUser => {
  if (
    sUser &&
    sUser.phone_number_verified === 'False' &&
    sUser.type !== 'Corporate'
  ) {
    let url = `${config.serverConfig.authorizationUrl}/Manage?returnUrl=${window.location.href}&clientId=${config.serverConfig.clientId}`
    window.location.href = url
  }
}
