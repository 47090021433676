import { jsonRequest } from '../../helpers/apiRequest'
import { baseUrl } from '../../helpers/baseUrl'

const endpoint = '/corporate/v2'

export const fetchUsersAjax = () => {
  return jsonRequest(`${baseUrl}${endpoint}/organization/users`, true)
}

export const fetchOrganizationRoles = () => {
  return jsonRequest(`${baseUrl}${endpoint}/organization/user-roles`, true)
}

export const createOrganizationRole = ({ name }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/user-roles`,
    true,
    { name: name, nameEng: name },
    'POST',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const updateOrganizationRole = ({ userRoleId, name }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/user-roles/${userRoleId}`,
    true,
    { name: name, nameEng: name },
    'PUT',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const deleteOrganizationRole = ({ userRoleId }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/user-roles/${userRoleId}`,
    true,
    null,
    'DELETE',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}
export const sendPermissionCode = sessionId => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/permissions/send-code`,
    true,
    { sessionId: sessionId },
    'POST'
  )
}

export const fetchOrganizationRolePermissions = ({ userRoleId }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/user-roles/${userRoleId}/permissions`,
    true
  )
}

export const updateOrganizationRolePermissions = ({
  userRoleId,
  userActionIds
}) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/user-roles/${userRoleId}/permissions`,
    true,
    userActionIds,
    'PUT'
  )
}

export const fetchDisclaimerMessage = () => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/permissions/open-banking-agreement`,
    true
  )
}
export const fetchUserActions = () => {
  return jsonRequest(`${baseUrl}${endpoint}/organization/user-actions`, true)
}

/// users
export const fetchUserAccountPermissions = ({ omniUserId }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/${omniUserId}/permissions`,
    true
  )
}

export const updateUserRole = ({ id: omniUserId, roleId }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/${omniUserId}/role`,
    true,
    roleId,
    'PUT',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const updateUserPermissions = ({ omniUserId, ...otherProps }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/${omniUserId}/permissions`,
    true,
    { ...otherProps },
    'PUT',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const createUser = ({
  username,
  firstname,
  lastname,
  firstnameEn,
  lastnameEn,
  personalNumber,
  birthDate,
  email,
  phoneNumber,
  roleId,
  marketingTemplateId
}) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/`,
    true,
    {
      username,
      firstname,
      lastname,
      firstnameEn,
      lastnameEn,
      personalNumber,
      birthDate,
      email,
      phoneNumber,
      roleId,
      marketingTemplateId
    },
    'POST',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const updateUser = ({
  id: omniUserId,
  username,
  firstname,
  lastname,
  firstnameEn,
  lastnameEn,
  personalNumber,
  birthDate,
  email,
  phoneNumber,
  roleId,
  marketingTemplateId
}) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/${omniUserId}`,
    true,
    {
      username,
      firstname,
      lastname,
      firstnameEn,
      lastnameEn,
      personalNumber,
      birthDate,
      email,
      phoneNumber,
      roleId,
      marketingTemplateId
    },
    'PUT',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const resetPassword = ({ omniUserId, sendSMS, phoneNumber }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/${omniUserId}/reset-password`,
    true,
    { sendSMS: sendSMS, phoneNumber: phoneNumber },
    'PUT',
    { internalServerError: true, badRequest: true }
  )
}

export const deactivateUser = ({ omniUserId }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/${omniUserId}/deactivate`,
    true,
    null,
    'PUT',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const activateUser = ({ omniUserId }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/${omniUserId}/activate`,
    true,
    null,
    'PUT',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const deleteUser = ({ omniUserId }) => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/${omniUserId}/delete`,
    true,
    null,
    'PUT',
    {
      internalServerError: true,
      badRequest: true
    }
  )
}

export const fetchMarketingMessage = () => {
  return jsonRequest(
    `${baseUrl}${endpoint}/organization/users/permissions/marketing-agreement`,
    true
  )
}
