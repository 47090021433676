import { administrationConstants } from '../../../constants/constants'
import { administration as client } from '../../../api/corporate'

export function fetchUsers() {
  return async dispatch => {
    try {
      dispatch({ type: administrationConstants.FETCH_USERS })
      let result = await client.fetchUsersAjax()
      dispatch({
        type: administrationConstants.FETCH_USERS_SUCCESS,
        payload: result
      })
    } catch (error) {
      dispatch({ type: administrationConstants.FETCH_USERS_ERROR, error })
    }
  }
}

export function fetchUserActions() {
  return async dispatch => {
    try {
      dispatch({ type: administrationConstants.FETCH_USER_ACTIONS })
      let result = await client.fetchUserActions()
      dispatch({
        type: administrationConstants.FETCH_USER_ACTIONS_SUCCESS,
        payload: result
      })
    } catch (error) {
      dispatch({
        type: administrationConstants.FETCH_USER_ACTIONS_ERROR,
        error
      })
    }
  }
}

export function fetchOrganizationRoles() {
  return async dispatch => {
    try {
      dispatch({ type: administrationConstants.FETCH_USER_ROLES })
      let result = await client.fetchOrganizationRoles()
      result.sort((a, b) => {
        if (a.default && !b.default) return -1
        if (!a.default && b.default) return 1
        if (a.id === -1) return 1
        if (a.id > b.id) return 1
        if (a.id < b.id) return -1
        return 0
      })
      dispatch({
        type: administrationConstants.FETCH_USER_ROLES_SUCCESS,
        payload: result
      })
    } catch (error) {
      dispatch({ type: administrationConstants.FETCH_USER_ROLES_ERROR, error })
    }
  }
}

export function selectOrganizationRole(role) {
  return async dispatch => {
    try {
      dispatch({
        type: administrationConstants.SELECT_USER_ROLE,
        payload: role
      })
      dispatch({ type: administrationConstants.FETCH_USER_ROLE_ACTIONS })
      let result = await client.fetchOrganizationRolePermissions({
        userRoleId: role.id
      })
      dispatch({
        type: administrationConstants.FETCH_USER_ROLE_ACTIONS_SUCCESS,
        payload: result
      })
    } catch (error) {
      dispatch({
        type: administrationConstants.FETCH_USER_ROLE_ACTIONS_ERROR,
        error
      })
    }
  }
}

export function addNewRole() {
  return async dispatch => {
    dispatch({ type: administrationConstants.ADD_USER_ROLE })
  }
}

export function saveRole(role, actionIds) {
  return async dispatch => {
    const oldId = role.id
    if (role.id >= 0) {
      await client.updateOrganizationRole({
        userRoleId: role.id,
        name: role.name
      })
    } else {
      role.id = await client.createOrganizationRole({ name: role.name })
    }
    await client.updateOrganizationRolePermissions({
      userRoleId: role.id,
      userActionIds: actionIds
    })
    let actions = await client.fetchOrganizationRolePermissions({
      userRoleId: role.id
    })
    dispatch({
      type: administrationConstants.UPDATE_USER_ROLE,
      payload: { oldId: oldId, role: role, roleActions: actions }
    })
  }
}

export function deleteUserRole(roleId) {
  return async dispatch => {
    await client.deleteOrganizationRole({ userRoleId: roleId })
    dispatch({
      type: administrationConstants.DELETE_USER_ROLE,
      payload: roleId
    })
  }
}

export function cancelNewRole() {
  return async dispatch => {
    dispatch({ type: administrationConstants.CANCEL_ADD_USER_ROLE })
  }
}
