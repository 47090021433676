import download from 'downloadjs'
import { jsonRequest } from '../helpers/apiRequest'
import { baseUrl } from '../helpers/baseUrl'

export function fetchDetailsOptions() {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/configuration`,
    true
  )
}
export function fetchGallery() {
  return jsonRequest(`${baseUrl}/deposit/v1/AccumulationService/gallery`, true)
}
export function fetchValues(moneyboxId) {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService?depositId=${moneyboxId}`,
    true
  )
}
/* eslint-disable */
export function checkCashbackAccessibility(
  accountNumber = '',
  accountCurrency = ''
) {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/cashback-service-exist?accountNumberToAddCashback=${accountNumber}&accountCurrencyToAddCashback=${accountCurrency}`,
    true
  )
}
/* eslint-enable */
export function register(data) {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/register`,
    true,
    data,
    'POST'
  )
}
export function update(data) {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/update`,
    true,
    data,
    'POST'
  )
}
export function rename(depositId, name) {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/rename`,
    true,
    { depositId, name },
    'POST'
  )
}
export function cancel(depositId) {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/cancel`,
    true,
    { depositId },
    'POST'
  )
}
export function getPdf() {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/agreement-sample/pdf`,
    true
  )
}
export function downloadExistedMoneyboxAgreement(id) {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/agreement/pdf?depositId=${id}`,
    true
  ).then(res => {
    return res.blob().then(blb => {
      return download(blb, 'savingAccount', 'application/pdf')
    })
  })
}
export function checkDepositLimitt() {
  return jsonRequest(
    `${baseUrl}/deposit/v1/AccumulationService/check-open-account-limit`,
    true
  )
}
