import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import { reactI18nextModule } from 'react-i18next'

import { baseUrl } from './app/helpers/baseUrl'
import {
  getCurrentLanguage,
  setCurrentLanguage
} from './app/helpers/languageManager'

let wasInit = false

export const setI18next = () => {
  if (wasInit) return
  wasInit = true
  let language = getCurrentLanguage()

  i18n
    .use(XHR)
    .use(reactI18nextModule) // if not using I18nextProvider
    .init({
      returnEmptyString: false,
      parseMissingKeyHandler: key => {
        return key
      },
      lng: language,
      fallbackLng: 'en-US',
      // ns:  ['translation'],
      debug: true,
      nsSeparator: '::',
      keySeparator: '::',
      whitelist: ['en-US', 'ka'],
      load: 'currentOnly',
      // ns: ['Common', 'Loans'],
      backend: {
        // for all available options read the backend's repository readme file
        loadPath: `${baseUrl}/configuration/v1/captions/IB?culture={{lng}}`
      },

      // saveMissing: true,

      interpolation: {
        escapeValue: false // not needed for react!!
      },

      // react i18next special options (optional)
      react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
      }
    })
    .on('languageChanged', language => {
      setCurrentLanguage(language)
    })
}

export default i18n
