import { userConstants, userSettingsConstants } from '../../constants/constants'
import client from '../../api/user-setting.api'
import { jsonTryParse } from '../../helpers/jsonTryParse'
import { isUserVerified } from '../../helpers/userManager'

const IsGlobal = {
  dashboardBlocks: true,
  accountSettings: true,
  setPrimaryReminder: true,
  orderings: true,
  wasPensionOfferIntroduced: true,
  transactionsCount: false,
  phoneVerification: true
}

function fetchUserSettings() {
  return dispatch => {
    dispatch({ type: userSettingsConstants.FETCH_USER_SETTINGS })
    client.fetchUserSettingsAjax().then(
      result => {
        if (!result) result = {}
        let result1 = Object.keys(result).reduce((accumulator, val) => {
          return { ...accumulator, [val]: jsonTryParse(result[val]) }
        }, {})
        dispatch({
          type: userSettingsConstants.FETCH_USER_SETTINGS_SUCCESS,
          payload: result1
        })
      },
      error => {
        dispatch({
          type: userSettingsConstants.FETCH_USER_SETTINGS_ERROR,
          error
        })
      }
    )
  }
}

function saveUserSetting(settings) {
  const settingsFormated = {
    keyword: Object.keys(settings)[0],
    settingBody: JSON.stringify(Object.values(settings)[0])
  }

  return dispatch => {
    // dispatch({ type: userSettingsConstants.FETCH_USER_SETTINGS });

    dispatch({
      type: userSettingsConstants.FETCH_USER_SETTINGS_SUCCESS,
      payload: settings
    })
    client
      .saveUserSettingsAjax(
        settingsFormated,
        IsGlobal[settingsFormated.keyword]
      )
      .then(
        () => {},
        error => {
          dispatch({
            type: userSettingsConstants.FETCH_USER_SETTINGS_ERROR,
            error
          })
        }
      )
  }
}

function fetchUserInfo() {
  return dispatch => {
    client.fetchUserInfo().then(userProfile => {
      if (userProfile) {
        dispatch({
          type: userConstants.UPDATE_USER_PROFILE,
          payload: userProfile
        })
        // if user phone number is not verified
        isUserVerified(userProfile)
      }
    })
  }
}

function fetcbCorporateUserSettings() {
  return dispatch => {
    return client.fetchCorporateUserSettings().then(corporate => {
      if (corporate)
        dispatch({
          type: userSettingsConstants.UPDATE_CORPORATE_USER_SETTINGS,
          payload: corporate
        })
    })
  }
}

export default {
  fetchUserSettings,
  saveUserSetting,
  fetchUserInfo,
  fetcbCorporateUserSettings
}
