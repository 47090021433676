export const config = {
  regDate: {
    transKey: 'RegDate',
    date: true,
    inTooltip: true
  },
  receiverName: {
    transKey: 'ReceiverName',
    inTooltip: true
  },
  description: {
    transKey: 'Description',
    inTooltip: true
  },
  canceledBy: {
    transKey: 'CanceledBy'
  },
  payType: {
    transKey: 'PayType',
    inTooltip: true
  },
  senderIban: {
    transKey: 'SenderIban',
    iban: true,
    inTooltip: true
  },
  paymentDate: {
    transKey: 'PaymentDate',
    date: true,
    inTooltip: true
  },
  receiverIban: {
    transKey: 'ReceiverIban',
    iban: true,
    inTooltip: true
  },
  taxPayerCode: {
    transKey: 'TaxPayerCode'
  },
  fee: {
    transKey: 'Fee',
    inTooltip: true
  },
  createdBy: {
    transKey: 'CreatedBy',
    inTooltip: true
  },
  signedBy: {
    transKey: 'SignedBy',
    inTooltip: true
  },
  awaitingSignRoles: {
    transKey: 'AwaitingSignRoles',
    inTooltip: true
  }
}

export default [
  {
    cellKey: 'regDate',
    visible: true
  },
  {
    cellKey: 'receiverName',
    visible: true
  },
  {
    cellKey: 'description',
    visible: true
  },
  {
    cellKey: 'canceledBy',
    visible: false
  },
  {
    cellKey: 'payType',
    visible: false
  },
  {
    cellKey: 'senderIban',
    visible: false
  },
  {
    cellKey: 'paymentDate',
    visible: false
  },
  // {
  //   name: 'ტრანზაქციის რაოდენობა',
  //   visible: false
  // },
  // {
  //   name: 'ტრანზაქციის სახელი',
  //   visible: false
  // },
  {
    cellKey: 'receiverIban',
    visible: false
  },
  {
    cellKey: 'taxPayerCode',
    visible: false
  },
  {
    cellKey: 'fee',
    visible: false
  },
  // {
  //   name: 'ჯამური საკომისიო',
  //   visible: false
  // },
  // {
  //   name: 'გადარიცხვის სტატუსი',
  //   cellKey: 'status',
  //   visible: false
  // },
  {
    cellKey: 'createdBy',
    visible: false
  },
  {
    cellKey: 'signedBy',
    visible: false
  },
  // {
  //   name: 'შემოწმების სტატუსი',
  //   visible: false
  // },
  {
    cellKey: 'awaitingSignRoles',
    visible: false
  }
]
