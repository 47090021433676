import { httpErrorConstants } from '../../constants/constants'

const initialState = {
  internalServerError: false,
  internalServerErrorUrl: '',
  badRequest: { active: false }
}

export default function httpErrorsReducer(state = initialState, action) {
  switch (action.type) {
    case httpErrorConstants.INTERNAL_SERVER_ERROR:
      return Object.assign({}, state, {
        internalServerError: true,
        internalServerErrorUrl: action.payload,
        badRequest: { active: false }
      })
    case httpErrorConstants.INTERNAL_SERVER_ERROR_OFF:
      return Object.assign({}, state, {
        internalServerError: false,
        internalServerErrorUrl: '',
        badRequest: { active: false }
      })
    case httpErrorConstants.BAD_REQUEST:
      return Object.assign({}, state, {
        internalServerError: false,
        internalServerErrorUrl: '',
        badRequest: { active: true, error: action.payload }
      })
    case httpErrorConstants.BAD_REQUEST_OFF:
      return Object.assign({}, state, {
        internalServerError: false,
        internalServerErrorUrl: '',
        badRequest: { active: false }
      })
    default:
      return state
  }
}
