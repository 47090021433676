import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { reducer as oidcReducer } from 'redux-oidc'
import accountReducer from './reducers/account.reducer'
import transactionReducer from './reducers/transaction.reducer'
import userSettingsReducer from './reducers/userSettings.reducer'
import oidcextendedReducer from './reducers/oidcextended.reducer'
import remittanceReducer from './reducers/remittance.reducer'
import loanReducer from './reducers/loan.reducer'
import depositReducer from './reducers/deposit.reducer'
import templateReducer from './reducers/template.reducer'
import httpErrorsReducer from './reducers/http-errors.reducer'
import reduceReducers from 'reduce-reducers'
import paymentReducer from './reducers/payment.reducer'
import exchangeRateReducer from './reducers/exchangeRate.reducer'
import bannerReducer from './reducers/banner.reducer'
import uiReducer from './reducers/ui.reducer'
import corporateReducer from './reducers/corporate.reducer'
import batchTransfersReducer from './reducers/batch.corporate.reducer'
import config from '../constants/config'
import administrationReducer from './reducers/administration.reducer'
import mailBoxReducer from './reducers/mailbox.reducer'
import investmentReducer from './reducers/investment.reducer'
import parkingReducer from './reducers/parking.reducer'
import p2pReducer from './reducers/p2p.reducer'
import thirdPartyReducer from './reducers/thirdparty.reducer'
import operationReducer from '../components/Offers/Accounts/Business/reducers/operationReducer'
import requestMoneyReducer from './reducers/requestMoney.reducer'

let store

export const setReduxStore = () => {
  let middlewares = []
  if (config.serverConfig.server === 'development') {
    const loggerMiddleware = createLogger()
    middlewares.push(loggerMiddleware)
  }
  middlewares.push(thunkMiddleware)
  const createStoreWithMiddleware = compose(applyMiddleware(...middlewares))(
    createStore
  )
  const reducers = combineReducers({
    oidc: reduceReducers(oidcReducer, oidcextendedReducer),
    userSettings: userSettingsReducer,
    account: accountReducer,
    transaction: transactionReducer,
    loan: loanReducer,
    deposit: depositReducer,
    templates: templateReducer,
    providers: paymentReducer,
    remittanceProviders: remittanceReducer,
    exchangeRates: exchangeRateReducer,
    httpErrors: httpErrorsReducer,
    banner: bannerReducer,
    ui: uiReducer,
    corporate: corporateReducer,
    batchTransfers: batchTransfersReducer,
    administration: administrationReducer,
    mailBox: mailBoxReducer,
    parking: parkingReducer,
    investment: investmentReducer,
    p2p: p2pReducer,
    thirdParty: thirdPartyReducer,
    operation: operationReducer,
    requestMoney: requestMoneyReducer
  })

  store =
    config.serverConfig.server !== 'production'
      ? createStoreWithMiddleware(
          reducers,
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      : createStoreWithMiddleware(reducers)
}

export { store }
